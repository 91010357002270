import { useColorsContext } from '@utils/styles';
import React from 'react';

const LocationIcon = ({
    size = 20,
}) => {
    const { text } = useColorsContext();
    return (

        <svg width={size} height={size * (24 / 18)} viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5C6.791 5 5 6.791 5 9C5 11.209 6.791 13 9 13C11.209 13 13 11.209 13 9C13 6.791 11.209 5 9 5ZM9 11C7.896 11 7 10.104 7 9C7 7.896 7.896 7 9 7C10.104 7 11 7.896 11 9C11 10.104 10.104 11 9 11ZM9 0C4.029 0 0 4.029 0 9C0 15.766 8 23 9 24C10 23 18 15.766 18 9C18 4.029 13.971 0 9 0ZM9 21.189C3.249 15.33 2 11.35 2 9C2 5.14 5.141 2 9 2C12.859 2 16 5.14 16 9C16 11.35 14.751 15.33 9 21.189Z" fill={text} />
        </svg>

    )

}


export default LocationIcon;
