import React from 'react';

const Caret = ({ size = 23 }) => {
    return (
        <svg width={size} height={size * (14 / 23)} viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4L11.5 11.5L19 4" stroke="black" strokeWidth="5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
    )

}


export default Caret;