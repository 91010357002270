import { useFirebaseContext } from '@contexts/firebaseContext'
import {
    useNavigation,
    NavigationProp,
    ParamListBase,
} from '@react-navigation/native'
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore'
import { useAlert } from 'react-alert'
import { NativeEventEmitter } from 'react-native'

const useNavigateToChat = () => {
    const { db } = useFirebaseContext()
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const customAlert = useAlert()
    const eventEmitter = new NativeEventEmitter()

    const navigateToChat = (tutor: any, user: any, profile: any) => {
        console.log('user', user)
        console.log('profile', profile)
        console.log('tutor', tutor)
        if (!user) {
            eventEmitter.addListener('login-success', ({ user, profile }) => {
                console.warn('Login success, navigating to chat')
                navigateToChat(tutor, user, profile)
            })
        } else {
            const chatsRef = collection(db, 'chats')
            const q = query(
                chatsRef,
                where('tutor.uid', '==', tutor.uid),
                where('user.uid', '==', user.uid)
            )
            getDocs(q).then((querySnapshot) => {
                if (querySnapshot.docs.length > 0) {
                    // Navigate to the chat
                    var hoursSinceOnline
                    if (tutor.lastOnline) {
                        hoursSinceOnline = Math.floor(
                            (new Date() - tutor.lastOnline.toDate()) /
                                1000 /
                                60 /
                                60
                        )
                    } else {
                        hoursSinceOnline = null
                    }

                    // Navigate to chat nested in chats navigator
                    navigation.navigate('Chats', {
                        screen: 'Chats',
                        params: {
                            chat: querySnapshot.docs[0].id,
                            lo: hoursSinceOnline,
                        },
                    })
                } else {
                    // Create the chat in firestore
                    const chatsRef = collection(db, 'chats')
                    var hoursSinceOnline

                    if (tutor.lastOnline) {
                        hoursSinceOnline = Math.floor(
                            (new Date() - tutor.lastOnline.toDate()) /
                                1000 /
                                60 /
                                60
                        )
                    } else {
                        hoursSinceOnline = null
                    }

                    // Add Doc
                    addDoc(chatsRef, {
                        tutor: {
                            name: tutor.name,
                            image: tutor.picture ? tutor.picture : null,
                            uid: tutor.uid,
                            id: tutor.uid,
                        },
                        user: {
                            name: user.name,
                            image: profile ? profile : null,
                            uid: user.uid,
                            id: user.uid,
                        },
                        created: new Date(),
                        lastUpdated: new Date(),
                        lastMessage: {},
                    }).then((docRef) => {
                        navigation.navigate('Chats', {
                            screen: 'Chats',
                            params: {
                                chat: docRef.id,
                                lo: hoursSinceOnline,
                                who: 'test',
                            },
                        })
                    })
                }
            })
        }
    }

    return { navigateToChat }
}

export default useNavigateToChat
