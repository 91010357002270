import React, { useEffect } from 'react'

import { ScrollView, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useMediaQuery } from 'react-responsive'
import Footer from '@components/Footer'
import { useColorsContext, useStyles } from '@utils/styles'
import { ContentHeader } from './ContentHeader'
import {
    Autocomplete,
    Box,
    Container,
    InputAdornment,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material'
import HelpSections from './HelpSections'
import SearchIcon from '@icons/SearchIcon'
import { articles } from './data'
import GroupItems from '@screens/TutorList/TutorListFilters/GroupItems'
import SearchResults from './HelpSections/SearchResults.tsx'
import AntDesign from '@expo/vector-icons/AntDesign'

const Help = ({ navigation, route }) => {
    const [value, setValue] = React.useState('student')
    const [searchText, setSearchText] = React.useState('')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigation.setParams({ tab: newValue })
        setValue(newValue)
    }

    const { background, textSecondary, text } = useColorsContext()
    const defaultStyles = useStyles()

    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })
    const params = route.params

    useEffect(() => {
        if (params?.tab) {
            setValue(params?.tab)
        }
    }, [params?.tab])

    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        scrollView: {
            flexGrow: 1,
            justifyContent: 'space-between',
            backgroundColor: background,
        },
        contentContainer: {
            padding: 0,
        },
    })

    useEffect(() => {
        if (params?.search) {
            setSearchText(params?.search)
        }

        return () => {
            setSearchText('')
        }
    }, [params])

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView
                contentContainerStyle={styles.scrollView}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <Container>
                    {params?.search ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 2,
                                pl: { xs: 1, md: 4 },
                            }}
                        >
                            <Typography
                                color={textSecondary}
                                variant="caption"
                                fontFamily={defaultStyles.titleFont}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigation.navigate('Help')
                                }}
                            >
                                Tutred
                            </Typography>
                            <AntDesign
                                name="arrowright"
                                size={12}
                                color={textSecondary}
                                style={{ marginHorizontal: 4 }}
                            />
                            <Typography
                                color={text}
                                variant="caption"
                                fontFamily={defaultStyles.titleFont}
                            >
                                Search
                            </Typography>
                        </Box>
                    ) : (
                        <ContentHeader />
                    )}

                    <Box
                        sx={{ padding: { xs: 0, md: 4 }, py: { xs: 1, md: 4 } }}
                    >
                        <Autocomplete
                            disablePortal={true}
                            options={articles}
                            // open={!open}
                            groupBy={(option) => {
                                return option?.type
                            }}
                            renderGroup={(params) => (
                                <li key={params.key}>
                                    {params.group && (
                                        <Typography
                                            sx={{ p: 1, pl: 2 }}
                                            variant="body2"
                                            textTransform="uppercase"
                                            color={textSecondary}
                                            fontFamily={defaultStyles.titleFont}
                                        >
                                            {params.group}
                                        </Typography>
                                    )}
                                    <GroupItems children={params.children} />
                                </li>
                            )}
                            // isOptionEqualToValue={(option, value) => {
                            //     return option.id === value.id
                            // }}
                            getOptionLabel={(option) =>
                                option?.title ? option.title : ''
                            }
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            onChange={(_, value) => {
                                if (value?.slug) {
                                    navigation.navigate('HelpArticle', {
                                        slug: value.slug,
                                    })
                                }
                            }}
                            freeSolo
                            clearOnBlur={false}
                            selectOnFocus={false}
                            inputValue={searchText}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search articles..."
                                    variant="outlined"
                                    fullWidth
                                    onKeyDown={(evt) => {
                                        if (evt.key === 'Enter') {
                                            navigation.push('Help', {
                                                search: params.inputProps.value,
                                            })
                                        }
                                    }}
                                    onChange={(event) => {
                                        console.log('event', event)
                                        setSearchText(event.target.value)
                                    }}
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon
                                                        size={20}
                                                        color={'#000'}
                                                    />
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{ paddingX: { xs: 0, md: 4 } }}>
                        {params?.search ? (
                            <SearchResults />
                        ) : (
                            <>
                                <Box>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        variant={
                                            isMd ? 'standard' : 'fullWidth'
                                        }
                                    >
                                        <Tab
                                            label="Student"
                                            value="student"
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.titleFont,
                                            }}
                                        />
                                        <Tab label="Tutor" value="tutor" />
                                    </Tabs>
                                </Box>
                                <Box sx={{ padding: { xs: 1, md: 2 } }}>
                                    {value === 'student' && (
                                        <HelpSections type="student" />
                                    )}
                                    {value === 'tutor' && (
                                        <HelpSections type="tutor" />
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Container>

                {isSm ? <Footer /> : null}
            </ScrollView>
        </SafeAreaView>
    )
}

export default Help
