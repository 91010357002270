import React from 'react';

const EmptyTutorListIcon = () => (
    <svg viewBox="0 0 800 405" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_29_31)">
            <path d="M532.063 272.676C509.058 260.841 501.053 225.456 512.518 183.621C523.983 141.785 557.945 84.5358 534.608 60.4839C511.271 36.432 492.071 29.0917 466.527 61.4305C441.177 93.5623 434.313 58.2461 449.082 32.7838C463.693 7.60359 493.356 -17.1065 543.108 15.8904C578.575 39.4095 587.175 92.7725 569.799 140.061C552.999 185.821 561.593 211.039 574.274 221.551C586.955 232.063 562.822 288.497 532.063 272.676Z" fill="#FE8A24" />
            <path d="M296.496 117.119C243.152 117.119 199.912 171.98 199.912 239.667V292.171H393.099V239.667C393.099 171.98 349.859 117.119 296.496 117.119Z" fill="#0571FF" />
            <path d="M444.067 163.75L389.845 148.273L350.793 153.476C321.607 109.829 278.863 136.294 270.507 146.167C267.223 150.047 287.495 155.57 293.663 172.156C280.944 185.614 264.828 214.323 290.309 244.468C264.502 248.931 253.394 269.861 253.394 269.861H328.07C363.286 281.96 404.018 274.049 421.864 267.511C448.712 257.682 501.273 194.152 444.067 163.75Z" fill="#FE8A24" />
            <path d="M372.976 188.128C373.185 183.362 370.907 179.391 367.887 179.259C364.868 179.127 362.251 182.883 362.042 187.649C361.833 192.415 364.112 196.386 367.131 196.518C370.15 196.651 372.767 192.894 372.976 188.128Z" fill="black" />
            <path d="M331.123 190.872C331.332 186.106 329.054 182.136 326.035 182.003C323.015 181.871 320.398 185.627 320.189 190.393C319.98 195.159 322.259 199.13 325.278 199.262C328.297 199.395 330.915 195.638 331.123 190.872Z" fill="#0E0E0E" />
            <path d="M335.661 206.312L342.964 212.662C343.525 213.102 344.222 213.33 344.935 213.309C345.647 213.287 346.329 213.016 346.863 212.543L353.758 206.375C355.407 204.896 354.353 202.539 352.028 202.507L337.849 202.326C335.36 202.294 333.981 204.852 335.661 206.312Z" fill="black" />
            <path d="M343.942 209.992C343.728 215.383 346.718 231.373 353.05 218.768" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M279.697 150.223C279.697 150.223 301.636 133.229 321.946 148.969" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M295.155 205.604C295.155 205.604 258.478 171.19 215.37 197.286" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M295.405 212.405C295.405 212.405 252.811 185.652 215.502 219.514" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M270.169 269.862C270.169 269.862 280.894 250.429 295.581 250.116" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M288.115 269.862C288.115 269.862 298.834 250.429 313.527 250.116" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M469.711 288.648H353.012V402.589H469.711V288.648Z" fill="#006EFF" />
            <path d="M393.725 269.861H100.339V292.171H393.725V269.861Z" fill="#02459E" />
            <path d="M394.979 290.29V239.667C394.979 206.513 384.793 175.321 366.289 151.846C353.539 135.642 337.867 124.434 320.723 119H489.682C541.898 119 584.385 173.127 584.385 239.667V290.29H394.979Z" fill="white" />
            <path d="M489.682 120.88C540.864 120.88 582.505 174.162 582.505 239.667V288.41H396.86V239.667C396.86 206.093 386.529 174.475 367.768 150.655C358.578 138.996 347.853 129.825 335.893 123.406C334.188 122.499 332.472 121.649 330.747 120.855H489.682M489.682 117.094H296.496C349.834 117.094 393.073 171.961 393.073 239.641V292.171H586.266V239.667C586.266 171.968 543.014 117.119 489.682 117.119V117.094Z" fill="black" />
            <path d="M379.578 205.604C379.578 205.604 416.26 171.19 459.368 197.286" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M379.333 212.405C379.333 212.405 421.921 185.652 459.237 219.514" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.88055 402.595H798.12" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_29_31">
                <rect width="800" height="404.476" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default EmptyTutorListIcon;