import { useColorsContext } from "@utils/styles"
import * as React from "react"
import Svg, { Path, G, } from "react-native-svg"

const SpecialtyIcon = ({
  size = 20,
}) => {
  const { text, textInverse } = useColorsContext();
  return (
    <svg width={size} height={size * 23 / 24} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.04 6.46379H1.72C1.32232 6.46379 1 6.15805 1 5.78082V1.68297C1 1.30574 1.32232 1 1.72 1H6.04C6.43768 1 6.76 1.30574 6.76 1.68297V5.78082C6.76 6.15805 6.43768 6.46379 6.04 6.46379Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M14.2 6.46379H9.88003C9.48235 6.46379 9.16003 6.15805 9.16003 5.78082V1.68297C9.16003 1.30574 9.48235 1 9.88003 1H14.2C14.5977 1 14.92 1.30574 14.92 1.68297V5.78082C14.92 6.15805 14.5977 6.46379 14.2 6.46379Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M6.04 14.2041H1.72C1.32232 14.2041 1 13.8984 1 13.5212V9.42333C1 9.0461 1.32232 8.74036 1.72 8.74036H6.04C6.43768 8.74036 6.76 9.0461 6.76 9.42333V13.5212C6.76 13.8984 6.43768 14.2041 6.04 14.2041Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M22.3599 14.2041H18.0399C17.6423 14.2041 17.3199 13.8984 17.3199 13.5212V9.42333C17.3199 9.0461 17.6423 8.74036 18.0399 8.74036H22.3599C22.7576 8.74036 23.0799 9.0461 23.0799 9.42333V13.5212C23.0799 13.8984 22.7576 14.2041 22.3599 14.2041Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M14.2 14.2041H9.88003C9.48235 14.2041 9.16003 13.8984 9.16003 13.5212V9.42333C9.16003 9.0461 9.48235 8.74036 9.88003 8.74036H14.2C14.5977 8.74036 14.92 9.0461 14.92 9.42333V13.5212C14.92 13.8984 14.5977 14.2041 14.2 14.2041Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M6.04 21.9445H1.72C1.32232 21.9445 1 21.6388 1 21.2615V17.1637C1 16.7865 1.32232 16.4807 1.72 16.4807H6.04C6.43768 16.4807 6.76 16.7865 6.76 17.1637V21.2615C6.76 21.6388 6.43768 21.9445 6.04 21.9445Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M22.3599 21.9445H18.0399C17.6423 21.9445 17.3199 21.6388 17.3199 21.2615V17.1637C17.3199 16.7865 17.6423 16.4807 18.0399 16.4807H22.3599C22.7576 16.4807 23.0799 16.7865 23.0799 17.1637V21.2615C23.0799 21.6388 22.7576 21.9445 22.3599 21.9445Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M14.2 21.9445H9.88003C9.48235 21.9445 9.16003 21.6388 9.16003 21.2615V17.1637C9.16003 16.7865 9.48235 16.4807 9.88003 16.4807H14.2C14.5977 16.4807 14.92 16.7865 14.92 17.1637V21.2615C14.92 21.6388 14.5977 21.9445 14.2 21.9445Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M20.1999 6.46379C21.7905 6.46379 23.0799 5.24068 23.0799 3.73189C23.0799 2.22311 21.7905 1 20.1999 1C18.6094 1 17.3199 2.22311 17.3199 3.73189C17.3199 5.24068 18.6094 6.46379 20.1999 6.46379Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
    </svg>

  )
}


export default SpecialtyIcon
