import { useDialog } from '@contexts/dialogContext'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import BecomeATutorHero from './BecomeATutorHero'
import TeachAndEarn from './TeachAndEarn'
import ExploreAI from './ExploreAI'
import TeachDifferentLanguages from './TeachDifferentLanguages'
import TeachDiverse from './TeachDiverse'
import StartTeaching from './StartTeaching'
import Footer from '@components/Footer'
import MoreQuestionsFooter from '@components/MoreQuestionsFooter.tsx'

const BecomeATutor: React.FC = () => {
    const { primary, primaryLight, primaryDark } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const { openSignupDialog } = useDialog()

    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: '#FCFCFC',
                width: '100%',
                overflowY: 'auto',
            }}
        >
            <Box
                sx={{
                    maxWidth: 1280,
                    margin: 'auto',
                    overflow: 'hidden',
                }}
            >
                <BecomeATutorHero />
                <StartTeaching />
                <TeachDiverse />
                <TeachDifferentLanguages />
                <ExploreAI />
                <TeachAndEarn />
                <MoreQuestionsFooter />
            </Box>
            <Footer />
        </Box>
    )
}

export default BecomeATutor
