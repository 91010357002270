import { TutorReview } from '@components/TutorReview'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { Box, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useColorsContext } from '@utils/styles'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React from 'react'
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'

interface Props {
    tutor: any
}

const Reviews: React.FC<Props> = ({ tutor }) => {
    const { primaryLight, text } = useColorsContext()
    const { db } = useFirebaseContext()

    const { data, isLoading } = useQuery({
        queryKey: ['Tutor Reviews', tutor.id],
        queryFn: async () => {
            const reviewsRef = collection(db, 'reviews')
            const q = query(reviewsRef, where('tutor', '==', tutor.uid))

            const querySnapshot = await getDocs(q)
            const reviews = querySnapshot.docs.map((doc) => doc.data())
            return reviews
        },
        enabled: !!tutor && !!db,
    })

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <ActivityIndicator size={'large'} />
                <Typography
                    variant="h6"
                    sx={{ textAlign: 'center', marginTop: 4 }}
                >
                    Loading Reviews
                </Typography>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                marginTop: 3,
                p: 2,
                background: primaryLight,
                borderRadius: 4,
            }}
        >
            {data?.length ? (
                data.map((review, index) => {
                    return (
                        <TutorReview
                            key={review.sessionId || `review-${index}`}
                            user={review.user}
                            review={review}
                            tutor={tutor}
                        ></TutorReview>
                    )
                })
            ) : (
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h6"
                        fontFamily="Poppins_600SemiBold"
                        sx={{ color: text, my: 2 }}
                    >
                        {tutor?.name?.split(' ')[0]} has no reviews yet
                    </Typography>
                    <img
                        style={{
                            maxWidth: 200,
                            maxHeight: 200,
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                        src={require('@assets/images/profile/empty-reviews.svg')}
                        alt="empty-reviews"
                    />
                </Box>
            )}
        </Box>
    )
}

export default Reviews
