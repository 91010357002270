import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { EditProfileForm } from '@screens/loggedIn/Profile/EditProfileNew'
import React, { useEffect, useState } from 'react'
import {
    Control,
    Controller,
    FieldErrors,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form'
import { View, Text, StyleSheet, TextInput } from 'react-native'
import { provinces, statesUSA, countries } from '@config/locations'

interface EditPersonalDetailsProps {
    user: any
    control: Control<any>
    errors: FieldErrors<EditProfileForm>
    watch: UseFormWatch<EditProfileForm>
    setValue: UseFormSetValue<EditProfileForm>
}

const EditPersonalDetails: React.FC<EditPersonalDetailsProps> = ({
    user,
    control,
    errors,
    watch,
    setValue,
}) => {
    const watchCountry = watch('location.country')
    const watchProvince = watch('location.province')

    useEffect(() => {
        if (
            watchCountry === 'United States' &&
            !statesUSA.includes(watchProvince)
        ) {
            setValue('location.province', '')
        } else if (
            watchCountry === 'Canada' &&
            !provinces.includes(watchProvince)
        ) {
            setValue('location.province', '')
        }
    }, [watchCountry, watchProvince])

    return (
        <Box sx={styles.container}>
            <Box sx={styles.inputContainer}>
                <Controller
                    defaultValue={user?.email}
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            disabled
                            inputRef={ref}
                            sx={styles.input}
                            {...field}
                            label="Email"
                            error={!!errors?.email}
                            placeholder="Enter your Email"
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    defaultValue={user?.name}
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            disabled={user?.isVerified}
                            inputRef={ref}
                            sx={styles.input}
                            {...field}
                            label="Name"
                            error={!!errors?.name}
                            placeholder="Enter your Name"
                        />
                    )}
                />
            </Box>
            <Typography variant="subtitle1" sx={styles.inputContainer}>
                Location
            </Typography>
            <Box sx={styles.inputContainer}>
                <Controller
                    name="location.country"
                    defaultValue={user?.location?.country}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete
                            disablePortal={true}
                            options={countries}
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    inputRef={ref}
                                    {...params}
                                    label="Country"
                                    error={!!errors?.location?.country}
                                    placeholder="Select a Country"
                                />
                            )}
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    name="location.province"
                    defaultValue={user?.location?.province}
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete
                            disablePortal={true}
                            options={
                                watchCountry === 'United States'
                                    ? statesUSA
                                    : provinces
                            }
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    inputRef={ref}
                                    {...params}
                                    label={
                                        watchCountry === 'United States'
                                            ? 'State'
                                            : 'Province'
                                    }
                                    error={!!errors?.location?.province}
                                    placeholder={
                                        watchCountry === 'United States'
                                            ? 'Select a State'
                                            : 'Select a Province'
                                    }
                                />
                            )}
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    defaultValue={user?.location?.city}
                    name="location.city"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            inputRef={ref}
                            sx={styles.input}
                            {...field}
                            label="City"
                            error={!!errors?.location?.city}
                            placeholder="Enter your City"
                        />
                    )}
                />
            </Box>
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    inputContainer: {
        marginBottom: 2,
    },
    input: {
        width: '100%',
    },
})

export default EditPersonalDetails
