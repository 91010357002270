import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useStyles } from '@utils/styles'
import { TypeAnimation } from 'react-type-animation'
import { subjectIcons } from '@config/subjects'

const icons = [
    'Engineering',
    'Chemistry',
    'Physics',
    'Mathematics',
    'Biology',
    'Computer Science',
    'Medicine',
    'Actuarial Science',
    'Political Science',
    '',
    '',
    '',
    'Anthropology',
    'Language',
    'Finance',
    'Law',
    '',
    '',
    '',
    'Architecture',
    'Geography',
    'Sociology',
    'Economics',
    'History',
    'Philosophy',
    'Psychology',
    'Art',
    'Education',
]

const FindTutors: React.FC = () => {
    const defaultStyles = useStyles()

    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Card
            sx={{
                backgroundColor: '#FF73C0',
                borderRadius: '30px',
                p: { xs: 1, sm: 3, md: 4 },
                mt: 10,
                position: 'relative',
                overflow: 'hidden',
            }}
            elevation={0}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: { xs: 2, sm: 4 },
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: { xs: 2, sm: 0 },
                        }}
                    >
                        {icons.slice(0, 7).map((icon, index) => (
                            <Box
                                key={index}
                                component="img"
                                src={subjectIcons[icon]}
                                alt={icon}
                                sx={{
                                    width: { xs: 25, sm: 40, md: 60 },
                                    height: { xs: 25, sm: 40, md: 60 },
                                }}
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: { xs: 2, sm: 0 },
                        }}
                    >
                        {icons.slice(7, 14).map((icon, index) => (
                            <Box
                                key={index}
                                component={icon !== '' ? 'img' : 'div'}
                                src={subjectIcons[icon]}
                                alt={icon}
                                sx={{
                                    width: { xs: 25, sm: 40, md: 60 },
                                    height: { xs: 25, sm: 40, md: 60 },
                                }}
                            />
                        ))}
                    </Box>
                    <Box
                        component="img"
                        src={require('@assets/images/landingpage/find-tutors1.svg')}
                        alt="find tutors img"
                        sx={{
                            flex: 1,
                            maxHeight: { xs: 75, sm: 120, md: 180 },
                            position: 'absolute',
                            left: {
                                xs: `calc(50% - 37.5px)`,
                                sm: `calc(50% - 60px)`,
                                md: `calc(50% - 90px)`,
                            },
                            top: {
                                xs: `calc(50% - 45px)`,
                                sm: `calc(50% - 60px)`,
                                md: `calc(50% - 90px)`,
                            },
                        }}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: { xs: 2, sm: 0 },
                        }}
                    >
                        {icons.slice(14, 21).map((icon, index) => (
                            <Box
                                key={index}
                                component={icon !== '' ? 'img' : 'div'}
                                src={subjectIcons[icon]}
                                alt={icon}
                                sx={{
                                    width: { xs: 25, sm: 40, md: 60 },
                                    height: { xs: 25, sm: 40, md: 60 },
                                    objectFit: 'contain',
                                }}
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: { xs: 2, sm: 0 },
                        }}
                    >
                        {icons.slice(21, 28).map((icon, index) => (
                            <Box
                                key={index}
                                component="img"
                                src={subjectIcons[icon]}
                                alt={icon}
                                sx={{
                                    width: { xs: 25, sm: 40, md: 60 },
                                    height: { xs: 25, sm: 40, md: 60 },
                                }}
                            />
                        ))}
                    </Box>
                </Box>
                <Typography
                    variant={isMd ? 'h4' : isSm ? 'h5' : 'body2'}
                    fontFamily={defaultStyles.boldFont}
                    sx={{
                        color: '#FFF',
                        mt: { xs: 2, sm: 3, md: 4 },
                        textAlign: 'left',
                    }}
                >
                    Find tutors that{' '}
                    <span style={{ color: '#000' }}>
                        <TypeAnimation
                            className="highlight-animated"
                            sequence={[
                                500,
                                'speak french',
                                1000,
                                'teach calculus',
                                1000,
                                'are in Alberta',
                                1000,
                                'are verified',
                                500,
                            ]}
                            speed={50}
                            repeat={Infinity}
                        />
                    </span>
                </Typography>
            </CardContent>
        </Card>
    )
}

export default FindTutors
