import TutredAvatar from '@components/TutredAvatar'
import {
    Card,
    Box,
    Typography,
    Button,
    useMediaQuery,
    useTheme,
    Collapse,
} from '@mui/material'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import TutorName from '../TutorName'
import Favourite from '@components/Favourite'
import Rating from '@components/TutorListCard/Rating'
import Location from '@components/TutorListCard/Location'
import { getCurrentTimeInLocation } from '@helpers/getCurrentTimeInLocation'
import { useColorsContext, useStyles } from '@utils/styles'
import SendIcon from '@icons/SendIcon'
import TutredButton from '@components/TutredButton'
import { useDialog } from '@contexts/dialogContext'
import useNavigateToChat from '@hooks/useNavigateToChat'
import { useUserContext } from '@contexts/userContext'
import CheckIcon from '@icons/CheckIcon'
import { NativeEventEmitter } from 'react-native'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useAlert } from 'react-alert'
import BookingInfo from './BookingInfo'
import Caret from '@icons/Caret'
import TutredTooltip from '@components/TutredTooltip'
import InstabookIcon from '@icons/InstabookIcon'
import InstabookInfo from './InstabookInfo'
import CancelModal from '@components/CancelModal'
import { confirmAlert } from 'react-confirm-alert' // Import

interface TutorSidebarProps {
    tutor: any
    rating: string
    reviewCount: number
    setShowBookingDialog: (show: boolean) => void
    setShowInstaBookConfirmModal: (show: boolean) => void
    setLoading
}
const TutorSidebar: React.FC<TutorSidebarProps> = ({
    tutor,
    rating,
    reviewCount,
    setShowBookingDialog,
    setShowInstaBookConfirmModal,
    setLoading,
}) => {
    const defaultStyles = useStyles()
    const { primaryLight, primary, text, background, border, disabled } =
        useColorsContext()
    const { navigateToChat } = useNavigateToChat()
    const { openLoginDialog } = useDialog()
    const { user, profile, extendedUser } = useUserContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const eventEmitter = new NativeEventEmitter()
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const customAlert = useAlert()
    const [bookingPanel, setBookingPanel] = React.useState(false)
    const [instaBookPanel, setInstaBookPanel] = React.useState(false)
    const [bookingTogglePosition, setBookingTogglePosition] = React.useState(60)
    const [bookingToggleTimeout, setBookingToggleTimeout] =
        useState<NodeJS.Timeout | null>(null)
    const [instaBookTogglePosition, setInstaBookTogglePosition] =
        React.useState(60)
    const [instaBookToggleTimeout, setInstaBookToggleTimeout] =
        useState<NodeJS.Timeout | null>(null)

    const showBookingPanel =
        tutor?.autoAccept || tutor?.sessionRecordingFee || tutor?.extensionRate
    const showInstabookPanel =
        tutor?.autoAccept || tutor?.sessionRecordingFee || tutor?.extensionRate

    const animateBookingToggle = useCallback(() => {
        // Don't animate if booking panel is open
        if (bookingPanel) return

        // Clear any existing timeout
        if (bookingToggleTimeout) {
            clearTimeout(bookingToggleTimeout)
        }

        // Initial position
        setBookingTogglePosition(16)

        const timeout = setTimeout(() => {
            setBookingTogglePosition(60)
        }, 5000)

        setBookingToggleTimeout(timeout)
    }, [bookingToggleTimeout, bookingPanel])

    const animateInstaBookToggle = useCallback(() => {
        // Don't animate if booking panel is open
        if (instaBookPanel) return

        // Clear any existing timeout
        if (instaBookToggleTimeout) {
            clearTimeout(instaBookToggleTimeout)
        }

        // Initial position
        setInstaBookTogglePosition(16)

        const timeout = setTimeout(() => {
            setInstaBookTogglePosition(60)
        }, 5000)

        setInstaBookToggleTimeout(timeout)
    }, [instaBookToggleTimeout, instaBookPanel])

    const bookable = useMemo(() => {
        if (!tutor?.sessionPricing?.[30] || !tutor?.sessionPricing?.[60])
            return false
        return true
    }, [tutor])

    console.log('tutor', tutor)

    return (
        <Card
            sx={{
                height: '100%',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                width: 364,
                overflowY: 'auto',
                borderRadius: 6,
                boxShadow: '0px 0px 8px 0 rgba(0,0,0,0.25)',

                '& .MuiPaper-root': {
                    boxShadow: '0px 0px 8px 0 rgba(0,0,0,0.25)',
                    overflowY: 'auto',
                    borderRadius: 6,
                },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 3,
                }}
            >
                <Favourite
                    tutor={tutor}
                    containerStyle={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                />
                <Rating
                    reviewCount={reviewCount}
                    rating={rating}
                    size="medium"
                />
            </Box>
            <Box>
                <TutredAvatar user={tutor} size="large" clickable={false} />
                <TutorName tutor={tutor} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 3,
                    px: 5,
                }}
            >
                <Location province={tutor?.location?.province} />
                <Typography
                    variant="body1"
                    sx={{
                        marginLeft: 4,
                    }}
                    fontFamily={defaultStyles.titleFont}
                >
                    {getCurrentTimeInLocation(tutor?.location?.province)}
                </Typography>
            </Box>
            <Box
                sx={{
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    mt: 1,
                    pl: 1,
                }}
            >
                {tutor?.uid === user?.uid ? (
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            navigation.navigate('EditProfile')
                        }}
                        disableElevation
                        size="medium"
                        sx={{
                            backgroundColor: primary,
                            fontFamily: defaultStyles.titleFont,
                            color: '#000',
                            fontSize: '1.25rem',
                            textTransform: 'none',
                            borderWidth: 3,
                            borderColor: '#000',
                            p: 0,
                        }}
                    >
                        Edit Profile
                    </Button>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                mx: 3.5,
                            }}
                        >
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => {
                                    if (!user) {
                                        openLoginDialog(
                                            'Please log in to send a message'
                                        )
                                    }
                                    navigateToChat(tutor, extendedUser, profile)
                                }}
                                disableElevation
                                sx={{
                                    backgroundColor: primaryLight,
                                    borderColor: '#D0D0D0',
                                    color: '#000',
                                    fontFamily: defaultStyles.titleFont,
                                    fontSize: '1.25rem',
                                    width: { md: '100%' },
                                    p: 0,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    pr: 1,
                                    mb: 2,
                                }}
                                endIcon={isMd ? <SendIcon size={32} /> : null}
                                size={!isMd ? 'small' : 'medium'}
                            >
                                {isMd ? 'Message' : <SendIcon size={32} />}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                backgroundColor: showBookingPanel
                                    ? primaryLight
                                    : '',
                                borderRadius: 3,
                                mb: 2,
                                position: 'relative',
                                mx: 3.5,
                            }}
                            onMouseOver={() => {
                                showBookingPanel && animateBookingToggle()
                            }}
                        >
                            <Button
                                variant="outlined"
                                fullWidth
                                disabled={!bookable}
                                onClick={() => {
                                    if (user) {
                                        if (extendedUser.isTutor) {
                                            customAlert.error(
                                                '[Booking Error] Only students can book sessions!'
                                            )
                                        } else {
                                            setShowBookingDialog(true)
                                        }
                                    } else {
                                        openLoginDialog(
                                            'Please log in to book a session'
                                        )
                                        eventEmitter.addListener(
                                            'login-success',
                                            ({ extendedUser }) => {
                                                if (extendedUser.isTutor) {
                                                    navigation.navigate(
                                                        'Sessions'
                                                    )
                                                } else {
                                                    setShowBookingDialog(true)
                                                }
                                            }
                                        )
                                    }
                                }}
                                disableElevation
                                size="medium"
                                sx={{
                                    backgroundColor: primary,
                                    fontFamily: defaultStyles.titleFont,
                                    color: '#000',
                                    fontSize: '1.25rem',
                                    textTransform: 'none',
                                    borderWidth: 3,
                                    borderColor: '#000',
                                    p: 0,
                                    zIndex: 99,
                                }}
                                endIcon={
                                    <CheckIcon
                                        size={22}
                                        color={theme.palette.common.black}
                                    />
                                }
                            >
                                Book ${tutor?.sessionPricing?.[60]?.rate}
                                /hr
                            </Button>
                            <Box
                                sx={{
                                    height: '100%',
                                    width: 54,
                                    pl: 1.5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    left: `calc(100% - ${bookingTogglePosition}px)`,
                                    borderRadius: 2.5,
                                    backgroundColor: primaryLight,
                                    transition: 'left 0.3s ease-in-out',
                                }}
                                onClick={() => {
                                    showBookingPanel &&
                                        setBookingPanel(!bookingPanel)
                                    if (bookingPanel) {
                                        const timeout = setTimeout(() => {
                                            setBookingTogglePosition(60)
                                        }, 5000)

                                        setBookingToggleTimeout(timeout)
                                    } else {
                                        clearTimeout(bookingToggleTimeout)
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transform: bookingPanel
                                            ? 'rotate(180deg)'
                                            : 'rotate(0deg)',
                                        transition:
                                            'transform 0.3s ease-in-out',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Caret size={18} />
                                </Box>
                            </Box>
                        </Box>
                        <BookingInfo show={bookingPanel} tutor={tutor} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: showInstabookPanel
                                    ? primaryLight
                                    : '',
                                alignItems: 'center',
                                borderRadius: 3,
                                mb: 2,
                                mx: 3.5,
                                position: 'relative',
                            }}
                        >
                            <TutredTooltip
                                placement="top"
                                title={
                                    !tutor.instaBook
                                        ? 'This tutor is not available right now'
                                        : ''
                                }
                            >
                                <Box sx={{ width: '100%' }}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        disabled={
                                            !tutor.instaBookPricing ||
                                            !tutor.instaBook
                                        }
                                        onClick={() => {
                                            if (!tutor?.instaBookPricing) {
                                                customAlert.error(
                                                    '[Error Booking] This tutor hasnt set up their pricing yet, please contact support'
                                                )
                                            } else if (!user) {
                                                openLoginDialog(
                                                    'Please log in to start an instabook session'
                                                )

                                                eventEmitter.addListener(
                                                    'login-success',
                                                    () => {
                                                        setShowInstaBookConfirmModal(
                                                            true
                                                        )
                                                    }
                                                )
                                            } else {
                                                if (
                                                    extendedUser?.paymentMethods
                                                        ?.length === 0
                                                ) {
                                                    confirmAlert({
                                                        customUI: ({
                                                            onClose,
                                                        }) => {
                                                            return (
                                                                <CancelModal
                                                                    onClose={() => {
                                                                        onClose()
                                                                    }}
                                                                    title="No Payment Methods Found"
                                                                    message={
                                                                        'In order to book a tutor, you must add a payment method to your account, please click the button below to add a payment method you will be redirected to the settings page. Once you have added a payment method, please try booking the tutor again.'
                                                                    }
                                                                    onAccept={() => {
                                                                        onClose()
                                                                        navigation.navigate(
                                                                            'Home',
                                                                            {
                                                                                screen: 'Profile',
                                                                            }
                                                                        )
                                                                    }}
                                                                    colors={{
                                                                        primary,
                                                                        text,
                                                                        background,
                                                                        border,
                                                                    }}
                                                                    defaultStyles={
                                                                        defaultStyles
                                                                    }
                                                                ></CancelModal>
                                                            )
                                                        },
                                                    })
                                                } else {
                                                    // Confirm alert
                                                    setShowInstaBookConfirmModal(
                                                        true
                                                    )
                                                }
                                            }
                                        }}
                                        disableElevation
                                        size="medium"
                                        sx={{
                                            '& .Mui-disabled': {
                                                backgroundColor: 'gray',
                                                color: '#FFF',
                                            },
                                            backgroundColor: primary,
                                            fontFamily: defaultStyles.titleFont,
                                            color: '#FFF',
                                            fontSize: '1.25rem',
                                            textTransform: 'none',
                                            borderWidth: 3,
                                            borderColor: '#000',
                                            p: 0,
                                            zIndex: 99,
                                        }}
                                        endIcon={
                                            <InstabookIcon
                                                color="#FFF"
                                                size={32}
                                            />
                                        }
                                        onMouseOver={() => {
                                            showInstabookPanel &&
                                                animateInstaBookToggle()
                                        }}
                                    >
                                        Instabook
                                    </Button>
                                </Box>
                            </TutredTooltip>

                            <Box
                                sx={{
                                    height: '100%',
                                    width: 54,
                                    pl: 1.5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    left: `calc(100% - ${instaBookTogglePosition}px)`,
                                    borderRadius: 2.5,
                                    backgroundColor: primaryLight,
                                    transition: 'left 0.3s ease-in-out',
                                }}
                                onClick={() => {
                                    showInstabookPanel &&
                                        setInstaBookPanel(!instaBookPanel)
                                    if (instaBookPanel) {
                                        const timeout = setTimeout(() => {
                                            setInstaBookTogglePosition(60)
                                        }, 5000)

                                        setInstaBookToggleTimeout(timeout)
                                    } else {
                                        clearTimeout(instaBookToggleTimeout)
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transform: instaBookPanel
                                            ? 'rotate(180deg)'
                                            : 'rotate(0deg)',
                                        transition:
                                            'transform 0.3s ease-in-out',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Caret size={18} />
                                </Box>
                            </Box>
                        </Box>
                        <InstabookInfo show={instaBookPanel} tutor={tutor} />
                    </Box>
                )}
            </Box>
        </Card>
    )
}

export default TutorSidebar
