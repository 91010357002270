const useGetFilterOptions = () => {
    return (label, options, selectedSubjects) => {
        if (label === 'Exams' || label === 'Degrees') {
            const filterOptions = []
            Object.keys(options).forEach((key) => {
                options[key].forEach((option) => {
                    filterOptions.push({
                        id: option.label,
                        label: option.label,
                        group: key,
                    })
                })
            })
            filterOptions.sort((a, b) => (a.group > b.group ? 1 : -1))
            return filterOptions
        } else if (label === 'Specialties') {
            const filterOptions = []
            Object.keys(options).forEach((key) => {
                if (
                    selectedSubjects?.length === 0 ||
                    selectedSubjects?.includes(key)
                ) {
                    options[key].specialties.forEach((option) => {
                        filterOptions.push({
                            id: option,
                            label: option,
                            group: key,
                        })
                    })
                }
            })
            filterOptions.sort((a, b) => (a.group > b.group ? 1 : -1))
            return filterOptions
        } else {
            return options
        }
    }
}

export default useGetFilterOptions
