import {
    Box,
    Collapse,
    CSSObject,
    Drawer,
    IconButton,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import TutorListFilters from '../TutorListFilters'
import { FilterType } from 'dataTypes'
import FilterIcon from '@icons/FilterIcon'
import { useColorsContext, useStyles } from '@utils/styles'

interface Props {
    filters: FilterType[]
    setFilters: React.Dispatch<React.SetStateAction<FilterType[]>>
    resetFilters: () => void
}
export const TutorListLargeDrawer: React.FC<Props> = ({
    filters,
    setFilters,
    resetFilters,
}) => {
    const [drawerOpen, setDrawerOpen] = React.useState(true)
    const defaultStyles = useStyles()
    const { primaryLight } = useColorsContext()
    const theme = useTheme()
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

    return (
        <>
            {!isMd && (
                <Box
                    onClick={() => setDrawerOpen(!drawerOpen)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        p: 2,
                        borderBottom: 1,
                        borderTop: 1,
                        borderColor: 'divider',
                        mb: 1,
                        backgroundColor: primaryLight,
                    }}
                >
                    <Typography
                        variant="body1"
                        fontFamily={defaultStyles.boldFont}
                    >
                        View Filters
                    </Typography>
                    <FilterIcon size={24} />
                </Box>
            )}
            <Drawer
                variant={isMd ? 'permanent' : 'temporary'}
                anchor={isMd ? 'left' : 'top'}
                open={!drawerOpen}
                sx={{
                    borderWidth: 0,
                    position: isMd ? 'relative' : 'absolute',

                    overflow: 'hidden',

                    height: '100%',
                    width: isMd ? 344 : '100%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        borderWidth: 0,
                        position: isMd ? 'relative' : 'absolute',
                        border: 'none',
                        width: isMd ? 344 : '100%',
                        height: '100%',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        pb: isMd ? 2 : 0,
                    },
                }}
            >
                <TutorListFilters
                    filters={filters}
                    setFilters={setFilters}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    resetFilters={resetFilters}
                />
            </Drawer>
        </>
        // <Collapse
        //     in={drawerOpen}
        //     orientation={isMd ? 'horizontal' : 'vertical'}
        //     sx={{ overflow: 'hidden' }}
        // >

        // </Collapse>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
