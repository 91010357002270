import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useColorsContext } from '@utils/styles';

const VisibleFaceIcon = ({ size = 35 }) => {
  const { text } = useColorsContext();
  return (
    <Svg width={size} height={size} viewBox="0 0 35 35" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1997 23.1953C21.9946 18.888 13.0057 18.888 5.8007 23.1953C5.14264 23.5887 4.73975 24.299 4.73975 25.0657V30.6003C4.73975 32.0098 5.88235 33.1524 7.29183 33.1524H27.7085C29.118 33.1524 30.2606 32.0098 30.2606 30.6003V25.0657C30.2606 24.299 29.8577 23.5887 29.1997 23.1953Z"
        fill={text}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7326 12.1019C18.3712 11.5917 20.0987 10.7244 21.4665 9.2988C21.8691 9.99544 22.0991 10.8036 22.0991 11.6663C22.0991 14.2839 19.9771 16.4059 17.3595 16.4059C15.1243 16.4059 13.2505 14.8586 12.7507 12.7768C13.8984 12.7355 15.3065 12.5459 16.7326 12.1019ZM17.3595 4.73926C13.5337 4.73926 10.4324 7.84062 10.4324 11.6663C10.4324 15.4921 13.5337 18.5934 17.3595 18.5934C21.1852 18.5934 24.2866 15.4921 24.2866 11.6663C24.2866 9.75579 23.5116 8.02414 22.2616 6.7721C21.009 5.51742 19.274 4.73926 17.3595 4.73926Z"
        fill={text}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37508 4.01025C4.17373 4.01025 4.0105 4.17349 4.0105 4.37484V7.2915C4.0105 7.89556 3.5208 8.38525 2.91675 8.38525C2.31269 8.38525 1.823 7.89556 1.823 7.2915V4.37484C1.823 2.96536 2.9656 1.82275 4.37508 1.82275H7.29175C7.8958 1.82275 8.3855 2.31245 8.3855 2.9165C8.3855 3.52056 7.8958 4.01025 7.29175 4.01025H4.37508Z"
        fill={text}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37508 15.6768C4.17373 15.6768 4.0105 15.5136 4.0105 15.3122V12.3955C4.0105 11.7915 3.5208 11.3018 2.91675 11.3018C2.31269 11.3018 1.823 11.7915 1.823 12.3955V15.3122C1.823 16.7217 2.9656 17.8643 4.37508 17.8643H7.29175C7.8958 17.8643 8.3855 17.3745 8.3855 16.7705C8.3855 16.1665 7.8958 15.6768 7.29175 15.6768H4.37508Z"
        fill={text}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6252 15.6768C30.8266 15.6768 30.9897 15.5136 30.9897 15.3122V12.3955C30.9897 11.7915 31.4795 11.3018 32.0835 11.3018C32.6875 11.3018 33.1772 11.7915 33.1772 12.3955V15.3122C33.1772 16.7217 32.0346 17.8643 30.6252 17.8643H27.7085C27.1045 17.8643 26.6147 17.3745 26.6147 16.7705C26.6147 16.1665 27.1045 15.6768 27.7085 15.6768H30.6252Z"
        fill={text}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6252 4.01025C30.8266 4.01025 30.9897 4.17349 30.9897 4.37484V7.2915C30.9897 7.89556 31.4795 8.38525 32.0835 8.38525C32.6875 8.38525 33.1772 7.89556 33.1772 7.2915V4.37484C33.1772 2.96536 32.0346 1.82275 30.6252 1.82275H27.7085C27.1045 1.82275 26.6147 2.31245 26.6147 2.9165C26.6147 3.52056 27.1045 4.01025 27.7085 4.01025H30.6252Z"
        fill={text}
      />
    </Svg>

  )
}
export default VisibleFaceIcon;