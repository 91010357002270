import {
    Box,
    Typography,
    Divider,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useStyles } from '@utils/styles'

interface ArticleListingProps {
    article: {
        title: string
        text?: string
        slug: string
    }
}

export const ArticleListing: React.FC<ArticleListingProps> = ({ article }) => {
    const navigation = useNavigation()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Box sx={{ mb: 1 }}>
            <TouchableOpacity
                onPress={() => {
                    navigation.navigate('HelpArticle', {
                        slug: article.slug,
                    })
                }}
            >
                <Typography
                    fontFamily={defaultStyles.titleFont}
                    variant={'body1'}
                    sx={{ mb: 1 }}
                >
                    {article.title}
                </Typography>
            </TouchableOpacity>
            {isMd && (
                <Box
                    sx={{
                        backgroundColor: '#000',
                        width: '100%',
                        height: '1px',
                    }}
                />
            )}
        </Box>
    )
}
