import { Box, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'

const StartTeaching: React.FC = () => {
    const defaultStyles = useStyles()
    const { primary } = useColorsContext()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('md'))
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    return (
        <Grid2
            container
            sx={{ p: { lg: 6, md: 4, xs: 2 } }}
            direction={{ sm: 'row', xs: 'column-reverse' }}
        >
            <Grid2 size={{ sm: 6, xs: 12 }}>
                <Box
                    component="img"
                    sx={{
                        maxWidth: { lg: 400, md: 300, sm: 200, xs: '100%' },
                        mb: 2,
                    }}
                    src={require('@assets/images/become-a-tutor/rocket.png')}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        maxWidth: { lg: 400, md: 300, sm: 200, xs: '100%' },
                    }}
                >
                    <Box
                        sx={{
                            width: { lg: 66, md: 44, sm: 24, xs: 44 },
                            height: { lg: 66, md: 44, sm: 24, xs: 44 },
                            mr: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: { lg: 44, md: 36, sm: 20, xs: 36 },
                                ml: 0.5,
                            }}
                            src={require('@assets/images/become-a-tutor/make-account.png')}
                        />
                    </Box>

                    <Typography
                        fontSize={{
                            lg: '2rem',
                            md: '1.8rem',
                            sm: '1.2rem',
                            xs: '1.8rem',
                        }}
                        fontFamily={defaultStyles.titleFont}
                        color={primary}
                        letterSpacing="-2.52px"
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigation.navigate('Help', {
                                screen: 'HelpArticle',
                                params: {
                                    slug: 'creating-a-tutor-account',
                                },
                            })
                        }}
                    >
                        Make an account
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        maxWidth: { lg: 400, md: 300, sm: 200, xs: '100%' },
                    }}
                >
                    <Box
                        sx={{
                            width: { lg: 66, md: 44, sm: 24, xs: 44 },
                            height: { lg: 66, md: 44, sm: 24, xs: 44 },
                            mr: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: { lg: 44, md: 36, sm: 20, xs: 36 },
                            }}
                            src={require('@assets/images/become-a-tutor/complete-profile.png')}
                        />
                    </Box>
                    <Typography
                        fontSize={{
                            lg: '2rem',
                            md: '1.8rem',
                            sm: '1.2rem',
                            xs: '1.8rem',
                        }}
                        fontFamily={defaultStyles.titleFont}
                        color={primary}
                        letterSpacing="-2.52px"
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigation.navigate('Help', {
                                screen: 'HelpArticle',
                                params: {
                                    slug: 'how-to-edit-profile',
                                },
                            })
                        }}
                    >
                        Complete your profile
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        maxWidth: { lg: 400, md: 300, sm: 200, xs: '100%' },
                    }}
                >
                    <Box
                        sx={{
                            width: { lg: 66, md: 44, sm: 24, xs: 44 },
                            height: { lg: 66, md: 44, sm: 24, xs: 44 },
                            mr: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: { lg: 44, md: 36, sm: 20, xs: 36 },
                            }}
                            src={require('@assets/images/become-a-tutor/get-listed.png')}
                        />
                    </Box>
                    <Typography
                        fontSize={{
                            lg: '2rem',
                            md: '1.8rem',
                            sm: '1.2rem',
                            xs: '1.8rem',
                        }}
                        fontFamily={defaultStyles.boldFont}
                        letterSpacing="-2.52px"
                    >
                        Get listed instantly!
                    </Typography>
                </Box>
            </Grid2>
            <Grid2
                size={{ sm: 6, xs: 12 }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: { xs: 'center', sm: 'flex-end' },
                }}
            >
                <Typography
                    fontSize={{
                        lg: '4.5rem',
                        md: '3.5rem',
                        xs: '2.5rem',
                    }}
                    fontFamily={defaultStyles.titleFont}
                    letterSpacing="-1.8px"
                    lineHeight={1.5}
                >
                    Start teaching in <br />{' '}
                    <span style={{ color: primary }}>
                        two easy{' '}
                        <span
                            style={{
                                color: primary,
                                position: 'relative',
                                marginBottom: 2,
                            }}
                        >
                            steps
                            <img
                                src={require('@assets/images/landingpage/underline.svg')}
                                style={{
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: -22,
                                    right: -10,
                                }}
                                alt="steps"
                            />
                        </span>{' '}
                    </span>
                </Typography>
                {isSm && (
                    <Box>
                        <Box
                            component="img"
                            sx={{
                                maxHeight: {
                                    lg: 300,
                                    md: 244,
                                    sm: 180,
                                    xs: 180,
                                },
                                mt: 3,
                            }}
                            src={require('@assets/images/become-a-tutor/steps.png')}
                        />
                    </Box>
                )}
            </Grid2>
        </Grid2>
    )
}

export default StartTeaching
