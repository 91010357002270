import TutredModal from '@components/TutredModal'
import {
    Autocomplete,
    Box,
    Button,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'
import { StyleSheet } from 'react-native'
import { schoolLogos, schools } from '@config/schools'
import { Controller, useForm } from 'react-hook-form'
import { Degree } from 'dataTypes'
import InputIconLabel from '@components/InputIconLabel'
import Feather from '@expo/vector-icons/Feather'
import DegreeTypeIcon from '@icons/DegreeTypeIcon'
import { useColorsContext } from '@utils/styles'
import CompletionYearIcon from '@icons/CompletionYearIcon'
import DegreeIcon from '@icons/DegreeIcon'
import SchoolIcon from '@icons/SchoolIcon'
import useGetFilterOptions from '@screens/TutorList/TutorListFilters/useGetFilterOptions'
import GroupHeader from '@screens/TutorList/TutorListFilters/GroupHeader'
import GroupItems from '@screens/TutorList/TutorListFilters/GroupItems'
import { degreeData } from '@config/degrees'
import { v4 as uuidv4 } from 'uuid'

interface AddNewDegreeModalProps {
    visible: boolean
    onClose: () => void
    handleAddDegree: (degree: Degree) => void
}

const degrees = [
    'Bachelor of Arts',
    'Bachelor of Business Administration',
    'Bachelor of Commerce',
    'Bachelor of Computer Science',
    'Bachelor of Education',
    'Bachelor of Engineering',
    'Bachelor of Fine Arts',
    'Bachelor of Health Sciences',
    'Bachelor of Journalism',
    'Bachelor of Laws (LLB)',
    'Bachelor of Music',
    'Bachelor of Nursing',
    'Bachelor of Science',
    'Bachelor of Social Work',
    'Bachelor of Technology',
    'Bachelor of Tourism and Hospitality Management',
]
const degreeTypes = [
    'Associates Degree',
    'Bachelors Degree',
    'Masters Degree',
    'Doctorate Degree',
    'Professional Degree',
]

const years = () => {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear; i > 1939; i--) {
        years.push(i)
    }
    years.unshift('In progress')
    return years
}

interface IFormInput {
    school: string
    otherSchool?: string
    degree: string
    year: string
}
const AddNewDegreeModal: React.FC<AddNewDegreeModalProps> = ({
    visible,
    onClose,
    handleAddDegree,
}) => {
    const {
        setFocus,
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm<IFormInput>()

    const filterOptions = useGetFilterOptions()

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const { text } = useColorsContext()

    return (
        <TutredModal
            title="Add New Degree"
            visible={visible}
            onClose={() => {
                reset()
                onClose()
            }}
        >
            <Box sx={styles.container}>
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<SchoolIcon size={22} color={text} />}
                        label="School"
                    />
                    <Controller
                        name="school"
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                disablePortal={true}
                                options={schools}
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <Box
                                            component="li"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                p: 1,
                                            }}
                                            {...props}
                                        >
                                            <img
                                                src={schoolLogos[option]}
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    marginRight: 16,
                                                    objectFit: 'contain',
                                                }}
                                            />
                                            <Typography>{option}</Typography>
                                        </Box>
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        inputRef={ref}
                                        error={!!errors?.school}
                                        placeholder="Choose an institution"
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },

                                                ...params.InputProps,
                                            },
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        )}
                    />
                </Box>

                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<DegreeIcon size={22} color={text} />}
                        label="Degree"
                    />
                    <Controller
                        name="degree"
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                {...field}
                                disablePortal={true}
                                options={filterOptions(
                                    'Degrees',
                                    degreeData,
                                    []
                                )}
                                groupBy={(option) => {
                                    return option?.group
                                }}
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        {params.group && (
                                            <GroupHeader title={params.group} />
                                        )}
                                        <GroupItems
                                            children={params.children}
                                            filter="Degrees"
                                        />
                                    </li>
                                )}
                                isOptionEqualToValue={(option, value) => {
                                    return option.id === value.id
                                }}
                                getOptionLabel={(option) =>
                                    option?.label ? option.label : ''
                                }
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                onChange={(_, value) => {
                                    console.log('value', value)
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        inputRef={ref}
                                        error={!!errors?.degree}
                                        placeholder="Choose a degree"
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },

                                                ...params.InputProps,
                                            },
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        )}
                    />
                </Box>

                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<CompletionYearIcon size={22} color={text} />}
                        label="Year"
                    />
                    <Controller
                        name="year"
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                fullWidth
                                disablePortal={true}
                                options={years()}
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        error={!!errors?.year}
                                        placeholder="Choose a Year"
                                        fullWidth
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },

                                                ...params.InputProps,
                                            },
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        )}
                    />
                </Box>

                <Box style={styles.buttonContainer}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{ flex: 1 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ flex: 1 }}
                        variant="contained"
                        onClick={handleSubmit((data) => {
                            handleAddDegree({
                                id: uuidv4(),
                                school:
                                    data.school !== 'Other'
                                        ? data.school
                                        : data.otherSchool,
                                name: data.degree.id,
                                type: data.degree.group,
                                year: data.year,
                                verificationImg: null,
                                isVerified: false,
                            })
                            onClose()
                            reset()
                        })}
                    >
                        Add Degree
                    </Button>
                </Box>
            </Box>
        </TutredModal>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 4,
    },
    inputContainer: {
        marginBottom: 2,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 16,
    },
})

export default AddNewDegreeModal
