import { useColorsContext } from '@utils/styles';
import Svg, { Path, G } from 'react-native-svg';

const TakePhotoIcon = ({ size = 32 }) => {
    const { text } = useColorsContext();
    return (
        <svg width={size} height={size * (43 / 52)} viewBox="0 0 52 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.9167 36.3333C48.9167 37.4384 48.4777 38.4982 47.6963 39.2796C46.9149 40.061 45.8551 40.5 44.75 40.5H7.25004C6.14497 40.5 5.08516 40.061 4.30376 39.2796C3.52236 38.4982 3.08337 37.4384 3.08337 36.3333V13.4167C3.08337 12.3116 3.52236 11.2518 4.30376 10.4704C5.08516 9.68899 6.14497 9.25 7.25004 9.25H15.5834L19.75 3H32.25L36.4167 9.25H44.75C45.8551 9.25 46.9149 9.68899 47.6963 10.4704C48.4777 11.2518 48.9167 12.3116 48.9167 13.4167V36.3333Z" stroke={text} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.0001 32.1667C30.6025 32.1667 34.3334 28.4357 34.3334 23.8333C34.3334 19.231 30.6025 15.5 26.0001 15.5C21.3977 15.5 17.6667 19.231 17.6667 23.8333C17.6667 28.4357 21.3977 32.1667 26.0001 32.1667Z" stroke={text} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default TakePhotoIcon;
