
const examData = {
    "College Entrance Exams": [
        { label: "SAT", icon: require("@assets/images/exams/ACT.svg") },
        { label: "ACT", icon: require("@assets/images/exams/ACT.svg") },
        { label: "PSAT", icon: require("@assets/images/exams/ACT.svg") }
    ],
    "AP Exams": [
        { label: "AP Calculus", icon: require("@assets/images/subjects/Mathematics.svg") },
        { label: "AP Biology", icon: require("@assets/images/subjects/Biology.svg") },
        { label: "AP Chemistry", icon: require("@assets/images/subjects/Chemistry.svg") },
        { label: "AP Physics", icon: require("@assets/images/subjects/Physics.svg") },
        { label: "AP English Language", icon: require("@assets/images/subjects/English.svg") },
        { label: "AP English Literature", icon: require("@assets/images/subjects/English.svg") },
        { label: "AP United States History", icon: require("@assets/images/subjects/History.svg") },
        { label: "AP World History", icon: require("@assets/images/subjects/History.svg") },
        { label: "AP Computer Science", icon: require("@assets/images/subjects/Computer-Science.svg") },
        { label: "AP Statistics", icon: require("@assets/images/exams/Statistics.svg") },
        { label: "AP Economics", icon: require("@assets/images/subjects/Economics.svg") },
        { label: "AP Psychology", icon: require("@assets/images/subjects/Psychology.svg") },
        { label: "AP Environmental Science", icon: require("@assets/images/exams/Environmental-Science.svg") },
        { label: "AP Human Geography", icon: require("@assets/images/subjects/Geography.svg") },
        { label: "AP Art History", icon: require("@assets/images/subjects/Art.svg") },
        { label: "AP Music Theory", icon: require("@assets/images/subjects/Music.svg") }
    ],
    "IB Exams": [
        { label: "IB Mathematics", icon: require("@assets/images/subjects/Mathematics.svg") },
        { label: "IB Biology", icon: require("@assets/images/subjects/Biology.svg") },
        { label: "IB Chemistry", icon: require("@assets/images/subjects/Chemistry.svg") },
        { label: "IB Physics", icon: require("@assets/images/subjects/Physics.svg") },
        { label: "IB English A Literature", icon: require("@assets/images/subjects/English.svg") },
        { label: "IB History", icon: require("@assets/images/subjects/History.svg") },
        { label: "IB Economics", icon: require("@assets/images/subjects/Economics.svg") },
        { label: "IB Psychology", icon: require("@assets/images/subjects/Psychology.svg") },
        { label: "IB Geography", icon: require("@assets/images/subjects/Geography.svg") },
        { label: "IB Computer Science", icon: require("@assets/images/subjects/Computer-Science.svg") }
    ],
    "Graduate Admission Exams": [
        { label: "MCAT", icon: require("@assets/images/exams/MCAT.svg") },
        { label: "LSAT", icon: require("@assets/images/exams/LSAT.svg") },
        { label: "GRE", icon: require("@assets/images/exams/ACT.svg") },
        { label: "GMAT", icon: require("@assets/images/exams/GMAT.svg") },
        { label: "DAT", icon: require("@assets/images/exams/DAT.svg") },
        { label: "OAT", icon: require("@assets/images/exams/OAT.svg") },
        { label: "UCAT", icon: require("@assets/images/exams/MCAT.svg") },
        { label: "BMAT", icon: require("@assets/images/exams/MCAT.svg") },
        { label: "UKCAT", icon: require("@assets/images/exams/MCAT.svg") },
        { label: "CASPer", icon: require("@assets/images/exams/MCAT.svg") }
    ],
    "Professional Certification Exams": [
        { label: "CFA Exams", icon: require("@assets/images/exams/CFA.svg") },
        { label: "CPA Exam", icon: require("@assets/images/subjects/Accounting.svg") },
        { label: "BAR Exam", icon: require("@assets/images/exams/LSAT.svg") },
        { label: "NCLEX-RN", icon: require("@assets/images/exams/NCLEX-RN.svg") },
        { label: "USMLE", icon: require("@assets/images/exams/MCAT.svg") },
        { label: "PE Exam", icon: require("@assets/images/subjects/Engineering.svg") },
        { label: "PMP Exam", icon: require("@assets/images/exams/GMAT.svg") },
        { label: "NAVLE", icon: require("@assets/images/exams/NAVLE.svg") },
        { label: "PCE", icon: require("@assets/images/exams/PCE.svg") }
    ],
    "English Proficiency Exams": [
        { label: "TOEFL", icon: require("@assets/images/subjects/Language.svg") },
        { label: "IELTS", icon: require("@assets/images/subjects/Language.svg") },
        { label: "PTE", icon: require("@assets/images/subjects/Language.svg") }
    ]
};

const examIcons = {}
Object.keys(examData).map((category) => {
    examData[category].map((exam) => {
        examIcons[exam.label] = exam.icon;
    });
})
const exams = Object.keys(examData).map((category) => {
    return examData[category].map((exam) => {
        return { value: exam.label, label: exam.label, category: category };
    });
});

console.log('examIcons', exams);

module.exports = {
    exams,
    examData,
    examIcons
};
