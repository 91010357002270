import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { useStyles } from '@utils/styles'
import { useColorsContext } from '@utils/styles'

interface OnboardingSectionHeaderProps {
    title: string
    subtitle?: string
    icon: React.ReactNode
}

const OnboardingSectionHeader: React.FC<OnboardingSectionHeaderProps> = ({
    title,
    subtitle,
    icon,
}) => {
    const { text } = useColorsContext()
    const defaultStyles = useStyles()

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    mb: 0.5,
                }}
            >
                <Box sx={{ mb: 0.5 }}>{icon}</Box>

                <Typography
                    variant="h4"
                    fontFamily={defaultStyles.titleFont}
                    textAlign="center"
                    sx={{ ml: 1 }}
                    lineHeight={1}
                >
                    {title}
                </Typography>
            </Box>

            {subtitle && (
                <Typography
                    variant="body2"
                    textAlign="center"
                    fontFamily="Poppins_600SemiBold"
                    sx={{ textWrap: 'balance', maxWidth: '70%' }}
                >
                    {subtitle}
                </Typography>
            )}
            {/* 
            <Box sx={{ width: '100%' }}>
                <Divider
                    variant="middle"
                    color={text}
                    sx={{ marginTop: 2, marginBottom: 1 }}
                />
            </Box> */}
        </Box>
    )
}

export default OnboardingSectionHeader
