
import { useColorsContext } from '@utils/styles';
import React from 'react';

const BackgroundIcon = ({ size = 35 }) => {
    const { text } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.09375 8.75C0.49 8.75 0 8.26 0 7.65625V1.09375C0 0.49 0.49 0 1.09375 0H7.65625C8.26 0 8.75 0.49 8.75 1.09375V7.65625C8.75 8.26 8.26 8.75 7.65625 8.75H1.09375Z" fill={text} />
            <path d="M14.2188 8.75C13.615 8.75 13.125 8.26 13.125 7.65625V1.09375C13.125 0.49 13.615 0 14.2188 0H33.9062C34.51 0 35 0.49 35 1.09375V7.65625C35 8.26 34.51 8.75 33.9062 8.75H14.2188Z" fill={text} />
            <path d="M1.09375 21.875C0.49 21.875 0 21.385 0 20.7812V14.2188C0 13.615 0.49 13.125 1.09375 13.125H7.65625C8.26 13.125 8.75 13.615 8.75 14.2188V20.7812C8.75 21.385 8.26 21.875 7.65625 21.875H1.09375Z" fill={text} />
            <path d="M14.2188 21.875C13.615 21.875 13.125 21.385 13.125 20.7812V14.2188C13.125 13.615 13.615 13.125 14.2188 13.125H33.9062C34.51 13.125 35 13.615 35 14.2188V20.7812C35 21.385 34.51 21.875 33.9062 21.875H14.2188Z" fill={text} />
            <path d="M1.09375 35C0.49 35 0 34.51 0 33.9062V27.3438C0 26.74 0.49 26.25 1.09375 26.25H7.65625C8.26 26.25 8.75 26.74 8.75 27.3438V33.9062C8.75 34.51 8.26 35 7.65625 35H1.09375Z" fill={text} />
            <path d="M14.2188 35C13.615 35 13.125 34.51 13.125 33.9062V27.3438C13.125 26.74 13.615 26.25 14.2188 26.25H33.9062C34.51 26.25 35 26.74 35 27.3438V33.9062C35 34.51 34.51 35 33.9062 35H14.2188Z" fill={text} />
        </svg>
    )
}

export default BackgroundIcon;