import React from 'react'
import {
    Box,
    Typography,
    Divider,
    useTheme,
    useMediaQuery,
    Select,
    MenuItem,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import UnreadMailIcon from '@icons/unreadMailIcon'
import { useNotificationsContext } from '@contexts/notificationsContext'

interface NotificationListHeaderProps {
    showMarkAllAsRead?: boolean
    showRangeSelector?: boolean
    titleColor?: string
    title?: string
}

const NotificationListHeader: React.FC<NotificationListHeaderProps> = ({
    showMarkAllAsRead = true,
    showRangeSelector = false,
    titleColor,
    title = 'Activity',
}) => {
    const { text } = useColorsContext()
    const defaultStyles = useStyles()
    const { markAllAsRead, setAllNotificationsRange, allNotificationsRange } =
        useNotificationsContext()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))

    const color = titleColor || text

    return (
        <Box
            sx={{
                mb: isSm ? 1 : 2,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isSm ? 'center' : 'space-between',
                    my: isSm ? 1 : 2,
                }}
            >
                {title === 'Activity' && !isSm && (
                    <Box sx={{ flex: 1 }}>
                        <Select
                            sx={{ fontFamily: 'Poppins_600SemiBold' }}
                            value={allNotificationsRange}
                            size="small"
                            onChange={(event) => {
                                setAllNotificationsRange(event.target.value)
                            }}
                        >
                            <MenuItem
                                value={30}
                                sx={{ fontFamily: 'Poppins_600SemiBold' }}
                            >
                                Last 30 days
                            </MenuItem>
                            <MenuItem
                                value={90}
                                sx={{ fontFamily: 'Poppins_600SemiBold' }}
                            >
                                Last 90 days
                            </MenuItem>
                            <MenuItem
                                value={180}
                                sx={{ fontFamily: 'Poppins_600SemiBold' }}
                            >
                                Last 180 days
                            </MenuItem>
                            <MenuItem
                                value={365}
                                sx={{ fontFamily: 'Poppins_600SemiBold' }}
                            >
                                Last 365 days
                            </MenuItem>
                            <MenuItem
                                value={9999}
                                sx={{ fontFamily: 'Poppins_600SemiBold' }}
                            >
                                All time
                            </MenuItem>
                        </Select>
                    </Box>
                )}
                {title !== 'Activity' && !isSm && showMarkAllAsRead && (
                    <Box sx={{ flex: 1 }} />
                )}
                <Typography
                    variant={isSm ? 'subtitle1' : 'h6'}
                    textAlign={isSm ? 'left' : 'center'}
                    sx={{ flex: 1 }}
                    color={color}
                >
                    {title}
                </Typography>
                {showMarkAllAsRead && (
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            cursor: 'pointer',
                            flexDirection: 'row',
                        }}
                        onClick={markAllAsRead}
                    >
                        <UnreadMailIcon size={isSm ? 16 : 20} />
                        <Typography
                            onClick={markAllAsRead}
                            sx={{ color: text, ml: 1 }}
                            variant={isSm ? 'caption' : 'body2'}
                            fontFamily={defaultStyles.titleFont}
                        >
                            Mark all as read
                        </Typography>
                    </Box>
                )}
            </Box>

            <Divider sx={{ my: isSm ? 1 : 2 }} flexItem />
        </Box>
    )
}

export default NotificationListHeader
