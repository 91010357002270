import React, { ReactNode, useEffect, useRef, useState } from 'react'
import {
    FlexAlignType,
    Text,
    TouchableOpacity,
    View,
    ViewStyle,
} from 'react-native'
import { useColorsContext, useStyles } from '@utils/styles'
import {
    Box,
    SxProps,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import MinusCircleIcon from '@icons/MinuCircleIcon'
import PlusCircleIcon from '@icons/PlusCircleIcon'
import SubjectIcon from '@icons/SubjectIcon'
import SubjectChip from '@components/Chips/SubjectChip'

interface Props {
    items: string[]
    title?: string
    icon?: ReactNode
    limitVisible?: boolean
    Chip: React.FC<{
        item: string
        size: 'small' | 'medium' | 'large'
        ref: any
    }>
    size?: 'small' | 'medium' | 'large'
    align?: FlexAlignType
    containerStyle?: ViewStyle
    label?: ReactNode
}

const TutredChipDisplay: React.FC<Props> = ({
    items,
    icon,
    limitVisible = false,
    Chip,
    size,
    align = 'flex-start',
    containerStyle = { marginTop: 8 },
    label,
}) => {
    const theme = useTheme()
    const { text } = useColorsContext()
    const defaultStyles = useStyles()

    const [showAll, setShowAll] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const [wrappedCount, setWrappedCount] = useState(0)
    const containerRef = useRef<HTMLDivElement>(null)
    const chipRefs = useRef<(HTMLDivElement | null)[]>([])
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    useEffect(() => {
        if (limitVisible) {
            const checkOverflowAndCount = () => {
                if (containerRef.current) {
                    const containerWidth = containerRef.current.offsetWidth
                    let currentLineWidth = isMd ? 26 : 22
                    let visibleCount = 0
                    let wrappedItems = 0
                    const itemsLength = items.length

                    chipRefs.current.forEach((chipRef, index) => {
                        if (chipRef) {
                            const gap = isMd ? 8 : 4
                            const chipWidth = chipRef.offsetWidth + gap

                            if (currentLineWidth + chipWidth > containerWidth) {
                                currentLineWidth += chipWidth
                                wrappedItems = itemsLength - visibleCount
                                return
                            }
                            currentLineWidth += chipWidth
                            visibleCount++
                        }
                    })

                    setIsOverflowing(wrappedItems > 0)
                    setWrappedCount(wrappedItems)
                }
            }

            checkOverflowAndCount()
            window.addEventListener('resize', checkOverflowAndCount)

            return () => {
                window.removeEventListener('resize', checkOverflowAndCount)
            }
        }
    }, [items, isMd, limitVisible])

    if (!items) return null

    return (
        <>
            <View
                style={{
                    display: 'flex',
                    flexDirection: isMd ? 'row' : 'column',
                    alignItems: align,
                    ...containerStyle,
                }}
            >
                <Box
                    ref={containerRef}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        rowGap: isMd ? 1 : 0.5,
                        columnGap: isMd ? 1 : 0.5,
                        maxHeight:
                            !showAll && limitVisible
                                ? isMd
                                    ? size === 'large'
                                        ? 36
                                        : 30
                                    : 24
                                : null,
                        overflow: showAll ? 'visible' : 'hidden',
                        flex: 1,
                    }}
                >
                    {label}
                    {icon && (
                        <View
                            style={{
                                width: isMd ? 26 : 22,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            {icon}
                        </View>
                    )}

                    {items.sort().map((item, index) => {
                        return (
                            <Chip
                                key={item}
                                item={item}
                                ref={(el) => (chipRefs.current[index] = el)}
                                size={isMd ? size || 'medium' : 'small'}
                            />
                        )
                    })}
                </Box>
                {isOverflowing && limitVisible && (
                    <TouchableOpacity
                        style={{
                            marginTop: 4,
                            height: 20,
                            paddingHorizontal: 8,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#006EFF1A',
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: '#E0DDDD',
                        }}
                    >
                        <Typography
                            variant="caption"
                            fontFamily={defaultStyles.titleFont}
                            sx={{
                                color: theme.palette.primary.main,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => setShowAll(!showAll)}
                        >
                            <Box
                                sx={{
                                    mr: 0.5,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {showAll ? (
                                    <MinusCircleIcon />
                                ) : (
                                    <PlusCircleIcon />
                                )}
                            </Box>
                            <span style={{ fontFamily: 'Poppins_500Medium' }}>
                                {!showAll
                                    ? `${String(wrappedCount)} More`
                                    : 'See Less'}
                            </span>
                        </Typography>
                    </TouchableOpacity>
                )}
            </View>
        </>
    )
}

export default TutredChipDisplay
