import React from 'react'
import { Chip, ChipProps, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useColorsContext } from '@utils/styles'
import AntDesign from '@expo/vector-icons/AntDesign'

interface TutredBaseChipProps extends Omit<ChipProps, 'icon'> {
    backgroundColor?: string
    size?: 'small' | 'medium'
}

const TutredBaseChip = React.forwardRef<HTMLDivElement, TutredBaseChipProps>(
    (
        {
            label,
            variant = 'filled',
            backgroundColor,
            size = 'medium',
            sx,
            ...rest
        },
        ref
    ) => {
        const theme = useTheme()
        const { darkMode } = useColorsContext()

        return (
            <Chip
                ref={ref}
                variant={variant}
                label={label}
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: size === 'small' ? 1.75 : 2.5,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 16,
                    borderWidth: variant === 'outlined' ? 2 : 0,
                    borderColor:
                        variant === 'outlined'
                            ? darkMode
                                ? theme.palette.grey[400]
                                : theme.palette.grey[900]
                            : 'none',
                    backgroundColor:
                        variant == 'outlined'
                            ? theme.palette.background
                            : backgroundColor || theme.palette.primary.main,
                    color:
                        variant == 'outlined' && !darkMode
                            ? theme.palette.grey[900]
                            : '#fff',
                    ...sx,
                }}
                deleteIcon={
                    <IconButton>
                        <AntDesign
                            name="close"
                            size={18}
                            color={
                                variant !== 'filled' && !darkMode
                                    ? theme.palette.grey[800]
                                    : theme.palette.grey[100]
                            }
                        />
                    </IconButton>
                }
                {...rest}
            />
        )
    }
)

export default TutredBaseChip
