import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useColorsContext } from '@utils/styles';
const RecentPhotoIcon = ({ size = 28 }) => {
    const { text } = useColorsContext()
    return (
        <Svg width={size} height={size} viewBox="0 0 28 28" fill="none">
            <Path
                d="M8.90002 0.400391C7.02232 0.400391 5.50014 1.92252 5.50002 3.80019H3.80002C1.92226 3.80019 0.400024 5.32242 0.400024 7.20019V20.8002C0.400024 22.678 1.92226 24.2002 3.80002 24.2002H5.50002C5.50002 26.078 7.02226 27.6004 8.90002 27.6004H19.1C20.9778 27.6004 22.5 26.0782 22.5 24.2004L24.2 24.2002C26.0778 24.2002 27.6 22.678 27.6 20.8002V7.20019C27.6 5.32242 26.0778 3.80019 24.2 3.80019H22.5C22.4999 1.92252 20.9777 0.400391 19.1 0.400391H8.90002ZM22.5 5.50019H24.2C25.1389 5.50019 25.9 6.26131 25.9 7.20019V20.8002C25.9 21.7391 25.1389 22.5002 24.2 22.5002H22.5V5.50019ZM5.50002 22.5002H3.80002C2.86115 22.5002 2.10002 21.7391 2.10002 20.8002V7.20019C2.10002 6.26131 2.86115 5.50019 3.80002 5.50019H5.50002V22.5002ZM7.20002 3.80039C7.20002 2.86152 7.96115 2.10039 8.90002 2.10039H19.1C20.0389 2.10039 20.8 2.86152 20.8 3.80039V24.2004C20.8 25.1393 20.0389 25.9004 19.1 25.9004H8.90002C7.96115 25.9004 7.20002 25.1393 7.20002 24.2004V3.80039Z"
                fill={text}
            />
        </Svg>
    )

}




export default RecentPhotoIcon;
