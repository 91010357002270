import React from 'react'
import {
    ChipProps,
    useTheme,
    useMediaQuery,
    Box,
    Typography,
} from '@mui/material'

import { useColorsContext, useStyles } from '@utils/styles'
import { examIcons } from '@config/exams'
import TutredBaseChip from '../TutredBaseChip'
interface ExamChipProps extends ChipProps {
    item: string
    size?: 'small' | 'medium' | 'large'
}

const ExamChip: React.FC<ExamChipProps> = ({
    item,
    onDelete,
    size = 'medium',
    ...rest
}) => {
    const theme = useTheme()
    const { darkMode } = useColorsContext()
    const defaultStyles = useStyles()

    return (
        <TutredBaseChip
            size={size}
            sx={{
                paddingHorizontal:
                    size === 'large' ? 2 : size === 'medium' ? 1.5 : 1,
                paddingVertical: 0,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#BF00DA66',
                borderRadius:
                    size === 'large' ? 2 : size === 'medium' ? 1.5 : 1,
                height: size === 'large' ? 36 : size === 'medium' ? 30 : 24,
            }}
            icon={
                <Box
                    sx={{
                        width:
                            size === 'large' ? 22 : size === 'medium' ? 18 : 14,
                        height:
                            size === 'large' ? 22 : size === 'medium' ? 18 : 14,
                        backgroundColor: '#FFF',
                        WebkitMask: `url(${examIcons[item]}) no-repeat center `,
                        mask: `url(${examIcons[item]}) no-repeat center `,
                        maskSize: 'contain',
                        WebkitMaskSize: 'contain',
                    }}
                />
            }
            label={
                <Typography
                    sx={{
                        fontSize:
                            size === 'large' ? 16 : size === 'medium' ? 14 : 10,
                    }}
                    color="#BF00DAE5"
                    fontFamily={defaultStyles.titleFont}
                >
                    {item}
                </Typography>
            }
            onDelete={onDelete}
            {...rest}
        />
    )
}

export default ExamChip
