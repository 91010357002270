import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext } from '@utils/styles'
import React, { useEffect, useMemo, useState } from 'react'
import TutorSidebar from './TutorSidebar'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { useUserContext } from '@contexts/userContext'
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    getDocs,
    limit,
    onSnapshot,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import BookSessionModal from './BookSessionModal'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'
import { decode } from 'html-entities'
import { InstaBookConfirmModal } from '@components/InstaBookConfirmModal'
import TutorMain from './TutorMain'
import Sections from './Sections'
import formatName from '@helpers/formatName'

const TutorProfile = ({ navigation, route }) => {
    const { background } = useColorsContext()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const { db } = useFirebaseContext()

    const { isTutor, user, profile, paymentMethods, extendedUser } =
        useUserContext()

    const urlId =
        route?.name === 'Profile' ? extendedUser?.URL : route?.params?.id

    const [tutor, setTutor] = useState(null)
    const [reviews, setReviews] = useState([])
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false)
    const [showInstaBookConfirmModal, setShowInstaBookConfirmModal] =
        useState<boolean>(false)
    const [selectedPricingIndex, setSelectedPricingIndex] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        // Get tutor data
        const q = query(
            collection(db, 'users'),
            where('URL', '==', parseInt(urlId)),
            where('isTutor', '==', true),

            where('isOnboarded', '==', true),
            limit(1)
        )
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setTutor(doc.data())
                navigation.setOptions({
                    title: `${formatName(doc.data()?.name)} | Tutor Profile`,
                })
            })
        })
        return () => {
            unsubscribe()
            setTutor(null)
        }
    }, [urlId])

    useEffect(() => {
        if (tutor?.uid) {
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'reviews'),
                    where('tutor', '==', tutor?.uid)
                ),
                (querySnapshot) => {
                    let reviews = []
                    querySnapshot.forEach((doc) => {
                        let review = doc.data()
                        reviews.push(review)
                    })
                    setReviews(reviews)
                }
            )

            return () => {
                unsubscribe()
            }
        }
    }, [tutor])

    const rating = useMemo(() => {
        if (reviews.length === 0) {
            return 0
        }
        let total = 0
        reviews.forEach((review) => {
            total += review.rating
        })
        return (total / reviews.length).toFixed(1)
    }, [reviews])

    const pricingOptions = useMemo<MultiSwitchToggleOption[]>(() => {
        if (tutor?.sessionPricing) {
            return Object.keys(tutor?.sessionPricing).map((key) => {
                const pricing = tutor?.sessionPricing[key]
                return {
                    label: `${key} mins`,
                    contextLabel: ` ${decode('\u00b7')} CA$${pricing.rate || 0}`,
                    value: pricing,
                    error: undefined,
                }
            })
        } else {
            return []
        }
    }, [tutor?.sessionPricing?.[30]?.rate, tutor?.sessionPricing?.[60]?.rate])

    if (!tutor) {
        return null
    }

    return (
        <Box
            sx={{
                flex: 1,
                justifyContent: 'center',
                backgroundColor: background,
                overflowY: 'hidden',
            }}
        >
            <BookSessionModal
                visible={showBookingDialog}
                setVisible={setShowBookingDialog}
                tutor={tutor}
                pricingOptions={pricingOptions}
                selectedPricingIndex={selectedPricingIndex}
                setSelectedPricingIndex={setSelectedPricingIndex}
            />
            <InstaBookConfirmModal
                tutor={tutor}
                showModal={showInstaBookConfirmModal}
                setShowModal={setShowInstaBookConfirmModal}
                setButtonLoading={setLoading}
            />
            <Box
                sx={{
                    padding: isMd ? 4 : 0,
                    maxWidth: 1280,
                    margin: 'auto',
                    minHeight: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    gap: 4,
                    paddingBottom: !isMd ? 8 : 4,
                }}
            >
                {isMd && (
                    <TutorSidebar
                        tutor={tutor}
                        rating={rating}
                        reviewCount={reviews?.length || 0}
                        setShowBookingDialog={setShowBookingDialog}
                        setShowInstaBookConfirmModal={
                            setShowInstaBookConfirmModal
                        }
                        pricingOptions={pricingOptions}
                    />
                )}

                <TutorMain
                    tutor={tutor}
                    rating={rating}
                    reviewCount={reviews?.length || 0}
                    setShowBookingDialog={setShowBookingDialog}
                />
            </Box>
        </Box>
    )
}

export default TutorProfile
