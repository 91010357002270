import { useColorsContext } from '@utils/styles';
import React from 'react';

const SessionRateIcon = ({ size = 48 }) => {
    const { text } = useColorsContext()
    return (

        <svg width={size} height={size * (20 / 30)} viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.625V19.375H30V0.625H0ZM27.5 16.875H9.375V13.75H6.875V16.875H2.5V3.125H6.875V6.25H9.375V3.125H27.5V16.875Z" fill={text} />
            <path d="M9.375 8.125H6.875V11.875H9.375V8.125Z" fill={text} />
            <path d="M19.3125 9.19439C18.5481 8.84563 18.0794 8.60564 17.9075 8.47376C17.735 8.34251 17.65 8.19251 17.65 8.02626C17.65 7.87126 17.7169 7.74126 17.8531 7.63688C17.9888 7.53251 18.205 7.48001 18.5044 7.48001C19.0819 7.48001 19.7675 7.66251 20.5619 8.02626L21.2656 6.25313C20.5556 5.93751 19.8569 5.74751 19.1688 5.67689V4.38501H17.3131V5.79251C16.8919 5.90188 16.5269 6.06751 16.2256 6.30001C15.6556 6.73751 15.3706 7.34813 15.3706 8.13126C15.3706 8.55001 15.4369 8.91251 15.57 9.21814C15.7044 9.52439 15.9094 9.79564 16.1863 10.0319C16.4631 10.2688 16.88 10.5125 17.4344 10.7644C18.0463 11.0394 18.4225 11.2213 18.5644 11.3106C18.7056 11.4006 18.8088 11.4881 18.8725 11.575C18.9363 11.6625 18.9688 11.7644 18.9688 11.8806C18.9688 12.0663 18.8894 12.2181 18.7306 12.3363C18.5713 12.4544 18.3219 12.5138 17.9806 12.5138C17.585 12.5138 17.1513 12.45 16.6788 12.3244C16.2056 12.1988 15.7506 12.0231 15.3119 11.7981V13.8444C15.7275 14.0419 16.1263 14.1806 16.51 14.26C16.7413 14.3075 17.0163 14.3363 17.3131 14.355V15.6144H19.1688V14.225C19.3569 14.1744 19.5394 14.1163 19.7075 14.04C20.2113 13.8138 20.5969 13.495 20.8644 13.0869C21.1325 12.6781 21.2663 12.21 21.2663 11.6831C21.2663 11.1094 21.1231 10.6331 20.8375 10.2556C20.5525 9.87751 20.0444 9.52376 19.3125 9.19439Z" fill={text} />
        </svg>

    )
}

export default SessionRateIcon
