import { useColorsContext } from '@utils/styles';
import React from 'react';
import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg';

const PhotoGuidelinesIcon = ({ size = 32 }) => {

    const { text } = useColorsContext();
    return (
        <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
            <G clipPath="url(#clip0_253_5967)">
                <Path d="M28 12H4C3.46975 12.0006 2.9614 12.2115 2.58646 12.5865C2.21152 12.9614 2.00061 13.4698 2 14V28C2.00053 28.5303 2.21141 29.0387 2.58637 29.4136C2.96133 29.7886 3.46973 29.9995 4 30H28C28.5302 29.9994 29.0386 29.7885 29.4135 29.4135C29.7885 29.0386 29.9994 28.5302 30 28V14C29.9993 13.4698 29.7883 12.9615 29.4134 12.5866C29.0385 12.2117 28.5302 12.0007 28 12ZM20 28H12V27C12.0002 26.7349 12.1057 26.4806 12.2932 26.2932C12.4806 26.1057 12.7349 26.0002 13 26H19C19.2651 26.0002 19.5194 26.1057 19.7068 26.2932C19.8943 26.4806 19.9998 26.7349 20 27V28ZM28 28H22V27C21.9991 26.2046 21.6828 25.4421 21.1204 24.8796C20.5579 24.3172 19.7954 24.0009 19 24H13C12.2046 24.0009 11.4421 24.3172 10.8796 24.8796C10.3172 25.4421 10.0009 26.2046 10 27V28H4V14H28.002L28 28Z" fill={text} />
                <Path d="M16 23C15.2089 23 14.4355 22.7654 13.7777 22.3259C13.1199 21.8864 12.6072 21.2616 12.3045 20.5307C12.0017 19.7998 11.9225 18.9956 12.0769 18.2196C12.2312 17.4437 12.6122 16.731 13.1716 16.1716C13.731 15.6122 14.4437 15.2312 15.2196 15.0769C15.9956 14.9225 16.7998 15.0017 17.5307 15.3045C18.2616 15.6072 18.8864 16.1199 19.3259 16.7777C19.7654 17.4355 20 18.2089 20 19C19.9989 20.0605 19.5771 21.0773 18.8272 21.8272C18.0773 22.5771 17.0605 22.9989 16 23ZM16 17C15.6044 17 15.2178 17.1173 14.8889 17.3371C14.56 17.5568 14.3036 17.8692 14.1522 18.2346C14.0009 18.6001 13.9613 19.0022 14.0384 19.3902C14.1156 19.7781 14.3061 20.1345 14.5858 20.4142C14.8655 20.6939 15.2219 20.8844 15.6098 20.9616C15.9978 21.0387 16.3999 20.9991 16.7654 20.8478C17.1308 20.6964 17.4432 20.44 17.6629 20.1111C17.8827 19.7822 18 19.3956 18 19C17.9994 18.4698 17.7885 17.9614 17.4135 17.5865C17.0386 17.2115 16.5302 17.0006 16 17Z" fill={text} />
                <Path d="M30 4V8C29.9993 8.53021 29.7883 9.03851 29.4134 9.41342C29.0385 9.78834 28.5302 9.99929 28 10H24C23.4698 9.99929 22.9615 9.78834 22.5866 9.41342C22.2117 9.03851 22.0007 8.53021 22 8V4C22.0007 3.46979 22.2117 2.96149 22.5866 2.58658C22.9615 2.21166 23.4698 2.00071 24 2H28C28.5302 2.00071 29.0385 2.21166 29.4134 2.58658C29.7883 2.96149 29.9993 3.46979 30 4ZM28 8L28.002 4H24V8H28Z" fill={text} />
                <Path d="M20 4V8C19.9993 8.53021 19.7883 9.03851 19.4134 9.41342C19.0385 9.78834 18.5302 9.99929 18 10H14C13.4698 9.99939 12.9614 9.78848 12.5865 9.41354C12.2115 9.0386 12.0006 8.53025 12 8V4C12.0006 3.46975 12.2115 2.9614 12.5865 2.58646C12.9614 2.21152 13.4698 2.00061 14 2H18C18.5302 2.00071 19.0385 2.21166 19.4134 2.58658C19.7883 2.96149 19.9993 3.46979 20 4ZM18 8L18.002 4H14V8H18Z" fill={text} />
                <Path d="M10 4V8C9.99939 8.53025 9.78848 9.0386 9.41354 9.41354C9.0386 9.78848 8.53025 9.99939 8 10H4C3.46975 9.99939 2.9614 9.78848 2.58646 9.41354C2.21152 9.0386 2.00061 8.53025 2 8V4C2.00061 3.46975 2.21152 2.9614 2.58646 2.58646C2.9614 2.21152 3.46975 2.00061 4 2H8C8.53025 2.00061 9.0386 2.21152 9.41354 2.58646C9.78848 2.9614 9.99939 3.46975 10 4ZM8 8L8.0015 4H4V8H8Z" fill={text} />
            </G>
            <Defs>
                <ClipPath id="clip0_253_5967">
                    <Rect width="32" height="32" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>
    )

}

export default PhotoGuidelinesIcon;
