import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Link, useRoute } from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'

const LegalMatterHeader: React.FC = () => {
    const { primary } = useColorsContext()
    const defaultStyles = useStyles()
    const route = useRoute()
    const theme = useTheme()

    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    console.log('isMd', isMd)

    const isActive = (screen: string) => route?.name === screen

    return (
        <Box sx={{ backgroundColor: primary, p: 3 }}>
            <Box
                sx={{
                    maxWidth: 1280,
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2,
                    }}
                >
                    <img
                        src={require('@assets/legal-matters.svg')}
                        style={{
                            height: isMd ? '60px' : '40px',
                            width: 'auto',
                            marginRight: 12,
                        }}
                    />
                    <Typography
                        variant={isMd ? 'h2' : 'h4'}
                        color="#FFF"
                        fontFamily={defaultStyles.boldFont}
                    >
                        Legal Matters
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMd ? 'row' : 'column',
                        justifyContent: 'space-evenly',
                        rowGap: 1,
                        width: { xs: '100%', md: '80%' },
                        mb: 1,
                    }}
                >
                    <Link to="/terms-of-service">
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontFamily={defaultStyles.titleFont}
                            color={isActive('TermsOfService') ? '#000' : '#FFF'}
                        >
                            Terms of service
                        </Typography>
                    </Link>
                    <Link to="/privacy-policy">
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontFamily={defaultStyles.titleFont}
                            color={isActive('PrivacyPolicy') ? '#000' : '#FFF'}
                        >
                            Privacy policy
                        </Typography>
                    </Link>

                    <Link to="/cancellation-policy">
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontFamily={defaultStyles.titleFont}
                            color={
                                isActive('cancellationpolicy') ? '#000' : '#FFF'
                            }
                        >
                            Cancellation policy
                        </Typography>
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMd ? 'row' : 'column',
                        justifyContent: 'space-evenly',
                        rowGap: 1,
                        width: { xs: '100%', md: '80%' },
                    }}
                >
                    <Link to="/community-guidelines">
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontFamily={defaultStyles.titleFont}
                            color={
                                isActive('communityguidelines')
                                    ? '#000'
                                    : '#FFF'
                            }
                        >
                            Community guidelines
                        </Typography>
                    </Link>

                    <Link to="/nondiscrimination-policy">
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontFamily={defaultStyles.titleFont}
                            color={
                                isActive('nondiscriminationpolicy')
                                    ? '#000'
                                    : '#FFF'
                            }
                        >
                            Nondiscrimination policy
                        </Typography>
                    </Link>

                    <Link to="/additional-policies">
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontFamily={defaultStyles.titleFont}
                            color={
                                isActive('additionalpolicies') ? '#000' : '#FFF'
                            }
                        >
                            Additional policies
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}

export default LegalMatterHeader
