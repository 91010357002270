import { Box, Typography, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { OnboardingStep } from '../..'
import FontAwesome6 from '@expo/vector-icons/FontAwesome6'
import { useColorsContext, useStyles } from '@utils/styles'
import { opacity } from 'react-native-reanimated/lib/typescript/Colors'
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated'
import CheckIcon from '@icons/CheckIcon'

interface OnboardingProgressItemProps {
    title: string
    subtitle: string
    selectedStep: number
    icon: React.ReactNode
    step: OnboardingStep
    index: number
}

const OnboardingProgressItem: React.FC<OnboardingProgressItemProps> = ({
    title,
    subtitle,
    selectedStep,
    step,
    icon,
    index,
}) => {
    const { text, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const completed = selectedStep > index
    const AnimatedBox = Animated.createAnimatedComponent(Box)
    const animatedOpacity = useSharedValue(1)
    const animatedColor = useSharedValue(primaryLight)

    useEffect(() => {
        if (completed) {
            animatedOpacity.value = withTiming(0.1, { duration: 600 })
            animatedColor
        } else {
            animatedOpacity.value = withTiming(1, { duration: 600 })
        }
    }, [completed])

    const animatedStyle = useAnimatedStyle(() => {
        return {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: 8,
            position: 'relative',
            opacity: animatedOpacity.value,
            flex: 1,
            width: '100%',
        }
    })

    return (
        <AnimatedBox style={animatedStyle}>
            <Box>
                <Box
                    sx={{
                        border: '2px solid ' + text,

                        borderRadius: '4px',
                        width: 28,
                        height: 28,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {icon}
                </Box>
                {index !== 6 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: -16,
                            left: 21,
                            height: 32,
                            width: 2,
                            opacity: !completed ? 1 : 0.3,
                            backgroundColor: text,
                        }}
                    ></Box>
                )}
            </Box>

            <Box sx={{ marginLeft: 2, flex: 1, marginRight: 6 }}>
                <Typography
                    variant="body2"
                    fontFamily={defaultStyles.titleFont}
                    color={text}
                >
                    {title}
                </Typography>
                <Typography variant="caption" color={text}>
                    {subtitle}
                </Typography>
            </Box>

            {completed && (
                <CheckIcon color={!completed ? primaryLight : text} />
            )}
        </AnimatedBox>
    )
}

export default OnboardingProgressItem
