// Imports
import { A } from '@expo/html-elements'
import {
    AntDesign,
    Feather,
    FontAwesome,
    FontAwesome5,
} from '@expo/vector-icons'
import * as Clipboard from 'expo-clipboard'
import * as Sharing from 'expo-sharing'
import {
    collection,
    getDocs,
    limit,
    onSnapshot,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import React, { useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useMediaQuery } from 'react-responsive'
import { Header as AuthHeader } from '../../../components/AuthHeader'
import { Header } from '../../../components/Header'
import { useColorsContext, useStyles } from '../../../utils/styles'

import FadeIn from 'react-native-fade-in-image'
import Footer from '../../../components/Footer'

import { LoadingPage } from '../../../components/LoadingPage'
import { ReportModal } from '../../../components/ReportModal'
import { useFirebaseContext } from '../../../contexts/firebaseContext'
import { useUserContext } from '../../../contexts/userContext'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'

const dev = process.env.mode === 'development'
const shareLink = dev
    ? 'https://tutred-staging.vercel.app'
    : 'https://tutred.com'

import useUpdateProfilePicture from './useUpdateProfilePicture'
import Education from './Education'
import EditPersonalDetails from '@screens/EditProfile/Sections/EditPersonalDetails'
import { useForm } from 'react-hook-form'
import EditTutorProfile from '@screens/EditProfile/Sections/EditTutorProfile'
import { Box, Container, Tab, Tabs } from '@mui/material'
import UploadPhotoDialog from '@components/UploadPhotoDialog'

export interface EditProfileForm {
    email: string
    name: string
    location: {
        city: string
        province: string
        country: string
    }
    languages: string[]
    subjects: string[]
    skills: string[]
    headline: string
    description: string
    examTutor: string[]
}

export const EditProfileNew = ({ navigation, route }) => {
    const { db } = useFirebaseContext()
    const { isTutor, user, name, extendedUser } = useUserContext() // Get user from authentication, if no user then user = null and take user to login page
    const [loaded, setLoaded] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const urlId = extendedUser?.URL

    // Alert
    const customAlert = useAlert()

    // Responsive Breakpoints
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })

    // Appearance
    const {
        primary,
        primaryVariant,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        yellow,
        green,
        red,
    } = useColorsContext()
    const defaultStyles = useStyles()
    const updateProfilePicture = useUpdateProfilePicture()
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<EditProfileForm>()

    const personalFormErrors = useMemo(() => {
        return (
            errors.email ||
            errors.name ||
            errors.location?.city ||
            errors.location?.province ||
            errors.location?.country
        )
    }, [errors])

    const tutorFormErrors = useMemo(() => {
        return (
            errors.headline ||
            errors.description ||
            errors.languages ||
            errors.subjects ||
            errors.skills ||
            errors.examTutor
        )
    }, [errors])

    // States and variables

    const [tutorId, setTutorId] = useState(null)
    const [totalSessions, setTotalSessions] = useState(0)
    const [sessions, setSessions] = useState([])
    const [reviews, setReviews] = useState([])
    const [rating, setRating] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)
    const [shareAvailable, setShareAvailable] = useState(false)
    const [hoursSinceOnline, setHoursSinceOnline] = useState(null)
    const [tutor, setTutor] = useState(null)
    const [bookable, setBookable] = useState(false)
    const [view, setView] = useState('personal')
    const [showUploadPhotoDialog, setShowUploadPhotoDialog] = useState(false)

    // Profile values
    const [cities, setCities] = useState([
        { city: 'Select city', enabled: false },
    ])
    const [provinces, setProvinces] = useState([
        { province: 'Select province', enabled: false },
    ])
    const [tutorName, setTutorName] = useState(name)
    const [city, setCity] = useState('')
    const [selectedProvince, setSelectedProvince] = useState('')
    const [selectedCountry, setSelectedCountry] = useState('')

    const [selectedSubject, setSelectedSubject] = useState([])
    const [selectedSkill, setSelectedSkill] = useState([])
    const [selectedLanguage, setSelectedLanguage] = useState([])
    const [headline, setHeadline] = useState('')
    const [description, setDescription] = useState('')
    // Reset degree values

    // Education values
    const [degreeArray, setDegreeArray] = useState([])
    const [selectedUniversity, setSelectedUniversity] = useState('')
    const [selectedDegree, setSelectedDegree] = useState('')
    const [selectedCompletionYear, setSelectedCompletionYear] = useState('')
    const [selectedDegreeType, setSelectedDegreeType] = useState('')
    const [customUniversity, setCustomUniversity] = useState('')

    // Degree info

    const [selectedExam, setSelectedExams] = useState([])

    const [selectedEducationLevels, setSelectedEducationLevels] = useState([])

    // Stylesheet
    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        menuText: {
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
        },
        menuTextSmall: {
            color: text,
            fontSize: 14,
            fontFamily: defaultStyles.regularFont,
            marginBottom: 15,
        },
        maxWidth: {
            flex: 1,
            backgroundColor: background,
            // display: 'flex',
            // flexDirection: Platform.OS === 'web' ? 'row' : null,
            // flexDirection: 'row',
            paddingRight: 0, // Padding right is for the cover photo to stretch
            width: '100%',
            // maxWidth: Platform.OS === "web" ? 1350 : null,
            margin: Platform.OS === 'web' ? 'auto' : null,
        },

        mainSection: {
            flex: 1,
            position: 'relative',
        },
        subtitle: {
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },
        profileImg: {
            marginBottom: 5,
            borderRadius: 100,
            width: 130,
            height: 130,
            opacity: 1,
            marginRight: 20,
        },
        title: {
            fontSize: 32,
            fontFamily: defaultStyles.titleFont,
            color: text,
        },
        online: {
            marginLeft: 15,
            fontSize: 14,
            color: '#00AA54',
            fontFamily: defaultStyles.boldFont,
        },
        banner: {
            shadowColor: '#171717',
            shadowOffset: { width: -2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 50,
            backgroundColor: backgroundVariant,
        },
        verified: {
            fontSize: 14,
            color: green,
            fontFamily: defaultStyles.boldFont,
        },
        description: {
            marginTop: 5,
            fontSize: 16,
            color: text,
            fontFamily: defaultStyles.regularFont,
            maxWidth: 925,
            // paddingHorizontal: 15,
        },
        circleButton: {
            borderRadius: defaultStyles.buttonRadius,
            height: 45,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            border: '1px solid ' + primary,
            backgroundColor: 'transparent',
            width: 245,
        },
        button: {
            borderRadius: defaultStyles.buttonRadius,
            height: 45,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: primary,
            width: 245,
        },
        disabledButton: {
            opacity: 0.5,
        },
        buttonText: {
            color: '#fff',
            fontSize: 19,
            fontFamily: defaultStyles.titleFont,
        },
        tutorInfo: {
            fontSize: 16,
            color: textVariant,
            fontFamily: defaultStyles.regularFont,
        },
        tutorDescriptionTitles: {
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.titleFont,
        },
        showMore: {
            color: textVariant,
            fontFamily: defaultStyles.boldFont,
            fontSize: 16,
        },
        actionText: {
            color: textVariant,
            fontFamily: defaultStyles.regularFont,
            fontSize: 14,
            marginLeft: 5,
            textDecorationColor: textVariant,
            textDecorationLine: 'underline',
        },
        input: {
            backgroundColor: backgroundVariant,
            padding: 15,
            fontSize: 16,
            marginTop: 5,
            borderRadius: defaultStyles.radius,
            border: '1px solid ' + border,
            fontFamily: defaultStyles.regularFont,
            color: text,
        },
        pickerInfoText: {
            fontSize: 14,
            color: textVariant,
            fontFamily: defaultStyles.regularFont,
            marginBottom: 1,
        },
        picker: {
            marginTop: 15,
            borderRadius: defaultStyles.radius,
            paddingVertical: 15,
            paddingLeft: 15,
            paddingRight: 15,
            color: text,
            backgroundColor: backgroundVariant,
            border: '1px solid ' + border,
            fontSize: 16,
        },
        descriptionInput: {
            backgroundColor: backgroundVariant,
            padding: 15,
            fontSize: 16,
            marginTop: 15,
            borderRadius: defaultStyles.radius,
            border: '1px solid ' + border,
            fontFamily: defaultStyles.regularFont,
            color: text,
            height: 150,
        },
        wordContainer: {
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            flexWrap: 'wrap',
            marginBottom: -10,
        },
        addDegreeButton: {
            color: primary,
            fontSize: 14,
            fontFamily: defaultStyles.boldFont,
            marginTop: 10,
        },
    })

    // Get reviews & tutor
    useEffect(() => {
        // Set sharing
        Sharing.isAvailableAsync().then((result) => {
            setShareAvailable(result)
        })
        // Get tutor data
        const q = query(
            collection(db, 'users'),
            where('URL', '==', parseInt(urlId)),
            where('isTutor', '==', true),
            where('isOnboarded', '==', true),
            limit(1)
        )
        onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let tutor = doc.data()

                // Set profile values
                setCity(tutor.location.city)
                setSelectedProvince(tutor.location.province)
                setSelectedCountry(tutor.location.country)

                // Set tutor values
                setSelectedSubject(
                    tutor.subjects ? Object.keys(tutor.subjects) : []
                )
                setSelectedSkill(tutor.skills)
                setSelectedLanguage(
                    tutor.languages ? Object.keys(tutor.languages) : []
                )
                setHeadline(tutor.headline || '')
                setDescription(tutor.description)

                // Education values
                setDegreeArray(doc.data().degrees)
                setSelectedEducationLevels(doc.data().educationLevels || [])
                setSelectedExams(doc.data().examTutor || [])

                let today = new Date()
                var hoursSinceOnline
                if (tutor.lastOnline == null) {
                    hoursSinceOnline = null
                } else {
                    let lastOnline = new Date(tutor.lastOnline.toDate())
                    hoursSinceOnline = Math.floor(
                        (today - lastOnline) / (1000 * 60 * 60)
                    )
                }
                setTutorId(tutor.uid)
                setTutor(tutor)
                setHoursSinceOnline(hoursSinceOnline)
                setBookable(true)
            })
        })
    }, [])

    console.log('tutor', degreeArray)

    useEffect(() => {
        if (tutorId) {
            setLoaded(true)
            // Snapshot listener for total tutor sessions
            onSnapshot(
                query(
                    collection(db, 'sessions'),
                    where('tutor', '==', tutorId)
                ),
                where('status', '==', 'completed'),
                (querySnapshot) => {
                    let sessions = []
                    querySnapshot.forEach((doc) => {
                        let session = doc.data()
                        if (
                            session.status == 'completed' &&
                            session.lengthInMinutes > 0
                        ) {
                            sessions.push(session)
                        }
                    })
                    setSessions(sessions)
                    setTotalSessions(sessions.length)
                }
            )
            // Snapshot listener for tutor reviews
            const unsubscribe = onSnapshot(
                query(collection(db, 'reviews'), where('tutor', '==', tutorId)),
                (querySnapshot) => {
                    let reviews = []
                    querySnapshot.forEach((doc) => {
                        let review = doc.data()
                        reviews.push(review)
                    })
                    setReviews(reviews)
                }
            )
            return unsubscribe
        }
    }, [tutorId])

    // Get rating
    useEffect(() => {
        // Get rating
        let rating = 0
        reviews.map((review) => {
            rating += review.rating
        })
        rating = rating / reviews.length || 0
        setRating(rating.toFixed(1))
    }, [reviews])

    if (!loaded) {
        return <LoadingPage></LoadingPage>
    }
    // Return 404
    if (!tutor && loaded) {
        return (
            <SafeAreaView style={styles.container}>
                <View style={styles.maxWidth}>
                    <View
                        style={[
                            styles.mainSection,
                            {
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        ]}
                    >
                        <Text style={styles.title}>404 Tutor not found</Text>
                    </View>
                </View>
            </SafeAreaView>
        )
    }

    // Sort the ratings from highest rating to lowest
    reviews.sort((a, b) => {
        return b.rating - a.rating
    })

    //  update user info
    const updateUserInfo = async function (data: EditProfileForm) {
        const languages = {}
        data.languages.forEach((language) => {
            languages[language] = true
        })
        const subjects = {}
        data.subjects.forEach((subject) => {
            subjects[subject] = true
        })
        const exams = []
        data.examTutor.forEach((exam) => {
            exams.push(exam.id)
        })

        const userRef = collection(db, 'users')
        const q = query(userRef, where('uid', '==', user.uid))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            updateDoc(doc.ref, {
                name: data.name,
                skills: data.skills,
                languages: languages,
                subjects: subjects,
                location: data.location,
                headline: data.headline,
                description: data.description,
                degrees: degreeArray,
                educationLevels: selectedEducationLevels,
                examTutor: exams,
            })
        })
        customAlert.success(
            '[Profile Updated] The changes to your profile have been saved!'
        )
    }

    // Render
    return (
        <SafeAreaView style={styles.container}>
            <UploadPhotoDialog
                visible={showUploadPhotoDialog}
                setVisible={setShowUploadPhotoDialog}
            />

            {user && user.uid === tutor.uid ? (
                <View style={[styles.banner]}>
                    <Text
                        style={{
                            color: text,
                            fontFamily: defaultStyles.boldFont,
                            fontSize: 16,
                        }}
                    >
                        You are in edit profile mode
                        {/* Public icon */}
                        <FontAwesome5
                            name="user-edit"
                            size={16}
                            color={primaryVariant}
                            style={{ marginLeft: 5 }}
                        ></FontAwesome5>
                    </Text>
                </View>
            ) : null}

            <View style={styles.maxWidth}>
                {/* Tutor info section  */}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={styles.mainSection}
                >
                    {/* REPORT MODAL  */}
                    <ReportModal
                        modalVisible={reportModal}
                        setModalVisible={setReportModal}
                        tutor={tutor}
                        navigation={navigation}
                    />

                    <View
                        style={{
                            display: 'flex',
                            // LAYOUT FLEX
                            flexDirection: isLg ? 'row' : 'column',
                            width: '100%',
                            paddingVertical: 16,
                            paddingHorizontal: isMd ? 60 : 30,
                        }}
                    >
                        <View
                            style={{
                                // Set width for all breakpoints
                                borderRightColor: border,
                                borderRightWidth: !isLg ? 0 : 1,
                                maxWidth: isLg ? 300 : '100%',
                                minWidth: isLg ? 300 : '100%',
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    paddingTop: 0,
                                    paddingBottom: !isSm ? 15 : 20,
                                    paddingLeft: !isSm ? 15 : 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: !isSm && '100%',
                                    borderBottomColor: border,
                                    borderBottomWidth: !isLg ? 1 : 0,
                                }}
                            >
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 30,
                                        paddingRight: 20,
                                    }}
                                ></View>

                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        position: 'relative',
                                        width: 130,
                                        height: 130,
                                    }}
                                >
                                    <FadeIn>
                                        <Image
                                            style={styles.profileImg}
                                            source={{
                                                uri: tutor.picture
                                                    ? tutor.picture
                                                    : 'https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e',
                                            }}
                                        />
                                    </FadeIn>

                                    {/* Edit profile image button */}
                                    <TouchableOpacity
                                        style={[
                                            {
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                width: 40,
                                                height: 40,
                                                borderRadius: 100,
                                                backgroundColor: background,
                                                border: '1px solid ' + primary,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            },
                                        ]}
                                        onPress={() => {
                                            setShowUploadPhotoDialog(true)
                                        }}
                                    >
                                        <FontAwesome5
                                            name="pencil-alt"
                                            size={16}
                                            color={primary}
                                        ></FontAwesome5>
                                    </TouchableOpacity>
                                </View>

                                {/* Name and online badge */}
                                <View>
                                    <View
                                        style={{
                                            // marginTop: 20,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text style={styles.title}>
                                            {tutor.name.split(' ').length > 1
                                                ? `${
                                                      tutor.name.split(' ')[0]
                                                  } ${
                                                      tutor.name.split(
                                                          ' '
                                                      )[1][0]
                                                  }.`
                                                : tutor.name}
                                        </Text>
                                        {hoursSinceOnline !== null &&
                                        hoursSinceOnline <= 1 ? (
                                            <Text style={styles.online}>
                                                <FontAwesome
                                                    name="circle"
                                                    size={12}
                                                    color={'#00AA54'}
                                                />{' '}
                                                Online
                                            </Text>
                                        ) : null}
                                    </View>

                                    {/* Verification */}
                                    {tutor.isVerified && (
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginTop: 10,
                                            }}
                                        >
                                            <Text style={styles.verified}>
                                                <FontAwesome
                                                    style={{ marginRight: 10 }}
                                                    name="check-circle"
                                                    size={16}
                                                    color={green}
                                                />{' '}
                                                Credentials verified by Tutred
                                            </Text>
                                        </View>
                                    )}

                                    {/* Super tutor */}
                                    {tutor.superTutor && (
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginTop: 5,
                                            }}
                                        >
                                            <Text style={styles.verified}>
                                                <FontAwesome5
                                                    style={{ marginRight: 10 }}
                                                    name="award"
                                                    size={16}
                                                    color={primary}
                                                />{' '}
                                                Super Tutor
                                            </Text>
                                        </View>
                                    )}

                                    {/* Total sessions */}
                                    <View
                                        style={{
                                            marginTop: 10,
                                            flexDirection: isMd
                                                ? 'row'
                                                : 'column',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.tutorInfo,
                                                {
                                                    marginRight: 15,
                                                    marginTop: isMd ? 0 : 5,
                                                },
                                            ]}
                                        >
                                            <AntDesign
                                                style={{ marginRight: 10 }}
                                                name="laptop"
                                                size={16}
                                                color={textVariant}
                                            />
                                            {totalSessions} Completed Session(s)
                                        </Text>
                                    </View>

                                    {/* Languages list, */}
                                    <View
                                        style={{
                                            marginTop: 5,
                                            flexDirection: isMd
                                                ? 'row'
                                                : 'column',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.tutorInfo,
                                                {
                                                    marginRight: 15,
                                                    marginTop: isMd ? 0 : 5,
                                                },
                                            ]}
                                        >
                                            <Feather
                                                style={{ marginRight: 10 }}
                                                name="globe"
                                                size={16}
                                                color={textVariant}
                                            />
                                            {Object.keys(tutor.languages).map(
                                                (language, index) => {
                                                    if (
                                                        index ==
                                                        Object.keys(
                                                            tutor.languages
                                                        ).length -
                                                            1
                                                    ) {
                                                        language = language
                                                    } else {
                                                        language =
                                                            language + ', '
                                                    }
                                                    return <>{language}</>
                                                }
                                            )}
                                        </Text>
                                    </View>

                                    {/* Tutor info -Location, Rating, Sessions, Join date */}
                                    <View
                                        style={{
                                            marginTop: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Text style={[styles.tutorInfo]}>
                                            <AntDesign
                                                style={{ marginRight: 10 }}
                                                name="enviromento"
                                                size={16}
                                                color={textVariant}
                                            />
                                            {tutor.location.province}
                                        </Text>

                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            {rating > 0 && (
                                                <Text
                                                    style={[
                                                        styles.tutorInfo,
                                                        styles.tutorRating,
                                                        { marginTop: 5 },
                                                    ]}
                                                >
                                                    <FontAwesome
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        name="star"
                                                        size={14}
                                                        color={yellow}
                                                    />
                                                    {rating} ({reviews.length})
                                                </Text>
                                            )}
                                        </View>

                                        <View
                                            style={{
                                                marginTop: 15,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {isTutor &&
                                                user &&
                                                user.uid === tutor.uid && (
                                                    <TouchableOpacity
                                                        disabled={!bookable}
                                                        onPress={() => {
                                                            handleSubmit(
                                                                (data) => {
                                                                    updateUserInfo(
                                                                        data
                                                                    )
                                                                },
                                                                (error) => {
                                                                    customAlert.error(
                                                                        'Please correct the errors in the form and try again.'
                                                                    )
                                                                }
                                                            )()
                                                            // updateUserInfo()
                                                        }}
                                                        style={[
                                                            styles.button,
                                                            {
                                                                marginTop: isSm
                                                                    ? 0
                                                                    : 15,
                                                                opacity:
                                                                    bookable
                                                                        ? 1
                                                                        : 0.7,
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={
                                                                styles.buttonText
                                                            }
                                                        >
                                                            Save changes
                                                        </Text>
                                                    </TouchableOpacity>
                                                )}

                                            <TouchableOpacity
                                                disabled={!bookable}
                                                onPress={() => {
                                                    navigation.navigate(
                                                        'Profile'
                                                    )
                                                }}
                                                style={[
                                                    styles.button,
                                                    {
                                                        marginTop: 5,
                                                        backgroundColor:
                                                            background,
                                                        border:
                                                            '1px solid ' +
                                                            primary,
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        styles.buttonText,
                                                        {
                                                            color: primary,
                                                        },
                                                    ]}
                                                >
                                                    Cancel
                                                </Text>
                                            </TouchableOpacity>
                                        </View>

                                        {/* Share buttons */}
                                        <View
                                            style={{
                                                maxWidth: 245,
                                                marginTop: 15,
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <TouchableOpacity
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                onPress={() => {
                                                    Clipboard.setString(
                                                        `${shareLink}/tutors/` +
                                                            tutor.uid
                                                    )
                                                    customAlert.success(
                                                        '[Share Tutor] Copied tutor profile link to your clipboard.'
                                                    )
                                                }}
                                            >
                                                <FontAwesome5
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="clipboard"
                                                ></FontAwesome5>
                                            </TouchableOpacity>

                                            <A
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: 10,
                                                }}
                                                target="_blank"
                                                href={
                                                    `sms:&body=Check out this tutor on Tutred! ${shareLink}/tutors/` +
                                                    urlId
                                                }
                                            >
                                                <AntDesign
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="message1"
                                                ></AntDesign>
                                            </A>

                                            <A
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: 10,
                                                }}
                                                target="_blank"
                                                href={
                                                    `https://twitter.com/intent/tweet?text=Check out this tutor on Tutred! ${shareLink}/tutors/` +
                                                    urlId
                                                }
                                            >
                                                <FontAwesome5
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="twitter"
                                                ></FontAwesome5>
                                            </A>

                                            <A
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: 10,
                                                }}
                                                target="_blank"
                                                href={
                                                    `https://www.facebook.com/sharer/sharer.php?u=${shareLink}/tutors/` +
                                                    urlId
                                                }
                                            >
                                                <FontAwesome5
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="facebook"
                                                ></FontAwesome5>
                                            </A>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* Right Section  */}
                        <Container sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Tabs
                                    value={view}
                                    onChange={(event, newValue) =>
                                        setView(newValue)
                                    }
                                    variant="fullWidth"
                                    color={primary}
                                >
                                    <Tab
                                        label="Personal"
                                        value={'personal'}
                                        icon={
                                            personalFormErrors ? (
                                                <MaterialIcons
                                                    name="error"
                                                    size={16}
                                                    color={red}
                                                    style={{ marginRight: 6 }}
                                                />
                                            ) : null
                                        }
                                        iconPosition="start"
                                        sx={{
                                            fontSize: 16,
                                        }}
                                    />
                                    <Tab
                                        label="Tutor Profile"
                                        value={'tutor'}
                                        icon={
                                            tutorFormErrors ? (
                                                <MaterialIcons
                                                    name="error"
                                                    size={16}
                                                    color={red}
                                                    style={{ marginRight: 6 }}
                                                />
                                            ) : null
                                        }
                                        iconPosition="start"
                                        sx={{
                                            fontSize: 16,
                                        }}
                                    />
                                    <Tab
                                        label="Education"
                                        value={'education'}
                                        sx={{
                                            fontSize: 16,
                                        }}
                                    />
                                </Tabs>
                            </Box>
                            <Box sx={{ padding: 6 }}>
                                <Box
                                    sx={{
                                        display:
                                            view === 'personal'
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    <EditPersonalDetails
                                        control={control}
                                        user={extendedUser}
                                        errors={errors}
                                        watch={watch}
                                        setValue={setValue}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display:
                                            view === 'tutor' ? 'block' : 'none',
                                    }}
                                >
                                    <EditTutorProfile
                                        user={extendedUser}
                                        control={control}
                                        errors={errors}
                                        watch={watch}
                                        setValue={setValue}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display:
                                            view === 'education'
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    <Education
                                        degrees={tutor?.degrees}
                                        selectedEducationLevels={
                                            selectedEducationLevels
                                        }
                                        setSelectedEducationLevels={
                                            setSelectedEducationLevels
                                        }
                                    />
                                </Box>
                            </Box>
                        </Container>
                    </View>

                    {/* Footer */}
                    {isSm ? (
                        <View style={{ marginTop: 100, width: '100%' }}>
                            <Footer></Footer>{' '}
                        </View>
                    ) : null}
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}
