import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import { useUserContext } from '@contexts/userContext'
import formatName from '@helpers/formatName'
import useFinalizeInstaBookSession from '@hooks/useFinalizeInstaBookSession'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useMemo, useState } from 'react'
import { ActivityIndicator, Text, TouchableOpacity } from 'react-native'
import { decode } from 'html-entities'
import { HelpOutline } from '@mui/icons-material'
import {
    Link,
    useNavigation,
    ParamListBase,
    NavigationProp,
} from '@react-navigation/native'
import paymentMethodIcon from '@icons/payment-method.png'
import sessionRecordingIcon from '@icons/session-recording.png'
import serviceFeeIcon from '@icons/service-fee.png'
import totalIcon from '@icons/total.png'
import sessionLengthIcon from '@icons/session-length.png'
import sessionFeeIcon from '@icons/session-fee.png'
import PaymentMethod from '@components/PaymentMethod'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { AxiosResponse } from 'axios'
import InvoiceLineItem from '@components/InvoiceLineItem'
import SessionFeeIcon from '@icons/SessionFeeIcon'
import SessionRecordingFeeIcon from '@icons/SessionRecordingFeeIcon'
import SessionLengthIcon from '@icons/SessionLengthIcon'
import TotalIcon from '@icons/TotalIcon'
import ServiceFeeIcon from '@icons/ServiceFeeIcon'
import PaymentMethodIcon from '@components/PaymentMethodIcon'

export const InstaBookConfirmModal = ({
    showModal,
    setShowModal,
    tutor,
    setButtonLoading,
}) => {
    if (!tutor?.instaBookPricing) {
        return null
    }

    const stripe = useStripe()
    const elements = useElements()
    const [loading, setLoading] = useState(false)
    const [enableRecording, setEnableRecording] = useState(false)
    const [showPaymentChange, setShowPaymentChange] = useState(false)
    const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] =
        useState(false)
    const { finalizeInstaBookSession } = useFinalizeInstaBookSession()
    const navigation: NavigationProp<ParamListBase> = useNavigation()

    const { background, border, text, green, redVariant, primary } =
        useColorsContext()
    const { customerId } = useUserContext()
    const { authenticatedAxios } = useAxiosWithAuth()
    const defaultStyles = useStyles()
    const { paymentMethods } = useUserContext()
    const theme = useTheme()
    const isXl = useMediaQuery(theme.breakpoints.up('xl'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const [selectedPricingIndex, setSelectedPricingIndex] = useState(0)

    const pricingOptions = useMemo(() => {
        return Object.keys(tutor?.instaBookPricing).map((key) => {
            const pricing = tutor?.instaBookPricing[key]
            return {
                label: `${pricing.length} mins`,
                contextLabel: ` ${decode('\u00b7')} CA$${pricing.rate}`,
                value: pricing,
            }
        })
    }, [tutor?.instaBookPricing])

    const selectedPricingOption = useMemo(() => {
        return pricingOptions?.[selectedPricingIndex]?.value
    }, [selectedPricingIndex, pricingOptions])

    const totalSessionFee = selectedPricingOption.rate
    const totalRecordingFee = enableRecording ? tutor.sessionRecordingFee : 0
    const taxesAndFees = useMemo(() => {
        return Number((totalSessionFee + totalRecordingFee) * 0.1)
    }, [totalSessionFee, totalRecordingFee])

    const totalCost = useMemo(() => {
        return totalSessionFee + totalRecordingFee + Number(taxesAndFees)
    }, [totalSessionFee, totalRecordingFee, taxesAndFees])

    const onSubmitPayment = async () => {
        setLoading(true)
        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
            console.error('CardElement not found')
            // setError('Card details not entered')
            return
        }

        console.log('Creating payment method...')
        const { error: paymentMethodError, paymentMethod } =
            await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            })

        if (paymentMethodError) {
            console.error(
                'Error creating payment method:',
                paymentMethodError.message
            )
            // setError(paymentMethodError.message)
            return
        }

        console.log('Payment method created:', paymentMethod.id)

        console.log('Calling backend to attach PaymentMethod...')
        let newPaymentMethodId = null

        authenticatedAxios
            .post(`api/${customerId}/payment-method`, {
                paymentMethodId: paymentMethod.id,
                setAsDefault: newDefaultPaymentMethod,
            })
            .then(
                (
                    response: AxiosResponse<{
                        success: boolean
                        paymentMethodId: string
                    }>
                ) => {
                    newPaymentMethodId = response.data.paymentMethodId
                    const tutorPayout =
                        (totalSessionFee + totalRecordingFee) * 0.9
                    setLoading(true)
                    finalizeInstaBookSession(
                        totalCost,
                        tutor,
                        enableRecording,
                        selectedPricingOption.length,
                        tutorPayout,
                        response.data.paymentMethodId
                    ).finally(() => {
                        setButtonLoading(false)
                        setLoading(false)
                        setShowModal(false)
                    })
                }
            )
            .catch((error) => {
                console.log('error', error)
            })
    }

    return (
        <Dialog
            key={3}
            open={showModal}
            scroll="paper"
            onClose={() => {
                setShowModal(false)
            }}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>
                {' '}
                Start an Instabook session with {formatName(
                    tutor.name,
                    false
                )}{' '}
                ?
            </DialogTitle>
            <DialogContent>
                <Typography
                    variant={isMd ? 'subtitle1' : 'subtitle2'}
                    sx={{
                        fontFamily: defaultStyles.titleFont,
                        marginBottom: isMd ? 2 : 1,
                        textAlign: 'center',
                        color: '#8c8c8c',
                    }}
                >
                    Session Details
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: isMd ? 3 : 2,
                    }}
                >
                    <MultiSwitchToggle
                        options={pricingOptions || []}
                        setSelectedIndex={setSelectedPricingIndex}
                        selectedIndex={selectedPricingIndex}
                    />
                </Box>
                <InvoiceLineItem
                    icon={<SessionFeeIcon size={22} />}
                    label="Session Fee"
                    amount={totalSessionFee}
                />

                {tutor.allowSessionRecording && (
                    <Box
                        sx={{
                            marginBottom: isMd ? 2 : 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: 22,
                                    height: 22,
                                }}
                            >
                                <SessionRecordingFeeIcon size={22} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant={isMd ? 'body1' : 'body2'}
                                    sx={{
                                        fontFamily: defaultStyles.titleFont,
                                        ml: 2,
                                    }}
                                >
                                    Would you like to enable session recording?
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    {' '}
                                    <Typography
                                        variant={isMd ? 'body2' : 'caption'}
                                        sx={{
                                            fontFamily:
                                                defaultStyles.regularFont,
                                            marginRight: 0.5,
                                            ml: 2,
                                        }}
                                    >
                                        You can add a session recording for
                                    </Typography>
                                    <Typography
                                        variant={isMd ? 'body2' : 'caption'}
                                        sx={{
                                            fontFamily: defaultStyles.titleFont,
                                        }}
                                    >
                                        {tutor.sessionRecordingFee > 0
                                            ? `$${tutor.sessionRecordingFee.toFixed(2)}`
                                            : 'FREE'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Checkbox
                            checked={enableRecording}
                            onChange={() =>
                                setEnableRecording(!enableRecording)
                            }
                        />
                    </Box>
                )}

                <Divider
                    variant="middle"
                    sx={{
                        backgroundColor: border,
                        marginTop: isMd ? 2 : 1,
                        marginBottom: isMd ? 2 : 1,
                    }}
                />
                <InvoiceLineItem
                    icon={<SessionLengthIcon size={22} />}
                    label={`${selectedPricingOption?.length} min session`}
                    amount={selectedPricingOption?.rate || 0.0}
                />

                <InvoiceLineItem
                    icon={<ServiceFeeIcon size={22} />}
                    label="Service Fee"
                    amount={taxesAndFees}
                    tooltip="Includes taxes and our service fee."
                />
                {totalRecordingFee > 0 && (
                    <InvoiceLineItem
                        icon={<SessionRecordingFeeIcon size={22} />}
                        label="Session Recording Fee"
                        amount={totalRecordingFee}
                    />
                )}
                <InvoiceLineItem
                    icon={<TotalIcon size={26} />}
                    label="Total"
                    amount={totalCost}
                />
                {paymentMethods?.length > 0 && (
                    <Box>
                        {showPaymentChange ? (
                            <Box sx={{ marginBottom: isMd ? 2 : 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: 22,
                                            height: 22,
                                        }}
                                    >
                                        <PaymentMethodIcon size={22} />
                                    </Box>
                                    <Typography
                                        variant={isMd ? 'body1' : 'body2'}
                                        sx={{
                                            fontFamily: defaultStyles.titleFont,
                                            marginLeft: 2,
                                        }}
                                    >
                                        Payment Method:
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        border: '1px solid #000',
                                        padding: 2,
                                        marginTop: 1,
                                        borderRadius: '4px',
                                    }}
                                >
                                    <CardElement />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginTop: 1,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Checkbox
                                            value={newDefaultPaymentMethod}
                                            onChange={() => {
                                                setNewDefaultPaymentMethod(
                                                    !newDefaultPaymentMethod
                                                )
                                            }}
                                            size="small"
                                        />
                                        <Typography variant="body2">
                                            Set as default?
                                        </Typography>
                                    </Box>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setShowPaymentChange(false)
                                        }}
                                    >
                                        <Typography
                                            variant={isMd ? 'body2' : 'caption'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.boldFont,
                                                textAlign: 'right',
                                                color: primary,
                                            }}
                                        >
                                            Cancel
                                        </Typography>
                                    </TouchableOpacity>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: isMd ? 2 : 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                width: 22,
                                                height: 22,
                                            }}
                                        >
                                            <PaymentMethodIcon size={22} />
                                        </Box>
                                        <Typography
                                            variant={isMd ? 'body1' : 'body2'}
                                            sx={{
                                                marginLeft: 2,
                                                fontFamily:
                                                    defaultStyles.titleFont,
                                            }}
                                        >
                                            Payment Method:
                                        </Typography>
                                    </Box>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setShowPaymentChange(true)
                                        }}
                                    >
                                        <Typography
                                            variant={isMd ? 'body2' : 'caption'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.boldFont,
                                                textAlign: 'right',
                                                color: primary,
                                            }}
                                        >
                                            Change payment method
                                        </Typography>
                                    </TouchableOpacity>
                                </Box>
                                <PaymentMethod
                                    brand={paymentMethods[0].card.brand}
                                    endingIn={paymentMethods[0].card.last4}
                                />
                            </Box>
                        )}
                    </Box>
                )}

                <Box
                    sx={{
                        marginBottom: isMd ? 4 : 2,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Typography
                        variant={isMd ? 'body2' : 'caption'}
                        sx={{
                            fontFamily: defaultStyles.regularFont,
                            width: '80%',
                            textAlign: 'center',
                        }}
                    >
                        The session will start after you both discuss the
                        details in the {tutor.instaBookBufferPeriod} minute
                        window provided by your tutor.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    fullWidth
                    onClick={async () => {
                        setShowModal(false)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    onClick={async () => {
                        if (showPaymentChange) {
                            onSubmitPayment()
                        } else {
                            const tutorPayout =
                                (totalSessionFee + totalRecordingFee) * 0.9
                            setLoading(true)
                            finalizeInstaBookSession(
                                totalCost,
                                tutor,
                                enableRecording,
                                selectedPricingOption.length,
                                tutorPayout
                            ).finally(() => {
                                setButtonLoading(false)
                                setLoading(false)
                                setShowModal(false)
                            })
                        }
                    }}
                >
                    {loading ? <ActivityIndicator /> : 'Start'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
