import React from 'react';
import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg';
import { useColorsContext } from '@utils/styles';
const CenteredIcon = ({ size = 35 }) => {
    const { text, textInverse } = useColorsContext();
    return (
        <Svg width={size} height={size} viewBox="0 0 35 35" fill="none">
            <G clipPath="url(#clip0_260_6436)">
                <Path d="M17.5 25.1562C21.7219 25.1562 25.1562 21.7219 25.1562 17.5C25.1562 13.2781 21.7219 9.84375 17.5 9.84375C13.2781 9.84375 9.84375 13.2781 9.84375 17.5C9.84375 21.7219 13.2781 25.1562 17.5 25.1562ZM19.9609 23.5812C19.2008 23.893 18.3695 24.0625 17.5 24.0625C16.6305 24.0625 15.7992 23.8875 15.0391 23.5812V20.2344C15.0391 19.6328 15.5312 19.1406 16.1328 19.1406H18.8672C19.4688 19.1406 19.9609 19.6328 19.9609 20.2344V23.5812ZM16.1328 16.1328C16.1328 15.3781 16.7453 14.7656 17.5 14.7656C18.2547 14.7656 18.8672 15.3781 18.8672 16.1328C18.8672 16.8875 18.2547 17.5 17.5 17.5C16.7453 17.5 16.1328 16.8875 16.1328 16.1328ZM17.5 10.9375C21.1203 10.9375 24.0625 13.8797 24.0625 17.5C24.0625 19.8078 22.8594 21.8367 21.0547 23.007V20.2344C21.0547 19.075 20.1469 18.1344 19.0094 18.0633C19.5836 17.6148 19.9609 16.9203 19.9609 16.1328C19.9609 14.7766 18.8562 13.6719 17.5 13.6719C16.1438 13.6719 15.0391 14.7766 15.0391 16.1328C15.0391 16.9203 15.4164 17.6094 15.9906 18.0633C14.8531 18.1398 13.9453 19.0805 13.9453 20.2344V23.007C12.1406 21.8367 10.9375 19.8078 10.9375 17.5C10.9375 13.8797 13.8797 10.9375 17.5 10.9375Z" fill={text} />
                <Path d="M17.1118 8.04453C17.2212 8.15391 17.3579 8.20312 17.5001 8.20312C17.6423 8.20312 17.779 8.14844 17.8884 8.04453L20.6227 5.31016C20.7813 5.15156 20.8251 4.91641 20.743 4.71406C20.661 4.51172 20.4587 4.375 20.2399 4.375H19.1462V1.09375C19.1462 0.792969 18.9001 0.546875 18.5993 0.546875H16.4118C16.111 0.546875 15.8649 0.792969 15.8649 1.09375V4.375H14.7712C14.5524 4.375 14.3501 4.50625 14.268 4.71406C14.1805 4.91641 14.2298 5.15156 14.3884 5.31016L17.1227 8.04453H17.1118ZM16.4063 5.46875C16.7071 5.46875 16.9532 5.22266 16.9532 4.92188V1.64062H18.0469V4.92188C18.0469 5.22266 18.293 5.46875 18.5938 5.46875H18.9165L17.5001 6.88516L16.0837 5.46875H16.4063Z" fill={text} />
                <Path d="M16.4063 34.453H18.5938C18.8946 34.453 19.1407 34.2069 19.1407 33.9062V30.6249H20.2344C20.4532 30.6249 20.6555 30.4937 20.7375 30.2858C20.825 30.0835 20.7758 29.8483 20.6172 29.6897L17.8828 26.9554C17.6696 26.7421 17.325 26.7421 17.1118 26.9554L14.3774 29.6897C14.2188 29.8483 14.175 30.0835 14.2571 30.2858C14.3391 30.4882 14.5414 30.6249 14.7602 30.6249H15.8539V33.9062C15.8539 34.2069 16.1 34.453 16.4008 34.453H16.4063ZM17.5 28.1147L18.9164 29.5312H18.5938C18.293 29.5312 18.0469 29.7772 18.0469 30.078V33.3593H16.9532V30.078C16.9532 29.7772 16.7071 29.5312 16.4063 29.5312H16.0836L17.5 28.1147Z" fill={text} />
                <Path d="M33.9062 15.8595H30.6249V14.7658C30.6249 14.547 30.4937 14.3447 30.2858 14.2627C30.0835 14.1752 29.8483 14.2244 29.6897 14.383L26.9554 17.1174C26.7421 17.3306 26.7421 17.6752 26.9554 17.8884L29.6897 20.6228C29.7937 20.7267 29.9358 20.7814 30.078 20.7814C30.1491 20.7814 30.2202 20.7705 30.2858 20.7377C30.4882 20.6556 30.6249 20.4533 30.6249 20.2345V19.1408H33.9062C34.2069 19.1408 34.453 18.8947 34.453 18.5939V16.4064C34.453 16.1056 34.2069 15.8595 33.9062 15.8595ZM33.3593 18.047H30.078C29.7772 18.047 29.5312 18.2931 29.5312 18.5939V18.9166L28.1147 17.5002L29.5312 16.0838V16.4064C29.5312 16.7072 29.7772 16.9533 30.078 16.9533H33.3593V18.047Z" fill={text} />
                <Path d="M1.09375 19.1408H4.375V20.2346C4.375 20.4533 4.50625 20.6556 4.71406 20.7377C4.77969 20.765 4.85078 20.7814 4.92188 20.7814C5.06406 20.7814 5.20625 20.7267 5.31016 20.6228L8.04453 17.8885C8.25781 17.6752 8.25781 17.3306 8.04453 17.1174L5.31016 14.383C5.15156 14.2244 4.92188 14.1806 4.71406 14.2627C4.51172 14.3447 4.375 14.5471 4.375 14.7658V15.8596H1.09375C0.792969 15.8596 0.546875 16.1056 0.546875 16.4064V18.5939C0.546875 18.8947 0.792969 19.1408 1.09375 19.1408ZM1.64062 16.9533H4.92188C5.22266 16.9533 5.46875 16.7072 5.46875 16.4064V16.0838L6.88516 17.5002L5.46875 18.9166V18.5939C5.46875 18.2931 5.22266 18.0471 4.92188 18.0471H1.64062V16.9533Z" fill={text} />
                <Path d="M24.4618 11.0851H28.3282C28.5469 11.0851 28.7493 10.9538 28.8313 10.746C28.9188 10.5437 28.8696 10.3085 28.711 10.1499L27.9399 9.37881L30.2586 7.06006C30.4719 6.84678 30.4719 6.50225 30.2586 6.28896L28.711 4.74131C28.5032 4.5335 28.1422 4.5335 27.9399 4.74131L25.6211 7.06006L24.8446 6.28896C24.686 6.13037 24.4563 6.08662 24.2485 6.16865C24.0461 6.25068 23.9094 6.45303 23.9094 6.67178V10.5382C23.9094 10.839 24.1555 11.0851 24.4563 11.0851H24.4618ZM25.0086 7.98975L25.2383 8.21396C25.4516 8.42725 25.7961 8.42725 26.0094 8.21396L28.3282 5.89521L29.0993 6.66631L26.7805 8.98506C26.5672 9.19834 26.5672 9.54287 26.7805 9.75615L27.0047 9.98037H25.0032V7.97881L25.0086 7.98975Z" fill={text} />
                <Path d="M4.74131 28.7165L6.28897 30.2642C6.39287 30.3681 6.52959 30.4228 6.67725 30.4228C6.8249 30.4228 6.96162 30.3626 7.06553 30.2642L9.38428 27.9454L10.1608 28.7165C10.3194 28.8751 10.5546 28.9188 10.7569 28.8368C10.9593 28.7548 11.096 28.5524 11.096 28.3337V24.4673C11.096 24.1665 10.8499 23.9204 10.5491 23.9204H6.68272C6.46397 23.9204 6.26162 24.0517 6.17959 24.2595C6.09209 24.4618 6.14131 24.697 6.2999 24.8556L7.071 25.6267L4.75225 27.9454C4.53896 28.1587 4.53896 28.5032 4.75225 28.7165H4.74131ZM8.21943 26.0095C8.43271 25.7962 8.43271 25.4517 8.21943 25.2384L7.99521 25.0142H9.99678V27.0157L9.76709 26.7915C9.55381 26.5782 9.20928 26.5782 8.996 26.7915L6.67725 29.1103L5.90615 28.3392L8.2249 26.0204L8.21943 26.0095Z" fill={text} />
                <Path d="M28.8312 24.2541C28.7492 24.0518 28.5468 23.915 28.3281 23.915H24.4617C24.1609 23.915 23.9148 24.1611 23.9148 24.4619V28.3283C23.9148 28.5471 24.046 28.7494 24.2539 28.8314C24.4562 28.9189 24.6914 28.8697 24.85 28.7111L25.6265 27.94L27.9453 30.2588C28.0492 30.3627 28.1859 30.4174 28.3335 30.4174C28.4812 30.4174 28.6179 30.3572 28.7218 30.2588L30.2695 28.7111C30.4828 28.4979 30.4828 28.1533 30.2695 27.94L27.9507 25.6213L28.7218 24.8502C28.8804 24.6916 28.9242 24.4564 28.8421 24.2541H28.8312ZM29.0992 28.3283L28.3281 29.0994L26.0093 26.7807C25.9 26.6713 25.7632 26.6221 25.621 26.6221C25.4789 26.6221 25.3421 26.6768 25.2328 26.7807L25.0031 27.0049V25.0033H27.0046L26.7804 25.2275C26.5671 25.4408 26.5671 25.7854 26.7804 25.9986L29.0992 28.3174V28.3283Z" fill={text} />
                <Path d="M7.06011 9.37881L6.28901 10.1499C6.13042 10.3085 6.08667 10.5437 6.1687 10.746C6.25073 10.9483 6.45308 11.0851 6.67183 11.0851H10.5382C10.839 11.0851 11.0851 10.839 11.0851 10.5382V6.67178C11.0851 6.45303 10.9539 6.25068 10.746 6.16865C10.5437 6.08115 10.3085 6.13037 10.15 6.28896L9.37339 7.06006L7.05464 4.74131C6.84683 4.5335 6.48589 4.5335 6.28354 4.74131L4.73589 6.28896C4.52261 6.50225 4.52261 6.84678 4.73589 7.06006L7.05464 9.37881H7.06011ZM6.67183 5.90068L8.99058 8.21943C9.20386 8.43271 9.54839 8.43271 9.76167 8.21943L9.99136 7.99521V9.99678H7.98979L8.21401 9.77256C8.4273 9.55928 8.4273 9.21475 8.21401 9.00146L5.89526 6.68271L6.66636 5.91162L6.67183 5.90068Z" fill={text} />
            </G>
            <Defs>
                <ClipPath id="clip0_260_6436">
                    <Rect width="35" height="35" fill={textInverse} />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export default CenteredIcon;
