import {
    Box,
    Card,
    IconButton,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useNotificationsContext } from '@contexts/notificationsContext'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Notification } from 'dataTypes'
import TutredIcon from '@icons/TutredIcon'
import { Timestamp } from 'firebase/firestore'
import { format, isSameDay, isSameWeek, subDays } from 'date-fns'
import AntDesign from '@expo/vector-icons/AntDesign'
import { useHandleNotificationAction } from '@hooks/notifications/handleNotificationAction'

interface NotificationItemProps {
    notification: Notification
    lastNotification: Notification
    index: number
    type: 'all' | 'pending' | 'unread'
    dense?: boolean
}

const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    lastNotification,
    index,
    type,
    dense = false,
}) => {
    const { text, background, backgroundVariant, grey } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))
    const { markAsRead, deleteNotification } = useNotificationsContext()

    const titleDate =
        notification.timestamp instanceof Timestamp
            ? new Date(notification.timestamp.toDate())
            : new Date(notification.timestamp)

    const lastDate =
        index !== 0
            ? lastNotification.timestamp instanceof Timestamp
                ? new Date(lastNotification.timestamp.toDate())
                : new Date(lastNotification.timestamp)
            : null

    const getDateString = (date: Date) => {
        if (isSameDay(date, new Date())) return 'Today'
        if (isSameDay(date, subDays(new Date(), 1))) return 'Yesterday'
        if (isSameWeek(date, new Date())) return format(date, 'EEEE')
        return format(date, 'EEE, MMM d, yyyy')
    }

    const currentDateString = getDateString(titleDate)
    const lastDateString = lastDate ? getDateString(lastDate) : null

    const showDate = index === 0 || currentDateString !== lastDateString
    const handleNotificationAction = useHandleNotificationAction()

    return (
        <>
            {showDate && (
                <Typography
                    variant={isSm ? 'body2' : 'subtitle2'}
                    fontFamily={defaultStyles.titleFont}
                    color={theme.palette.text.secondary}
                    sx={{ mt: isSm ? 1 : 2, mb: isSm ? 0.5 : 1 }}
                >
                    {currentDateString}
                </Typography>
            )}
            <Card
                variant="outlined"
                sx={{
                    padding: isSm ? 1 : 2,
                    mb: isSm ? 1 : 2,
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: notification.read
                        ? background
                        : backgroundVariant,
                    position: 'relative',
                }}
            >
                <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() => handleNotificationAction(notification)}
                >
                    {!notification.read && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: isSm ? 4 : 8,
                                top: isSm ? 4 : 8,
                                backgroundColor: '#0027FF',
                                width: isSm ? 8 : 12,
                                height: isSm ? 8 : 12,
                                borderRadius: 999,
                                boxShadow: `0px 0px ${isSm ? '6px' : '8px'} 0px #0027FF`,
                            }}
                        ></Box>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box
                            sx={{
                                marginRight: isSm ? 1 : 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 999,
                                height: isSm ? 32 : 40,
                                width: isSm ? 32 : 40,
                                minWidth: isSm ? 32 : 40,
                                minHeight: isSm ? 32 : 40,
                                padding: isSm ? 0.25 : 0.5,
                            }}
                        >
                            <img
                                src={require('@assets/images/logo-hat.svg')}
                                alt="logo"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>
                        <Box>
                            <Typography
                                variant={isSm ? 'body1' : 'subtitle1'}
                                fontFamily={defaultStyles.titleFont}
                                color={text}
                                sx={{
                                    fontWeight: notification.read ? 400 : 600,
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: dense ? 1 : null,
                                }}
                            >
                                {notification.title}
                            </Typography>
                            <Typography
                                variant={isSm ? 'body2' : 'body1'}
                                fontFamily={defaultStyles.regularFont}
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: dense ? 1 : null,
                                }}
                            >
                                {notification.message}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: isSm ? 1 : 2,
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    fontFamily={defaultStyles.regularFont}
                                    color={theme.palette.text.secondary}
                                >
                                    {titleDate &&
                                        format(
                                            titleDate,
                                            "EEE, MMM d 'at' h:mm a"
                                        )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {!notification.read && (
                        <IconButton
                            onClick={() => markAsRead(notification.id)}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: 0,
                            }}
                        >
                            <AntDesign
                                name="close"
                                size={isSm ? 20 : 24}
                                color={theme.palette.grey[500]}
                            />
                        </IconButton>
                    )}
                </TouchableOpacity>
            </Card>
        </>
    )
}

export default NotificationItem
