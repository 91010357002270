import React from 'react'
import {
    Chip,
    Avatar,
    ChipProps,
    Typography,
    IconButton,
    Box,
} from '@mui/material'
import { languageIcons } from '../../../config/languages'
import AntDesign from '@expo/vector-icons/AntDesign'
import { useTheme } from '@mui/material/styles'
import { useColorsContext, useStyles } from '@utils/styles'
import { specialtyIcons } from '@config/subjects'
import TutredBaseChip from '../TutredBaseChip'

interface SpecialtyChipProps extends ChipProps {
    item: string
    size?: 'small' | 'medium' | 'large'
}

const SpecialtyChip = React.forwardRef<HTMLDivElement, SpecialtyChipProps>(
    ({ item, onDelete, size = 'medium', ...rest }, ref) => {
        const defaultStyles = useStyles()
        return (
            <TutredBaseChip
                ref={ref}
                size={size}
                sx={{
                    paddingHorizontal:
                        size === 'large' ? 2 : size === 'medium' ? 1.5 : 1,
                    paddingVertical: 0,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(229, 10, 142, 0.25)',
                    borderRadius:
                        size === 'large' ? 2 : size === 'medium' ? 1.5 : 1,
                    height: size === 'large' ? 36 : size === 'medium' ? 30 : 24,
                }}
                icon={
                    <Box
                        sx={{
                            width:
                                size === 'large'
                                    ? 22
                                    : size === 'medium'
                                      ? 18
                                      : 14,
                            height:
                                size === 'large'
                                    ? 22
                                    : size === 'medium'
                                      ? 18
                                      : 14,
                            backgroundColor: '#FFF',
                            WebkitMask: `url(${specialtyIcons[item]}) no-repeat center `,
                            mask: `url(${specialtyIcons[item]}) no-repeat center `,
                            maskSize: 'contain',
                            WebkitMaskSize: 'contain',
                            mr: 1,
                        }}
                    />
                }
                label={
                    <Typography
                        sx={{
                            fontSize:
                                size === 'large'
                                    ? 16
                                    : size === 'medium'
                                      ? 14
                                      : 10,
                        }}
                        color="rgba(229, 10, 142, 0.90)"
                        fontFamily={defaultStyles.titleFont}
                    >
                        {item}
                    </Typography>
                }
                onDelete={onDelete}
                {...rest}
            />
        )
    }
)

export default SpecialtyChip
