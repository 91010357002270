import { Box, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useStyles } from '@utils/styles'

interface Props {
    // Add props here
}

const TeachDifferentLanguages: React.FC<Props> = () => {
    const defaultStyles = useStyles()

    const languages = ['English', 'French', 'Spanish', 'Mandarin']
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Box sx={{ p: 4 }}>
            <Typography
                variant={isMd ? 'h2' : isSm ? 'h4' : 'h3'}
                fontFamily={defaultStyles.titleFont}
                textAlign="center"
                letterSpacing="-1.8px"
            >
                Teach different languages!
            </Typography>
            <Typography
                variant={isMd ? 'h5' : isSm ? 'body1' : 'h6'}
                fontFamily={defaultStyles.titleFont}
                textAlign="center"
                letterSpacing="-1.8px"
            >
                Choose from 30+ languages to teach
            </Typography>

            <Grid2 container>
                {languages.map((language) => (
                    <Grid2
                        size={{ xs: 6, sm: 3 }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: { md: 150, sm: 110, xs: 90 },
                                height: { md: 150, sm: 110, xs: 90 },
                                mb: { lg: 8, sm: 6, xs: 4 },
                                mt: { md: 4, sm: 2, xs: 1 },
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    maxHeight: {
                                        md: language === 'Spanish' ? 130 : 150,
                                        sm: language === 'Spanish' ? 100 : 120,
                                        xs: language === 'Spanish' ? 70 : 90,
                                    },
                                }}
                                src={require(
                                    `@assets/images/become-a-tutor/${language}.png`
                                )}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant={isMd ? 'h4' : isSm ? 'body1' : 'h6'}
                                fontFamily={defaultStyles.titleFont}
                                letterSpacing="-1.8px"
                            >
                                {language}
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    height: {
                                        md: 40,
                                        sm: 30,
                                        xs: 25,
                                    },
                                    ml: {
                                        xs: 0.5,
                                        md: 1,
                                    },
                                }}
                                src={require(
                                    `@assets/images/languages/${language}.svg`
                                )}
                            />
                        </Box>
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    )
}

export default TeachDifferentLanguages
