import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    collection,
    query,
    where,
    onSnapshot,
    doc,
    updateDoc,
    deleteDoc,
    orderBy,
} from 'firebase/firestore'
import { useFirebaseContext } from './firebaseContext'
import { useUserContext } from './userContext'
import { Notification } from 'dataTypes'
import { subDays } from 'date-fns'

const NotificationsContext = createContext<{
    notifications: Notification[]
    pendingNotifications: Notification[]
    unreadNotifications: Notification[]
    markAsRead: (notificationId: string) => Promise<void>
    deleteNotification: (notificationId: string) => Promise<void>
    markAllAsRead: () => Promise<void>
    allNotificationsRange?: Number
    setAllNotificationsRange?: (range: Number) => void
    badgeCount: string | number
}>({
    notifications: [],
    pendingNotifications: [],
    unreadNotifications: [],
    markAsRead: async () => {},
    deleteNotification: async () => {},
    markAllAsRead: async () => {},
    badgeCount: 0,
})

export const useNotificationsContext = () => useContext(NotificationsContext)

export const NotificationsProvider = ({ children }) => {
    const { db } = useFirebaseContext()
    const { userId } = useUserContext()
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [pendingNotifications, setPendingNotifications] = useState<
        Notification[]
    >([])
    const [unreadNotifications, setUnreadNotifications] = useState<
        Notification[]
    >([])
    const [allNotificationsRange, setAllNotificationsRange] = useState(30)
    useEffect(() => {
        if (!userId) return
        const notificationsRef = collection(db, 'notifications')
        const dateRange = subDays(new Date(), allNotificationsRange)
        const q = query(
            notificationsRef,
            where('userId', '==', userId),
            where('timestamp', '>=', dateRange),
            orderBy('timestamp', 'desc')
        )
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const notificationsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...(doc.data() as Notification),
            }))
            setNotifications(notificationsData)
        })
        return () => unsubscribe()
    }, [userId, allNotificationsRange])

    useEffect(() => {
        if (!userId) return
        const notificationsRef = collection(db, 'notifications')
        const q = query(
            notificationsRef,
            where('userId', '==', userId),
            where('status', '==', 'pending'),
            orderBy('timestamp', 'desc')
        )

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const pendingNotificationsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...(doc.data() as Notification),
            }))
            setPendingNotifications(pendingNotificationsData)
        })

        return () => unsubscribe()
    }, [userId])

    useEffect(() => {
        if (!userId) return
        const notificationsRef = collection(db, 'notifications')
        const q = query(
            notificationsRef,
            where('userId', '==', userId),
            where('read', '==', 'false'),
            orderBy('timestamp', 'desc')
        )

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const unreadNotifications = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...(doc.data() as Notification),
            }))
            setUnreadNotifications(unreadNotifications)
        })

        return () => unsubscribe()
    }, [userId])

    const badgeCount = useMemo(() => {
        const pendingCount = pendingNotifications.filter(
            (notification) => !!notification.read
        ).length
        const count = pendingCount + unreadNotifications.length
        if (count > 99) return '99+'
        else return count
    }, [unreadNotifications, pendingNotifications])

    const markAsRead = async (notificationId) => {
        const notificationDoc = doc(db, 'notifications', notificationId)
        await updateDoc(notificationDoc, { read: true })
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
                notification.id === notificationId
                    ? { ...notification, read: true }
                    : notification
            )
        )
    }

    const markAllAsRead = async () => {
        notifications.forEach((notification) => markAsRead(notification.id))
    }

    const deleteNotification = async (notificationId) => {
        const notificationDoc = doc(db, 'notifications', notificationId)
        await deleteDoc(notificationDoc)
        setNotifications((prevNotifications) =>
            prevNotifications.filter(
                (notification) => notification.id !== notificationId
            )
        )
    }

    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                pendingNotifications,
                unreadNotifications,
                markAsRead,
                deleteNotification,
                markAllAsRead,
                allNotificationsRange,
                setAllNotificationsRange,
                badgeCount,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    )
}
