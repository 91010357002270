import LocationIcon from '@icons/LocationIcon'
import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    SxProps,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { FC } from 'react'

interface LocationProps {
    province: string
    size?: 'small' | 'large'
    textSx?: SxProps
}

const Location: FC<LocationProps> = ({ province, size, textSx }) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const defaultStyles = useStyles()
    const { text } = useColorsContext()

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mt: 0.5,
            }}
        >
            <Box
                sx={{
                    width: 22,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LocationIcon size={size === 'small' ? 12 : 16} />
            </Box>

            <Typography
                variant={size === 'small' ? 'caption' : 'body1'}
                fontFamily={defaultStyles.boldFont}
                sx={{ color: text, ...textSx }}
            >
                {province}
            </Typography>
        </Box>
    )
}

export default Location
