import React, { FC } from 'react'
import {
    Box,
    Button,
    Card,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import ShowMoreText from 'react-show-more-text'
import Sections from '../Sections'
import TutredTooltip from '@components/TutredTooltip'
import QuestionMarkIcon from '@icons/QuestionMarkIcon'
import { languageIcons } from '@config/languages'
import TutredChipDisplay from '@components/TutredChipDisplay'
import SubjectChip from '@components/Chips/SubjectChip'
import SpecialtyChip from '@components/Chips/SpecialtyChip'
import ExamChip from '@components/Chips/ExamChip'
import TutredAvatar from '@components/TutredAvatar'
import formatName from '@helpers/formatName'
import Rating from '@components/TutorListCard/Rating'
import AntDesign from '@expo/vector-icons/AntDesign'
import Favourite from '@components/Favourite'
import Languages from '@components/TutorListCard/Languages'
import Location from '@components/TutorListCard/Location'
import SendIcon from '@icons/SendIcon'
import CheckIcon from '@icons/CheckIcon'
import { useUserContext } from '@contexts/userContext'
import { useDialog } from '@contexts/dialogContext'
import useNavigateToChat from '@hooks/useNavigateToChat'
import { NativeEventEmitter } from 'react-native'
import { useAlert } from 'react-alert'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'

interface TutorMainProps {
    tutor: any
    rating: string
    reviewCount: number
    setShowBookingDialog: (show: boolean) => void
}

const TutorMain: FC<TutorMainProps> = ({
    tutor,
    rating,
    reviewCount,
    setShowBookingDialog,
}) => {
    const defaultStyles = useStyles()
    const { text, background, primary, primaryLight } = useColorsContext()
    const { user, extendedUser, profile } = useUserContext()
    const { openLoginDialog } = useDialog()
    const { navigateToChat } = useNavigateToChat()
    const eventEmitter = new NativeEventEmitter()
    const customAlert = useAlert()
    const navigation: NavigationProp<ParamListBase> = useNavigation()

    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Card
            sx={{
                maxHeight: '100%',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: isSm ? 2 : 1,
                width: 364,
                boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.25)',
                borderRadius: isMd ? 6 : 0,

                '& .MuiPaper-root': {
                    borderRadius: isMd ? 6 : 0,
                    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.25)',
                },
            }}
        >
            <Box sx={{ overflowY: 'auto', pr: 2, width: '100%' }}>
                <Box>
                    {!isMd && (
                        <>
                            <Box
                                item
                                md={8}
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden',
                                    flexWrap: 'wrap',
                                    backgroundColor: background,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        position: 'relative',
                                    }}
                                >
                                    <TutredAvatar
                                        user={tutor}
                                        size={isSm ? 'large' : 'medium'}
                                        sx={{
                                            marginRight: { xs: 1, md: 2 },
                                        }}
                                    />
                                    {/* Name, Language, Location */}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {/* Name & Verified */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Typography
                                                variant={isSm ? 'h4' : 'h6'}
                                                fontFamily={
                                                    defaultStyles.boldFont
                                                }
                                                sx={{ color: text }}
                                            >
                                                {formatName(tutor.name)}
                                            </Typography>
                                            {tutor.isVerified && (
                                                <AntDesign
                                                    name="checkcircle"
                                                    size={isSm ? 18 : 14}
                                                    color={primary}
                                                    style={{
                                                        marginLeft: isSm
                                                            ? 8
                                                            : 4,
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        {isSm && (
                                            <>
                                                <Languages
                                                    tutorLanguages={
                                                        tutor?.languages
                                                    }
                                                    size="large"
                                                />
                                                <Location
                                                    province={
                                                        tutor?.location
                                                            ?.province
                                                    }
                                                    size="large"
                                                />
                                            </>
                                        )}

                                        {!isSm && (
                                            <Rating
                                                reviewCount={reviewCount}
                                                size={isSm ? 'large' : 'small'}
                                                rating={rating}
                                            />
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                        }}
                                    >
                                        {isSm && (
                                            <Rating
                                                reviewCount={reviewCount}
                                                size={'large'}
                                                rating={rating}
                                            />
                                        )}
                                        <Favourite
                                            tutor={tutor}
                                            size={36}
                                            containerStyle={{
                                                marginLeft: 8,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                    {!isSm && (
                        <Box sx={{ mt: 1 }}>
                            <Languages
                                tutorLanguages={tutor?.languages}
                                size="large"
                            />
                            <Location
                                province={tutor?.location?.province}
                                size="large"
                            />
                        </Box>
                    )}
                    <Typography
                        variant="h5"
                        fontFamily={defaultStyles.titleFont}
                        sx={{ mt: { xs: 2, md: 0 }, mb: 2 }}
                    >
                        {tutor?.headline}
                    </Typography>
                    <Typography
                        variant="h6"
                        fontFamily={defaultStyles.titleFont}
                    >
                        About me
                    </Typography>
                    <ShowMoreText
                        more={'Show More'}
                        lines={isMd ? 5 : 3}
                        className="show-more-text"
                    >
                        {tutor.description}
                    </ShowMoreText>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            my: 2,
                        }}
                    >
                        <Typography
                            variant="h6"
                            fontFamily={defaultStyles.titleFont}
                        >
                            My Languages
                        </Typography>
                        <TutredTooltip
                            title="Languages I tutor in"
                            placement="top-end"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    ml: 0.5,
                                }}
                            >
                                <QuestionMarkIcon
                                    color={theme.palette.grey[500]}
                                />
                            </Box>
                        </TutredTooltip>
                    </Box>

                    <Box>
                        {tutor?.languages &&
                            Object.keys(tutor?.languages).map(
                                (language: string, index: number) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <img
                                            src={languageIcons[language]}
                                            alt={language}
                                            style={{ width: 24, height: 24 }}
                                        />
                                        <Typography
                                            key={index}
                                            variant="body1"
                                            fontFamily="Poppins_500Medium"
                                        >
                                            {language}
                                        </Typography>
                                    </Box>
                                )
                            )}
                    </Box>
                    <Typography
                        variant="h6"
                        fontFamily={defaultStyles.titleFont}
                        sx={{ mt: 2, mb: 1 }}
                    >
                        Subjects
                    </Typography>
                    <TutredChipDisplay
                        items={Object.keys(tutor?.subjects || {}).map(
                            (key) => key
                        )}
                        Chip={SubjectChip}
                        size="large"
                    />
                    <Typography
                        variant="h6"
                        fontFamily={defaultStyles.titleFont}
                        sx={{ mt: 2, mb: 1 }}
                    >
                        Specialties
                    </Typography>
                    <TutredChipDisplay
                        items={tutor?.skills || []}
                        Chip={SpecialtyChip}
                        size="large"
                    />
                    {tutor?.examTutor && (
                        <>
                            <Typography
                                variant="h6"
                                fontFamily={defaultStyles.titleFont}
                                sx={{ mt: 2, mb: 1 }}
                            >
                                Exam Prep
                            </Typography>
                            <TutredChipDisplay
                                items={tutor?.examTutor || []}
                                Chip={ExamChip}
                                size="large"
                            />
                        </>
                    )}
                </Box>
                <Sections tutor={tutor} />
                {!isMd && (
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            gap: 1,
                            p: 1,
                            backgroundColor: background,
                            borderTop: '1px solid #D0D0D0',
                        }}
                    >
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                                if (!user) {
                                    openLoginDialog(
                                        'Please log in to send a message'
                                    )
                                }
                                navigateToChat(tutor, extendedUser, profile)
                            }}
                            disableElevation
                            sx={{
                                backgroundColor: primaryLight,
                                borderColor: '#D0D0D0',
                                color: '#000',
                                width: { xs: 42, sm: '100%' },
                                p: 0,
                                pr: 1,
                            }}
                            endIcon={isSm ? <SendIcon size={32} /> : null}
                            size={!isSm ? 'small' : 'medium'}
                        >
                            {isSm ? 'Message' : <SendIcon size={32} />}
                        </Button>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                                if (user) {
                                    if (extendedUser.isTutor) {
                                        customAlert.error(
                                            '[Booking Error] Only students can book sessions!'
                                        )
                                    } else {
                                        setShowBookingDialog(true)
                                    }
                                } else {
                                    openLoginDialog(
                                        'Please log in to book a session'
                                    )
                                    eventEmitter.addListener(
                                        'login-success',
                                        ({ extendedUser }) => {
                                            if (extendedUser.isTutor) {
                                                navigation.navigate('Sessions')
                                            } else {
                                                setShowBookingDialog(true)
                                            }
                                        }
                                    )
                                }
                            }}
                            disableElevation
                            size={!isMd ? 'small' : 'medium'}
                            sx={{
                                color: '#000',
                                textTransform: 'none',
                            }}
                            endIcon={<CheckIcon size={22} />}
                        >
                            Book $
                            {tutor.sessionPricing?.[60]?.rate || tutor?.rate}
                            /hr
                        </Button>
                    </Box>
                )}
            </Box>
        </Card>
    )
}

export default TutorMain
