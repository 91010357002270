import {
    Box,
    Dialog,
    DialogContent,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import OnboardingProgress from './OnboardingProgress'
import { useWindowDimensions } from 'react-native'
import YourDetails from './OnboardingProgress/OnboardingSteps/YourDetails'
import ProfilePhoto from './OnboardingProgress/OnboardingSteps/ProfilePhoto'
import Background from './OnboardingProgress/OnboardingSteps/Background'
import AddYourAvailability from './OnboardingProgress/OnboardingSteps/AddYourAvailability'
import AboutYou from './OnboardingProgress/OnboardingSteps/AboutYou'
import AddYourSubjects from './OnboardingProgress/OnboardingSteps/AddYourSubjects'
import { useColorsContext } from '@utils/styles'
import { useUserContext } from '@contexts/userContext'
import Congratulations from './OnboardingProgress/OnboardingSteps/Congratualations'
import Confetti from 'react-confetti'

interface OnboardingDialogProps {
    visible: boolean
    setVisible: (visible: boolean) => void
}

export type OnboardingStep =
    | 'welcome'
    | 'details'
    | 'photo'
    | 'subjects'
    | 'background'
    | 'availability'
    | 'about'

const OnboardingDialog: React.FC<OnboardingDialogProps> = ({
    visible,
    setVisible,
}) => {
    const [step, setStep] = useState<number>(1)
    const { width, height } = useWindowDimensions()
    const { extendedUser } = useUserContext()
    const [showConfetti, setShowConfetti] = useState(false)
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const contentRef = useRef(null)
    const [confettiWidth, setConfettiWidth] = useState(0)

    useEffect(() => {
        if (extendedUser?.onboarding)
            setStep(extendedUser?.onboarding?.length + 1)
    }, [visible])

    useEffect(() => {
        if (step === 7) {
            setShowConfetti(true)
        }
    }, [step])

    useEffect(() => {
        const handleResize = () => {
            setConfettiWidth(contentRef.current?.clientWidth || 0)
        }
        if (contentRef.current) {
            setConfettiWidth(contentRef.current?.clientWidth || 0)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [contentRef.current])

    return (
        <Dialog
            open={visible}
            onClose={() => {
                setVisible(false)
            }}
            scroll="body"
            maxWidth="xl"
            sx={{ padding: 0, overflow: 'hidden', width: '100%' }}
        >
            <DialogContent
                sx={{
                    width: '100%',
                    overflowX: 'hidden',
                    height: height * 0.8,
                    minHeight: 600,
                    p: 0,
                }}
                ref={contentRef}
            >
                <Confetti
                    run={showConfetti}
                    width={confettiWidth}
                    height={height * 0.8}
                    numberOfPieces={600}
                    recycle={false}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {isMd && (
                        <OnboardingProgress
                            step={step}
                            setVisible={setVisible}
                        />
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                height: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            {step === 1 && (
                                <AddYourSubjects setStep={setStep} />
                            )}
                            {step === 2 && <Background setStep={setStep} />}
                            {step === 3 && <AboutYou setStep={setStep} />}
                            {step === 4 && <YourDetails setStep={setStep} />}
                            {step === 5 && <ProfilePhoto setStep={setStep} />}
                            {step === 6 && (
                                <AddYourAvailability setStep={setStep} />
                            )}
                            {step === 7 && (
                                <Congratulations
                                    onClose={() => {
                                        setVisible(false)
                                    }}
                                    setStep={setStep}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default OnboardingDialog
