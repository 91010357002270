import formatName from '@helpers/formatName'
import { Dialog, DialogTitle } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import { Image, StyleSheet } from 'react-native'
import SelectSession from './Steps/SelectSession'
import { useForm } from 'react-hook-form'
import AddDetails from './Steps/AddDetails'
import ConfirmSession from './Steps/ConfirmSession'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useUserContext } from '@contexts/userContext'
import { useSessionContext } from '@contexts/sessionContext'
import { useAlert } from 'react-alert'
import { PricingOption } from 'dataTypes'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'
import TutredAvatar from '@components/TutredAvatar'

interface BookSessionModalProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    tutor: any
    appointment?: Date
    pricingOptions: MultiSwitchToggleOption[]
    selectedPricingIndex: number
    setSelectedPricingIndex: (index: number) => void
}

export interface BookSessionForm {
    subjects: string[]
    specialties: string[]
    exams: string[]
    note: string
}

const BookSessionModal: React.FC<BookSessionModalProps> = ({
    visible,
    setVisible,
    tutor,
    appointment,
    pricingOptions,
    selectedPricingIndex,
    setSelectedPricingIndex,
}) => {
    const [selectedTime, setSelectedTime] = React.useState(null)
    const [view, setView] = useState(0)
    const [details, setDetails] = useState(null)
    const [loading, setLoading] = useState(false)

    const selectedPricingOption = useMemo(() => {
        return pricingOptions?.[selectedPricingIndex]?.value
    }, [selectedPricingIndex, pricingOptions])

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<BookSessionForm>()

    const resetForm = () => {
        reset()
        setView(0)
        setSelectedTime(null)
        setDetails(null)
    }

    useEffect(() => {
        if (!visible) resetForm()
    }, [visible])

    useEffect(() => {
        if (appointment && selectedPricingOption) {
            setSelectedTime(appointment)
            setView(1)
        }
    }, [appointment, selectedPricingOption])

    return (
        <Dialog
            key={3}
            open={visible}
            onClose={() => {
                setVisible(false)
            }}
            maxWidth="md"
            sx={{ overflow: 'hidden' }}

            // PaperProps={{ style: { borderRadius: '16px' } }}
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 0,
                    mt: 1,
                    mb: 2,
                }}
            >
                <TutredAvatar
                    user={tutor}
                    size="extra-small"
                    sx={{ marginRight: 1 }}
                />
                {/* <Image
                    style={{
                        width: 40,
                        height: 40,
                        borderRadius: 20,
                        marginRight: 16,
                    }}
                    source={{
                        uri: tutor.picture
                            ? tutor.picture
                            : 'https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e',
                    }}
                /> */}
                Book a session with {formatName(tutor.name, false)} ?
            </DialogTitle>

            <SelectSession
                setSelectedTime={setSelectedTime}
                tutor={tutor}
                onSelect={() => {
                    setView(1)
                }}
                view={view}
                selectedPricingIndex={selectedPricingIndex}
                setSelectedPricingIndex={setSelectedPricingIndex}
                pricingOptions={pricingOptions}
                setView={setView}
                setVisible={setVisible}
                selectedTime={selectedTime}
            />

            <AddDetails
                control={control}
                errors={errors}
                handleNext={() => {
                    handleSubmit((data) => {
                        setDetails(data)
                        setView(2)
                    })()
                }}
                tutor={tutor}
                setView={setView}
                view={view}
                selectedTime={selectedTime}
            />

            <ConfirmSession
                tutor={tutor}
                selectedPricingOption={selectedPricingOption}
                selectedTime={selectedTime}
                setVisible={setVisible}
                setView={setView}
                details={details}
                view={view}
                loading={loading}
                setLoading={setLoading}
            />
        </Dialog>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5FCFF',
    },
    button: {
        width: 100,
        height: 40,
        marginLeft: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default BookSessionModal
