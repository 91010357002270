import { provinceToTimeZone } from '@config/provinceToTimeZone'
import { stateToTimeZone } from '@config/stateToTimeZone'
import moment from 'moment-timezone'

export const getCurrentTimeInLocation = (location) => {
    if (!location) return ''
    const timeZone =
        provinceToTimeZone[location] || stateToTimeZone[location] || 'UTC' // Default to UTC if the location is not found
    return moment().tz(timeZone).format('h:mm A')
}
