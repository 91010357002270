import { styled, Slider } from '@mui/material'

const TutredSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
        width: 20,
        height: 20,
        backgroundColor: '#FFF',
        borderRadius: 4,
        border: '1px solid #000000',
    },
    '& .MuiSlider-track': {
        height: 16,
    },
}))

export default TutredSlider
