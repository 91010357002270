import {
    Autocomplete,
    Box,
    Button,
    Card,
    Chip,
    TextField,
    Typography,
} from '@mui/material'
import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import DegreeCard from './DegreeCard'
import AddNewDegreeModal from './AddNewDegreeModal'
import { useColorsContext, useStyles } from '@utils/styles'
import { levels } from '@config/levels'
import TutredBaseChip from '@components/Chips/TutredBaseChip'
import { doc, updateDoc, arrayUnion } from 'firebase/firestore'
import { useUserContext } from '@contexts/userContext'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { Degree } from 'dataTypes'
import ConfirmActionDialog, {
    useConfirmActionDialog,
} from '@hooks/useConfirmAction'

interface EducationProps {
    degrees: Degree[]
    selectedEducationLevels: any[]
    setSelectedEducationLevels: (any) => void
}

{
}

const Education: React.FC<EducationProps> = ({ degrees }) => {
    const [showModal, setShowModal] = React.useState(false)
    const { user } = useUserContext()
    const { db } = useFirebaseContext()
    const { confirmAction, ConfirmActionDialogComponent } =
        useConfirmActionDialog()

    const removeDegree = (id: string) => {
        confirmAction({
            title: 'Delete Degree',
            message: 'Are you sure you want to delete this degree?',
            confirmText: 'Delete',
            onConfirm: () => {
                const docRef = doc(db, 'users', user.uid)
                updateDoc(docRef, {
                    degrees: degrees?.filter((degree) => degree.id !== id),
                })
            },
        })
    }

    return (
        <Box sx={{}}>
            <ConfirmActionDialogComponent />
            <AddNewDegreeModal
                visible={showModal}
                onClose={() => setShowModal(false)}
                handleAddDegree={(degree) => {
                    const docRef = doc(db, 'users', user.uid)
                    updateDoc(docRef, {
                        degrees: arrayUnion(degree),
                    })
                }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5">Degrees</Typography>
                <Button variant="contained" onClick={() => setShowModal(true)}>
                    Add New Degree
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    paddingTop: 2,
                    gap: 2,
                }}
            >
                {degrees?.length > 0 &&
                    degrees.map((degree, index) => {
                        return (
                            <DegreeCard
                                sx={{
                                    width: '100%',
                                    marginBottom: 2,
                                }}
                                degree={degree}
                                key={index}
                                showVerificationOption
                                remove={() => removeDegree(degree.id)}
                            />
                        )
                    })}
            </Box>
        </Box>
    )
}
export default Education
