import { HelpArticleLink } from 'dataTypes'
import { useState, useEffect } from 'react'

const useLinkifyHtml = (html) => {
    const [transformedHtml, setTransformedHtml] = useState('')
    const [links, setLinks] = useState<HelpArticleLink[]>([])

    useEffect(() => {
        if (html) {
            const links = []
            const transformedHtml = html.replace(
                /<h2[^>]*>(.*?)<\/h2>/gi,
                (match, content) => {
                    const text = content.replace(/<.*?>/g, '')

                    const id = text
                        .toLowerCase()
                        .trim()
                        .replace(/[^\w\s-]/g, '')
                        .replace(/[\s]+/g, '-')

                    links.push({
                        title: text,
                        url: `#${id}`,
                    })
                    return `<h2 id="${id}">${content}</h2>`
                }
            )
            setTransformedHtml(transformedHtml)
            setLinks(links)
        }
    }, [html])

    return { html: transformedHtml, links }
}

export default useLinkifyHtml
