import { Box, Button, Typography } from '@mui/material'
import { useStyles } from '@utils/styles'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import { useNavigation } from '@react-navigation/native'
import { useUserContext } from '@contexts/userContext'
import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import CongratulationsIcon from '@icons/CongratulationsIcon'
import { useState } from 'react'
import { View } from 'react-native'

const Congratulations = ({
    onClose,
    setStep,
}: {
    onClose: () => void
    setStep: (step: number) => void
}) => {
    const defaultStyles = useStyles()
    const navigation = useNavigation()
    const { extendedUser } = useUserContext()
    const [iconSize, setIconSize] = useState(0)

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',

                overflow: 'hidden',
            }}
        >
            {/* <OnboardingSectionHeader
                title="Congratulations!"
                subtitle={`Welcome to the platform, ${extendedUser?.name}! Your profile is now complete.`}
                icon={<Typography variant="h1">🎉</Typography>}
            /> */}
            <View
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: 32,
                }}
                // onLayout={({ nativeEvent: { layout } }) => {
                //     setIconSize(layout.width)
                // }}
            >
                <img
                    src={require('@assets/images/onboarding/congrats-1.svg')}
                    alt="Congratulations"
                    style={{ width: 'calc(100% - 64px)', height: 'auto' }}
                />
                <img
                    src={require('@assets/images/onboarding/congrats-2.svg')}
                    alt="Congratulations"
                    style={{ width: 'calc(100% - 64px)', height: 'auto' }}
                />
            </View>

            <OnboardingFooter
                onNext={onClose}
                onPrev={() => {
                    setStep(6)
                }}
                step={7}
            />
        </Box>
    )
}

export default Congratulations
