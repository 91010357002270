import { useColorsContext } from "@utils/styles";

const FilterIcon = ({
    size = 48,
}) => {
    const { text } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17_9)">
                <path d="M34 28H36.0046C36.5543 28 37 28.451 37 28.9908V33.0092C37 33.5564 36.5443 34 36.0046 34H34V36.0088C34 37.1085 33.1123 38 32 38C30.8954 38 30 37.1001 30 36.0088V34H27.9954C27.4457 34 27 33.549 27 33.0092V28.9908C27 28.4436 27.4557 28 27.9954 28H30V11.9912C30 10.8915 30.8877 10 32 10C33.1046 10 34 10.8999 34 11.9912V28ZM18 14H20.0046C20.5543 14 21 14.451 21 14.9908V19.0092C21 19.5564 20.5443 20 20.0046 20H18V36.0088C18 37.1085 17.1123 38 16 38C14.8954 38 14 37.1001 14 36.0088V20H11.9954C11.4457 20 11 19.549 11 19.0092V14.9908C11 14.4436 11.4557 14 11.9954 14H14V11.9912C14 10.8915 14.8877 10 16 10C17.1046 10 18 10.8999 18 11.9912V14ZM0 2.00139C0 0.896052 0.894938 0 2.00139 0H45.9986C47.1039 0 48 0.894938 48 2.00139V45.9986C48 47.1039 47.1051 48 45.9986 48H2.00139C0.896052 48 0 47.1051 0 45.9986V2.00139ZM4 44H44V4H4V44Z" fill={text} />
            </g>
        </svg>
    );
}

export default FilterIcon;