import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import { useUserContext } from '@contexts/userContext'
import {
    Autocomplete,
    Box,
    Chip,
    Divider,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { languageIcons, languages } from '@config/languages'
import {
    specialties,
    subjectIcons,
    subjects as subjectsList,
    subjectData,
} from '@config/subjects'
import { examData } from '@config/exams'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import LanguageChip from '@components/Chips/LanguageChip'
import { useColorsContext, useStyles } from '@utils/styles'
import SubjectChip from '@components/Chips/SubjectChip'
import TutredBaseChip from '@components/Chips/TutredBaseChip'
import Feather from '@expo/vector-icons/Feather'
import SpecialtyIcon from '@icons/SpecialtyIcon'
import ExamPrepIcon from '@icons/ExamPrepIcon'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import SpecialtyChip from '@components/Chips/SpecialtyChip'
import InputIconLabel from '@components/InputIconLabel'
import SubjectIcon from '@icons/SubjectIcon'
import useGetFilterOptions from '@screens/TutorList/TutorListFilters/useGetFilterOptions'
import GroupHeader from '@screens/TutorList/TutorListFilters/GroupHeader'
import GroupItems from '@screens/TutorList/TutorListFilters/GroupItems'
import ExamChip from '@components/Chips/ExamChip'

interface SubjectsForm {
    subjects: string[]
    skills: string[]
    languages: string[]
    examTutor: string[]
}

const AddYourSubjects = ({ setStep }) => {
    const [loading, setLoading] = useState(false)
    const { extendedUser, user } = useUserContext()
    const { db } = useFirebaseContext()
    const { darkMode, text, primaryExtraLight } = useColorsContext()
    const filterOptions = useGetFilterOptions()

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<SubjectsForm>()
    const defaultStyles = useStyles()
    const { subjects, skills } = watch()
    const theme = useTheme()

    useEffect(() => {
        const existingSubjects = extendedUser?.subjects
            ? Object.keys(extendedUser?.subjects).map((key) => key)
            : []
        setValue('subjects', existingSubjects)
    }, [])

    const existingExams = useMemo(() => {
        let existing = []
        if (extendedUser?.examTutor) {
            extendedUser?.examTutor.forEach((userExam) => {
                const exams = Object.keys(examData).flatMap((key) => {
                    return examData[key]
                })
                const exam = exams.find((exam) => userExam === exam.label)
                if (exam) {
                    existing.push({
                        id: exam.label,
                        label: exam.label,
                    })
                }
            })
        }
        return existing
    }, [extendedUser?.examTutor])

    const extistingSpecialties = useMemo(() => {
        let existing = []
        if (extendedUser?.skills) {
            extendedUser?.skills.forEach((userSkill) => {
                const skills = Object.keys(specialties).flatMap((key) => {
                    return specialties[key]
                })
                const skill = skills.find((skill) => userSkill === skill)
                if (skill) {
                    existing.push({
                        id: skill,
                        label: skill,
                    })
                }
            })
        }
        return existing
    }, [extendedUser?.skills])

    const handleSave = (data) => {
        const languages = {}
        data.languages.forEach((language) => {
            languages[language] = true
        })
        const subjects = {}
        data.subjects.forEach((subject) => {
            subjects[subject] = true
        })
        const exams = []
        data.examTutor.forEach((exam) => {
            exams.push(exam.id)
        })
        const skills = []
        data.skills.forEach((skill) => {
            skills.push(skill.id)
        })
        updateDoc(doc(db, 'users', user.uid), {
            skills: skills,
            languages: languages,
            subjects: subjects,
            examTutor: exams,
            onboarding: arrayUnion('subjects'),
        })

        setStep(2)
    }

    // Remove any skills that don't belong to selected subjects
    useEffect(() => {
        const selectedSkills = skills || []
        const selectedSubjects = subjects || []

        // Get all valid specialties for selected subjects
        const validSpecialties = []
        selectedSubjects.forEach((subject) => {
            if (specialties[subject]) {
                validSpecialties.push(...specialties[subject])
            }
        })

        // Filter out skills that aren't in valid specialties
        const validSkills = selectedSkills.filter((skill) => {
            return validSpecialties.some(
                (specialty) => specialty === skill.label
            )
        })

        // Update form if any skills were removed
        if (validSkills.length !== selectedSkills.length) {
            setValue('skills', validSkills)
        }
    }, [subjects, skills])

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {/* header */}
            <OnboardingSectionHeader
                title="Subjects"
                subtitle="Tell students what you teach. List the subjects, and specialties you offer to help learners find the perfect match for their needs."
                icon={<SubjectIcon size={28} />}
            />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    pl: { xs: 0, md: 2 },
                    pr: { xs: 0, md: 0.5 },
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: primaryExtraLight,
                        borderRadius: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Box sx={styles.inputContainer}>
                        <InputIconLabel
                            icon={<SubjectIcon size={20} />}
                            label="Subjects"
                            description="Choose up to 6 subjects you want to teach."
                        />

                        <Controller
                            name="subjects"
                            control={control}
                            rules={{
                                required: true,
                                validate: (value) => {
                                    if (value?.length > 6)
                                        return 'You can only select up to 6 subjects'
                                },
                            }}
                            defaultValue={
                                extendedUser?.subjects
                                    ? Object.keys(extendedUser?.subjects).map(
                                          (key) => key
                                      )
                                    : []
                            }
                            render={({ field: { ref, ...field } }) => (
                                <Autocomplete
                                    size="small"
                                    multiple
                                    options={subjectsList}
                                    getOptionLabel={(option) => option}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                            const { key, ...tagProps } =
                                                getTagProps({
                                                    index,
                                                })
                                            return (
                                                <SubjectChip
                                                    key={key}
                                                    item={option}
                                                    {...tagProps}
                                                />
                                            )
                                        })
                                    }
                                    sx={{
                                        '& .Mui-Autocomplete-root': {
                                            zIndex: 9999,
                                        },
                                    }}
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                key={option}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    p: 1,
                                                }}
                                                {...props}
                                            >
                                                <Box
                                                    sx={{
                                                        width: 28,
                                                        height: 28,
                                                        backgroundColor:
                                                            darkMode
                                                                ? theme.palette
                                                                      .grey[400]
                                                                : theme.palette
                                                                      .grey[800],
                                                        WebkitMask: `url(${subjectIcons[option]}) no-repeat center `,
                                                        mask: `url(${subjectIcons[option]}) no-repeat center `,
                                                        maskSize: 'contain',
                                                        WebkitMaskSize:
                                                            'contain',
                                                        mr: 2,
                                                    }}
                                                />
                                                <Typography>
                                                    {option}
                                                </Typography>
                                            </Box>
                                        )
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            inputRef={ref}
                                            error={!!errors?.subjects}
                                            placeholder="Select Subject(s)"
                                            helperText={
                                                errors?.subjects?.message
                                            }
                                            {...params}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                    <Divider
                        variant="middle"
                        color={text}
                        sx={{ mt: 1.5, mb: 3 }}
                    />
                    <Box sx={styles.inputContainer}>
                        <InputIconLabel
                            icon={
                                <MaterialIcons
                                    name="translate"
                                    size={22}
                                    color={text}
                                />
                            }
                            label="Languages"
                            description="Choose the languages you can fluently teach in. You can choose up to 6."
                        />
                        <Controller
                            name="languages"
                            control={control}
                            rules={{
                                required: true,
                                validate: (value) => {
                                    if (value?.length > 6)
                                        return 'You can only select up to 6 languages'
                                },
                            }}
                            defaultValue={
                                extendedUser?.languages
                                    ? Object.keys(extendedUser?.languages).map(
                                          (key) => key
                                      )
                                    : []
                            }
                            render={({ field: { ref, ...field } }) => (
                                <Autocomplete
                                    size="small"
                                    multiple
                                    value={field.value}
                                    options={languages}
                                    getOptionLabel={(option) => option}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                            const { key, ...tagProps } =
                                                getTagProps({
                                                    index,
                                                })
                                            return (
                                                <LanguageChip
                                                    key={key}
                                                    language={option}
                                                    {...tagProps}
                                                />
                                            )
                                        })
                                    }
                                    sx={{
                                        '& .Mui-Autocomplete-root': {
                                            zIndex: 9999,
                                        },
                                    }}
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    p: 1,
                                                }}
                                                {...props}
                                            >
                                                <img
                                                    src={languageIcons[option]}
                                                    style={{
                                                        width: 32,
                                                        height: 32,
                                                        marginRight: 16,
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                                <Typography>
                                                    {option}
                                                </Typography>
                                            </Box>
                                        )
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            inputRef={ref}
                                            error={!!errors?.languages}
                                            helperText={
                                                errors?.languages?.message
                                            }
                                            placeholder="Select Language(s)"
                                            {...params}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                    <Divider
                        variant="middle"
                        color={text}
                        sx={{ mt: 1.5, mb: 3 }}
                    />
                    <Box sx={styles.inputContainer}>
                        <InputIconLabel
                            icon={<SpecialtyIcon size={20} />}
                            label="Specialty"
                            optional={true}
                            description="Choose the specialties for the subjects you teach."
                        />
                        <Controller
                            name="skills"
                            control={control}
                            defaultValue={extistingSpecialties}
                            rules={{
                                validate: (value) => {
                                    if (value?.length > 10)
                                        return 'You can only select up to 10 specialties'
                                },
                            }}
                            render={({ field: { ref, ...field } }) => (
                                <Autocomplete
                                    size="small"
                                    multiple
                                    // value={Object.keys(extendedUser?.languages).map(
                                    //     (key) => key
                                    // )}

                                    options={filterOptions(
                                        'Specialties',
                                        subjectData,
                                        subjects
                                    )}
                                    groupBy={(option) => {
                                        return option?.group
                                    }}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            {params.group && (
                                                <GroupHeader
                                                    title={params.group}
                                                />
                                            )}
                                            <GroupItems
                                                children={params.children}
                                                filter="Specialties"
                                            />
                                        </li>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                            const { key, ...tagProps } =
                                                getTagProps({
                                                    index,
                                                })
                                            return (
                                                <SpecialtyChip
                                                    key={key}
                                                    item={option.label}
                                                    {...tagProps}
                                                />
                                            )
                                        })
                                    }
                                    sx={{
                                        '& .Mui-Autocomplete-root': {
                                            zIndex: 9999,
                                        },
                                    }}
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            inputRef={ref}
                                            error={!!errors?.skills}
                                            placeholder="Select Specialties"
                                            helperText={errors?.skills?.message}
                                            {...params}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                    <Divider
                        variant="middle"
                        color={text}
                        sx={{ mt: 1.5, mb: 3 }}
                    />
                    <Box sx={styles.inputContainer}>
                        <InputIconLabel
                            icon={<ExamPrepIcon size={20} style={{}} />}
                            label="Exam Prep"
                            description="Choose the standardized tests you are qualified to help students prepare for."
                            optional={true}
                        />
                        <Controller
                            name="examTutor"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    if (value?.length > 6)
                                        return 'You can only select up to 6 exams'
                                },
                            }}
                            defaultValue={existingExams}
                            render={({ field: { ref, ...field } }) => (
                                <Autocomplete
                                    {...field}
                                    size="small"
                                    multiple
                                    options={filterOptions(
                                        'Exams',
                                        examData,
                                        []
                                    )}
                                    groupBy={(option) => {
                                        return option?.group
                                    }}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            {params.group && (
                                                <GroupHeader
                                                    title={params.group}
                                                />
                                            )}
                                            <GroupItems
                                                children={params.children}
                                                filter="Exams"
                                            />
                                        </li>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                            const { key, ...tagProps } =
                                                getTagProps({
                                                    index,
                                                })
                                            return (
                                                <ExamChip
                                                    key={key}
                                                    item={option.label}
                                                    {...tagProps}
                                                />
                                            )
                                        })
                                    }
                                    sx={{
                                        '& .Mui-Autocomplete-root': {
                                            zIndex: 9999,
                                        },
                                    }}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            inputRef={ref}
                                            error={!!errors?.examTutor}
                                            helperText={
                                                errors?.examTutor?.message
                                            }
                                            placeholder="Select exam(s)"
                                            {...params}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>

            <OnboardingFooter
                step={1}
                onNext={() => {
                    handleSubmit(handleSave)()
                }}
                loading={loading}
            />
        </Box>
    )
}

const styles = StyleSheet.create({
    inputContainer: {
        marginBottom: 2,
    },
})

export default AddYourSubjects
