import React, { useEffect } from 'react'
import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import { Box } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useState } from 'react'
import { useUserContext } from '@contexts/userContext'
import {
    convertAvailabilityTime,
    getMinutesForTime,
} from '@helpers/convertAvailabilityTime'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import CalendarIcon from '@icons/CalendarIcon'
import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import AvailabilityListView from './AvailabilityListView'
import AvailabilityCalendarView from './AvailabilityCalendarView'
import { useAlert } from 'react-alert'
import { arrayUnion, updateDoc } from 'firebase/firestore'
import { getDocs, query, collection, where } from 'firebase/firestore'
import { useFirebaseContext } from '@contexts/firebaseContext'

const AddYourAvailability = ({ setStep }) => {
    const { extendedUser } = useUserContext()
    const defaultStyles = useStyles()
    const alert = useAlert()
    const { text, primaryExtraLight } = useColorsContext()
    const [view, setView] = useState<number>(0)
    const { user } = useUserContext()
    const [availability, setAvailability] = useState<any>({
        sunday: null,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
    })
    const [errors, setErrors] = useState<any>({})
    const { db } = useFirebaseContext()

    useEffect(() => {
        // Check if any day has end time before start time
        const newErrors = {}
        Object.keys(availability).forEach((day) => {
            if (availability[day]) {
                const { start, end } = availability[day]
                if (start && end) {
                    const startMinutes = getMinutesForTime(start)
                    const endMinutes = getMinutesForTime(end)
                    if (startMinutes >= endMinutes) {
                        newErrors[day] = true
                    }
                }
            }
        })
        setErrors(newErrors)
    }, [availability])

    useEffect(() => {
        if (availability && extendedUser?.availability) {
            setAvailability((prev) => {
                Object.keys(prev).forEach((key) => {
                    if (!extendedUser?.availability?.[key]) {
                        prev[key] = null
                    } else {
                        prev[key] = {
                            start: convertAvailabilityTime(
                                extendedUser?.availability?.[key].start,
                                'in'
                            ),
                            end: convertAvailabilityTime(
                                extendedUser?.availability?.[key].end,
                                'in'
                            ),
                        }
                    }
                })
                return prev
            })
        }
    }, [])

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <OnboardingSectionHeader
                title="Schedule"
                subtitle="Set your availability. Let learners know when they can book sessions with you by customizing your schedule"
                icon={<CalendarIcon size={32} color={text} />}
            />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    pl: { xs: 0, md: 2 },
                    pr: { xs: 0, md: 0.5 },
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: primaryExtraLight,
                        borderRadius: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <MultiSwitchToggle
                            options={[
                                { label: 'List view', value: 'list' },
                                {
                                    label: 'Calendar view',
                                    value: 'calendar',
                                    disabled: true,
                                    disabledMessage:
                                        'This feature is coming soon',
                                },
                            ]}
                            selectedIndex={view}
                            setSelectedIndex={(value) => {
                                setView(value)
                            }}
                        />
                    </Box>

                    {view === 0 && (
                        <AvailabilityListView
                            errors={errors}
                            availability={availability}
                            setAvailability={setAvailability}
                        />
                    )}
                    {view === 1 && (
                        <AvailabilityCalendarView
                            availability={availability}
                            setAvailability={setAvailability}
                        />
                    )}
                </Box>
            </Box>

            <OnboardingFooter
                step={6}
                onNext={async () => {
                    const tempAvailability = {}
                    if (Object.keys(errors).length > 0) {
                        alert.error(
                            '[Availability Error] Start time must be before end time'
                        )
                        return
                    }
                    const hasAnyDaySet = Object.values(availability).some(
                        (value) => value !== null
                    )
                    if (!hasAnyDaySet) {
                        alert.error(
                            '[Availability Error] Please set availability for at least one day'
                        )
                        return
                    }

                    Object.keys(availability).forEach((key) => {
                        if (availability[key]) {
                            const start = availability[key].start
                            const end = availability[key].end

                            tempAvailability[key] = {
                                start: convertAvailabilityTime(
                                    start,
                                    'out',
                                    key
                                ),
                                end: convertAvailabilityTime(end, 'out', key),
                            }
                        } else {
                            tempAvailability[key] = null
                            setErrors((prev) => ({
                                ...prev,
                                [key]: false,
                            }))
                        }
                    })

                    const userRef = collection(db, 'users')
                    const q = query(userRef, where('uid', '==', user.uid))
                    const querySnapshot = await getDocs(q)
                    querySnapshot.forEach((doc) => {
                        updateDoc(doc.ref, {
                            availability: tempAvailability,
                            onboarding: arrayUnion('availability'),
                            isOnboarded: true,
                        })
                    })

                    setStep(7)
                }}
                onPrev={() => {
                    setStep(5)
                }}
            />
        </Box>
    )
}

export default AddYourAvailability
