import { useState } from 'react'
import { ArticleListing } from './ArticleListing'
import { Typography } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'

interface ArticleListingsProps {
    articles: Array<{
        title: string
        text?: string
        slug: string
    }>
}

export const ArticleListings: React.FC<ArticleListingsProps> = ({
    articles,
}) => {
    const defaultStyles = useStyles()
    const { primary } = useColorsContext()
    const [collapse, setCollapse] = useState(true)
    const leftToShow = articles?.length - 3
    const limitLength = collapse ? 3 : articles?.length

    return (
        <>
            {articles.slice(0, limitLength).map((article) => (
                <ArticleListing key={article.slug} article={article} />
            ))}
            {leftToShow > 0 && (
                <Typography
                    onClick={() => {
                        setCollapse(!collapse)
                    }}
                    fontFamily={defaultStyles.titleFont}
                    sx={{ color: primary, cursor: 'pointer' }}
                >
                    {collapse
                        ? `Show all ${articles.length} articles`
                        : 'Show Less'}
                </Typography>
            )}
        </>
    )
}
