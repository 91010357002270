import TutredTooltip from '@components/TutredTooltip'
import { HelpOutline } from '@mui/icons-material'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { ReactNode } from 'react'

interface InvoiceLineItemProps {
    icon: ReactNode
    label: string
    amount: number
    tooltip?: string
}

const InvoiceLineItem: React.FC<InvoiceLineItemProps> = ({
    icon,
    label,
    amount,
    tooltip,
}) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const defaultStyles = useStyles()
    const { text } = useColorsContext()

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: 22,
                        height: 22,
                    }}
                >
                    {icon}
                </Box>
                <Typography
                    variant={isMd ? 'body1' : 'body2'}
                    sx={{
                        marginLeft: 2,
                        fontFamily: defaultStyles.titleFont,
                    }}
                >
                    {label}:{' '}
                </Typography>
                {tooltip && (
                    <TutredTooltip title="Includes taxes and our service fee.">
                        <HelpOutline color={text} sx={{ marginLeft: 1 }} />
                    </TutredTooltip>
                )}
            </Box>
            <Typography
                variant={isMd ? 'body1' : 'body2'}
                sx={{
                    fontFamily: defaultStyles.regularFont,
                }}
            >
                ${amount.toFixed(2)}
            </Typography>
        </Box>
    )
}

export default InvoiceLineItem
