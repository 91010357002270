import { onAuthStateChanged } from "firebase/auth";
import {
	collection,
	doc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useFirebaseContext } from "./firebaseContext";
import mixpanel from 'mixpanel-browser'

const hostname = process.env.HOSTNAME

const userContext = createContext();

export const UserContextProvider = ({ children }) => {
	const customAlert = useAlert();
	const [user, setUser] = useState(null);
	// The majority of the state in this context should have been added to the user object. However I didnt want to go through the process of updating all the components that use this context.
	// extendedUser contains all of the data from the "user document" in firestore.
	const [extendedUser, setExtendedUser] = useState(null);
	const [name, setName] = useState("");
	const [userId, setUserId] = useState("");
	const [profile, setProfile] = useState("");
	const [birthday, setBirthday] = useState("");
	const [chats, setChats] = useState([]);
	const [isTutor, setIsTutor] = useState(null);
	const [customerId, setCustomerId] = useState("");
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [isVerified, setIsVerified] = useState(false);
	const [isSignedUp, setIsSignedUp] = useState(null);
	const { db, auth } = useFirebaseContext();
	const [loadedUser, setLoadedUser] = useState(false);
	const [showOnboarding, setShowOnboarding] = useState(false);
	const [isStripeConnected, setIsStripeConnected] = useState(false);
	const [showStripeOnboarding, setShowStripeOnboarding] = useState(false);
	const [accountId, setAccountId] = useState("");
	const [isOnboarded, setIsOnboarded] = useState(false);
	const [instaBookLength, setInstaBookLength] = useState(15);
	const [instaBookMinimum, setInstaBookMinimum] = useState(1);
	const [instaBookExtension, setinstaBookExtension] = useState(15);
	const [instaBook, setInstaBook] = useState(false);
	const [deviceId, setDeviceId] = useState(null);
	const [URL, setURL] = useState("");
	const [superTutor, setSuperTutor] = useState(false);
	const [performance, setPerformance] = useState(null);
	const [favourites, setFavourites] = useState([]);
	const [rate, setRate] = useState(0);
	const [bufferPeriod, setBufferPeriod] = useState(0);
	const [preLiminaryWindow, setpreLiminaryWindow] = useState(0);
	const [instaBookBufferPeriod, setInstaBookBufferPeriod] = useState(0);
	const [instaBookAdvanceNotice, setInstaBookAdvanceNotice] = useState(0);
	const [advanceNotice, setAdvanceNotice] = useState(0);
	const [minlength, setMinlength] = useState(0);
	const [maxlength, setMaxlength] = useState(0);
	const [minfee, setMinfee] = useState(0);
	const [autoAccept, setAutoAccept] = useState(false);
	const [availability, setAvailability] = useState(null);
	const [alwaysAvailable, setAlwaysAvailable] = useState(false);
	const [unavailable, setUnavailable] = useState(false);
	const [unreadMessages, setUnreadMessages] = useState([]);
	const [chatsIdArray, setChatsIdArray] = useState([]);
	const [notificationPreferences, setNotificationPreferences] = useState({});
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (db) {
			const unsubscribeFromAuthStatuChanged = onAuthStateChanged(
				auth,
				async (user) => {
					if (user && !loadedUser) {
						setLoadedUser(true);
						// User is signed in, see docs for a lixst of available properties
						// https://firebase.google.com/docs/reference/js/firebase.User

						// Generate a Device ID or check local storage for existing one
						let deviceId = localStorage.getItem("deviceId");
						if (!deviceId) {
							deviceId = Math.random()
								.toString(36)
								.substring(2, 15);
							localStorage.setItem("deviceId", deviceId);
						}

						let userRef = doc(db, "users", user.uid);

						updateDoc(userRef, {
							lastOnline: serverTimestamp(),
							deviceId: deviceId,
						}).catch((error) => { });

						const q = query(
							collection(db, "users"),
							where("uid", "==", user.uid)
						);
						const unsubscribe = onSnapshot(q, (snapshot) => {
							if (snapshot.empty) {
								// customAlert.error('User not found');
								// Sign out user
								// auth.signOut();
								// window.location.reload();
								// return;
							}
							snapshot.forEach((doc) => {

								// Check if user exists
								if (!doc.exists()) {
									customAlert.error("No such document!");
								} else {
									setDeviceId(
										doc.data().deviceId
											? doc.data().deviceId
											: null
									);
									setBirthday(
										doc.data().birthday
											? doc.data().birthday
											: ""
									);
									setUser(user);
									setExtendedUser(doc.data());
									setName(doc.data().name);
									setUserId(doc.data().uid);
									setProfile(doc.data().picture);
									setCustomerId(
										doc.data().customerId
											? doc.data().customerId
											: ""
									);
									if (doc.data().isTutor) {
										if (!performance) {
											calculatePerformance(doc.data());
										}
										setSuperTutor(
											doc.data().superTutor
												? doc.data().superTutor
												: false
										);
										setURL(doc.data().URL);
										setInstaBook(doc.data().instaBook);
										setInstaBookLength(
											doc.data().instaBookLength
												? doc.data().instaBookLength
												: 15
										);
										setinstaBookExtension(
											doc.data().instaBookExtension ? doc.data().instaBookExtension : 15
										)
										setInstaBookMinimum(
											doc.data().instaBookMinimum
												? doc.data().instaBookMinimum
												: 1
										);
										setRate(
											doc.data().rate
												? doc.data().rate
												: 0
										);
										setBufferPeriod(
											doc.data().bufferPeriod
												? doc.data().bufferPeriod
												: "5"
										);
										setpreLiminaryWindow(
											doc.data().preLiminaryWindow
												? doc.data().preLiminaryWindow
												: "5"
										)
										setAdvanceNotice(
											doc.data().advanceNotice
												? doc.data().advanceNotice
												: "5"
										);
										setInstaBookAdvanceNotice(
											doc.data().instaBookAdvanceNotice
												? doc.data().instaBookAdvanceNotice
												: "5"
										);
										setInstaBookBufferPeriod(
											doc.data().instaBookBufferPeriod
												? doc.data().instaBookBufferPeriod
												: "5"
										);
										setMinlength(
											doc.data().minlength
												? doc.data().minlength
												: 30
										);
										setMaxlength(
											doc.data().maxlength
												? doc.data().maxlength
												: 120
										);
										setMinfee(
											doc.data().minfee
												? doc.data().minfee
												: 0
										);
										setAutoAccept(
											doc.data().autoAccept
												? doc.data().autoAccept
												: false
										);
										setAvailability(
											doc.data().availability
												? doc.data().availability
												: null
										);
										setAlwaysAvailable(
											doc.data().alwaysAvailable
												? doc.data().alwaysAvailable
												: false
										);
										setUnavailable(
											doc.data().unavailable
												? doc.data().unavailable
												: false
										);
										setIsTutor(true);
										setIsSignedUp(doc.data().isSignedUp);
										setAccountId(
											doc.data().stripe &&
												doc.data().stripe.accountId
												? doc.data().stripe.accountId
												: ""
										);
										setIsStripeConnected(
											doc.data().stripe &&
												doc.data().stripe.confirmed
												? true
												: false
										);
									} else {
										setURL(doc.data().URL);
										setIsTutor(false);
										setIsSignedUp(false);
										setIsStripeConnected(false);
										setFavourites(
											doc.data().favourites
												? doc.data().favourites
												: []
										);
									}

									if (doc.data().isVerified) {
										setIsVerified(true);
									} else {
										setIsVerified(false);
									}
									getChats(user.uid, doc.data().isTutor);
								}
							});
							setLoading(false);
						});


						return unsubscribe;
					} else {
						setLoading(false);
						// User is signed out
						setUser(null);
						setName("");
						setProfile("");
						setChats([]);
						setLoadedUser(false);
						setIsTutor(false);
						setIsVerified(false);
						setIsSignedUp(false);
						setIsStripeConnected(false);
					}
				}
			);
			return unsubscribeFromAuthStatuChanged;
		}
	}, [db]);

	// useEffect(() => {
	// 	// If device ID is different then local log the user out
	// 	if (deviceId) {
	// 		if (deviceId !== localStorage.getItem("deviceId")) {
	// 			// Alert the user that they are logged out
	// 			alert("You have logged in from another device.");
	// 			auth.signOut();
	// 			// window.location.reload();
	// 			// Redirect to home page
	// 			// window.location.href = '/';
	// 		}
	// 	}
	// }, [deviceId]);

	const getNotificationPreferences = async () => {
		if (user) {
			const q = query(
				collection(db, "users"),
				where("uid", "==", user.uid)
			);

			const unsubscribe = onSnapshot(q, (snapshot) => {
				if (snapshot.empty) {
					console.log("No matching documents.");
					return;
				}

				snapshot.forEach((document) => {
					if (!document.exists()) {
						console.log("No such document!");
						return;
					}

					const retrievedPreferences =
						document.data().notificationPreferences;

					if (retrievedPreferences) {
						setNotificationPreferences(retrievedPreferences);

						// console.log("[userContext] User notifications found...", retrievedPreferences);
					} else {
						const userRef = doc(db, "users", user.uid);

						const defaultPreferences = {
							newMessage: true,
							newBooking: true,
							bookingReminder: true,
							bookingCancellation: true,
							bookingConfirmation: true,
							bookingCompleted: true,
							bookingDeclined: true,
							bookingRequest: true,
							bookingRescheduled: true,
							bookingUpdated: true,
							accountUpdates: true,
							offersAndUpdates: true,
						};

						updateDoc(userRef, {
							notificationPreferences: defaultPreferences,
						})
							.then(() => {
								console.log(
									"Default notification preferences set in firestore"
								);
								setNotificationPreferences(defaultPreferences);
							})
							.catch((error) => {
								console.log(
									"Error setting default notification preferences:",
									error
								);
							});
					}
				});
			});

			return unsubscribe;
		}
	};

	useEffect(() => {
		if (user && extendedUser) {
			if (process.env.mode === 'production') {
				mixpanel.identify(user.uid);
				mixpanel.people.set({
					$name: extendedUser.name,
					$email: user.email,
				});
			}

			getNotificationPreferences();
		}
	}, [user]);

	// Get payment methods for user
	const getPaymentMethods = async () => {
		console.log('Fetching payment methods...');
		try {
			const res = await fetch(`${hostname}/api/${customerId}/payment-methods`, {
				method: "GET",
				headers: {
					Authorization: "Bearer " + user.accessToken,
					"Content-Type": "application/json",
				},
			});
			const data = await res.json();
			console.log('Payment methods fetched:', data);

			if (data.success && data.paymentMethods) {
				setPaymentMethods(data.paymentMethods);
			} else {
				console.error('Failed to fetch payment methods:', data.message);
			}
		} catch (error) {
			console.error('Error fetching payment methods:', error);
		}
	};

	useEffect(() => {
		if (user && customerId) {
			getPaymentMethods();
		}
	}, [user, customerId])
	// Trigger onboarding
	useEffect(() => {
		if (isTutor && !isSignedUp) {
			getOnboarding(user.uid);
			setShowOnboarding(true);
		} else if (isTutor && isSignedUp) {
			setShowOnboarding(false);
		}
	}, [isSignedUp, isTutor]);

	// Trigger stripe onboarding
	useEffect(() => {
		if (isTutor && isSignedUp && !isStripeConnected) {
			setShowStripeOnboarding(true);
		} else if (isTutor && isSignedUp && isStripeConnected) {
			setShowStripeOnboarding(false);
		}
	}, [isSignedUp, isTutor, isStripeConnected]);

	// if (showOnboarding) {
	//   return <Onboarding user={user}> </Onboarding>;
	// }

	if (showStripeOnboarding && accountId) {
		// return <StripeOnboarding
		//   email={user.email}
		//   accountId={accountId}
		// ></StripeOnboarding>;
	}

	// Get chats
	const getChats = async function (uid, userIsTutor) {
		try {
			const chatsRef = collection(db, "chats");
			var chatsQuery;
			if (userIsTutor) {
				chatsQuery = query(
					chatsRef,
					where("tutor.uid", "==", uid),
					limit(25),
					orderBy("lastUpdated", "desc")
				);
			} else {
				chatsQuery = query(
					chatsRef,
					where("user.uid", "==", uid),
					limit(25),
					orderBy("lastUpdated", "desc")
				);
			}
			const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
				var chats = [];
				snapshot.forEach((doc) => {
					chats.push({
						id: doc.id,
						...doc.data(),
					});
				});

				// Sort chats by last message
				chats.sort((a, b) => {
					if (a.lastMessage && b.lastMessage) {
						return (
							b.lastMessage.createdAt - a.lastMessage.createdAt
						);
					} else {
						return 0;
					}
				});

				setChats(chats);
			});

			return unsubscribe;
		} catch { }
	};

	// Get isOnboarded
	const getOnboarding = async function (uid) {
		const unsubscribe = onSnapshot(
			doc(db, "users", uid),
			(doc) => {
				if (doc.exists()) {
					setIsOnboarded(doc.data().isOnboarded);
				} else {
				}
			},
			(error) => { }
		);
		return unsubscribe;
	};

	// Create tutor performance
	const calculatePerformance = async function (tutor) {
		// Check if isVerified
		var performance = {
			isVerified: false,
			totalRatings: 0,
			fiveStarRatings: 0,
			totalSessions: 0,
			sessionResponses: 0,
			completedSessions: 0,
		};

		// Check if user is verified
		if (tutor.isVerified) {
			performance.isVerified = true;
		}

		// Get all sessions
		const sessionsRef = collection(db, "sessions");
		const sessionsQuery = query(
			sessionsRef,
			where("tutor", "==", tutor.uid)
		);

		var allSessions = await getDocs(sessionsQuery).then((querySnapshot) => {
			return querySnapshot.docs.map((doc) => {
				return doc.data();
			});
		});

		// Iterate through all sessions and add all that are not pending to sessionResponses
		allSessions.forEach((session) => {
			if (session.status !== "pending") {
				performance.sessionResponses += 1;
			}

			if (session.status === "completed") {
				performance.completedSessions += 1;
			}
		});

		performance.totalSessions = allSessions.length;

		// Get all reviews
		const reviewsRef = collection(db, "reviews");
		const reviewsQuery = query(reviewsRef, where("tutor", "==", tutor.uid));

		var allReviews = await getDocs(reviewsQuery).then((querySnapshot) => {
			return querySnapshot.docs.map((doc) => {
				return doc.data();
			});
		});

		// Iterate through all reviews and add all 5 star ratings to fiveStarRatings
		allReviews.forEach((review) => {
			if (review.rating === 5) {
				performance.fiveStarRatings += 1;
			}
		});

		performance.totalRatings = allReviews.length;

		// Check if we have to update the tutor to superTutor
		// 10+ completed sessions
		// 95%+ session responses
		// 95%+ 5 star ratings

		if (
			performance.isVerified &&
			performance.compeltedSessions >= 10 &&
			performance.sessionResponses / performance.totalSessions >= 0.9 &&
			performance.fiveStarRatings / performance.totalRatings >= 0.9
		) {
			if (!tutor.superTutor) {
				// Set superTutor to true
				let userRef = doc(db, "users", tutor.uid);
				updateDoc(userRef, {
					superTutor: true,
				}).catch((error) => { });
			}
		} else {
			if (tutor.superTutor) {
				// Set superTutor to true
				let userRef = doc(db, "users", tutor.uid);
				updateDoc(userRef, {
					superTutor: false,
				}).catch((error) => { });
			}
		}

		setPerformance(performance);
	};


	// Toggle notification preferences
	const toggleNotificationPreferences = async (type) => {
		let userRef = doc(db, "users", userId);
		let newNotificationPreferences = notificationPreferences;

		if (notificationPreferences[type]) {
			newNotificationPreferences[type] = false;
		} else {
			newNotificationPreferences[type] = true;
		}

		updateDoc(userRef, {
			notificationPreferences: newNotificationPreferences,
		}).catch((error) => { });

		setNotificationPreferences(newNotificationPreferences);
	};

	const turnOffInstabook = () => {
		updateDoc(doc(db, "users", user.uid), {
			instaBook: false,
		})
	}

	const signOut = async () => {
		try {
			await auth.signOut();
			setUser(null);
			setExtendedUser(null);
			setName("");
			setUserId("");
			setProfile("");
			setBirthday("");
			setChats([]);
			setIsTutor(null);
			setCustomerId("");
			setPaymentMethods([]);
			setIsVerified(false);
			setIsSignedUp(null);
			setLoadedUser(false);
			setShowOnboarding(false);
			setIsStripeConnected(false);
			setShowStripeOnboarding(false);
			setAccountId("");
			setIsOnboarded(false);
			setInstaBookLength(15);
			setInstaBookMinimum(1);
			setinstaBookExtension(15);
			setInstaBook(false);
			setDeviceId(null);
			setURL("");
			setSuperTutor(false);
			setPerformance(null);
			setFavourites([]);
			setRate(0);
			setBufferPeriod(0);
			setpreLiminaryWindow(0);
			setInstaBookBufferPeriod(0);
			setInstaBookAdvanceNotice(0);
			setAdvanceNotice(0);
			setMinlength(0);
			setMaxlength(0);
			setMinfee(0);
			setAutoAccept(false);
			setAvailability(null);
			setAlwaysAvailable(false);
			setUnavailable(false);
			setUnreadMessages([]);
			setChatsIdArray([]);
			setNotificationPreferences({});
			if (process.env.mode === 'production')
				mixpanel.reset();

		} catch (error) {
			console.log("Error signing out:", error);
		}
	}

	return (
		<userContext.Provider
			value={{
				customerId,
				paymentMethods,
				getPaymentMethods,
				isSignedUp,
				setIsSignedUp,
				isTutor,
				isVerified,
				chats,
				user,
				name,
				profile,
				userId,
				accountId,
				instaBook,
				instaBookLength,
				instaBookMinimum,
				instaBookExtension,
				showStripeOnboarding,
				isStripeConnected,
				isOnboarded,
				birthday,
				URL,
				performance,
				superTutor,
				favourites,
				rate,
				minfee,
				bufferPeriod,
				preLiminaryWindow,
				instaBookBufferPeriod,
				instaBookAdvanceNotice,
				advanceNotice,
				minlength,
				maxlength,
				autoAccept,
				availability,
				alwaysAvailable,
				unavailable,
				setUnreadMessages,
				unreadMessages,
				notificationPreferences,
				toggleNotificationPreferences,
				loading,
				extendedUser,
				signOut,
			}}
		>
			{children}
		</userContext.Provider>
	);
};

export const useUserContext = () => {
	return useContext(userContext);
};
