import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import React, { useState, useCallback } from 'react'

interface ConfirmActionDialogProps {
    title: string
    message: string
    confirmText?: string
    cancelText?: string
    onConfirm: () => void
}

const ConfirmActionDialog: React.FC<ConfirmActionDialogProps> = ({
    title,
    message,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onConfirm,
}) => {
    const [open, setOpen] = useState(true)

    const handleOpen = useCallback(() => setOpen(true), [])
    const handleClose = useCallback(() => setOpen(false), [])
    const handleConfirm = useCallback(() => {
        onConfirm()
        handleClose()
    }, [onConfirm, handleClose])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Typography id="confirm-dialog-description">
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    color="secondary"
                >
                    {cancelText}
                </Button>
                <Button
                    onClick={handleConfirm}
                    variant="outlined"
                    color="primary"
                    autoFocus
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const useConfirmActionDialog = () => {
    const [dialogProps, setDialogProps] =
        useState<ConfirmActionDialogProps | null>(null)

    const confirmAction = useCallback((props: ConfirmActionDialogProps) => {
        setDialogProps(props)
    }, [])

    const ConfirmActionDialogComponent = useCallback(() => {
        if (!dialogProps) return null
        return <ConfirmActionDialog {...dialogProps} />
    }, [dialogProps])

    return { confirmAction, ConfirmActionDialogComponent }
}

export default ConfirmActionDialog
