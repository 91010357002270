import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useDialog } from '@contexts/dialogContext'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'

const Explore = () => {
    const { primary, primaryDark } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const isXs = useMediaQuery(theme.breakpoints.up('xs'))
    const { openSignupDialog } = useDialog()
    const navigation: NavigationProp<ParamListBase> = useNavigation()

    return (
        <Box>
            <Card
                sx={{
                    backgroundColor: primaryDark,
                    borderRadius: '30px',
                    p: { xs: 1, sm: 3, md: 4 },
                    position: 'relative',
                    overflow: 'hidden',
                    zIndex: 99,
                }}
                elevation={0}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: isSm ? 'row' : 'column',
                    }}
                >
                    <Box sx={{ flex: 9, zIndex: 99 }}>
                        {isXs && !isSm && (
                            <Box sx={{ minHeight: 150 }}>
                                <Box
                                    component="img"
                                    src={require('@assets/images/landingpage/explore2.svg')}
                                    alt="landing-page-image"
                                    sx={{
                                        width: 180,
                                        position: 'absolute',
                                        top: 12,
                                        right: `calc(50% - 90px)`,
                                    }}
                                />
                            </Box>
                        )}

                        <Typography
                            sx={{
                                color: '#FFF',
                                mb: 2,
                                fontSize: {
                                    xs: '2rem',
                                    sm: '2.5rem',
                                    md: '3.5 rem',
                                    lg: '4rem',
                                    xl: '5rem',
                                },
                            }}
                            fontFamily={defaultStyles.titleFont}
                            variant="h2"
                        >
                            Explore a new <br />
                            way of learning!
                        </Typography>
                        <Typography
                            sx={{
                                color: '#FFF',
                                fontSize: {
                                    xs: '1.5rem',
                                    sm: '1.75rem',
                                    md: '2rem',
                                },
                            }}
                            fontFamily="Poppins_500Medium"
                            variant="h4"
                        >
                            Connect to the right tutor <br />
                            instantly
                        </Typography>
                        <Box
                            sx={{
                                mt: 4,
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: defaultStyles.boldFont,
                                    fontSize: {
                                        xs: '1rem',
                                        sm: '1rem',
                                        md: '1.25rem',
                                    },
                                    px: { xs: 2, sm: 3 },
                                    height: { xs: 48, sm: 50, md: 52 },
                                    backgroundColor: '#FFF',
                                    border: `3px solid black`,
                                    color: '#000',
                                    borderRadius: '12px',
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                                onClick={() => openSignupDialog('student')}
                            >
                                Get Started
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: defaultStyles.boldFont,
                                    fontSize: {
                                        xs: '1rem',
                                        sm: '1rem',
                                        md: '1.25rem',
                                    },
                                    px: { xs: 2, sm: 3 },
                                    height: { xs: 48, sm: 50, md: 52 },
                                    backgroundColor: '#FFF',
                                    border: `3px solid black`,
                                    borderRadius: '12px',
                                    color: primary,
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                                onClick={() => {
                                    navigation.navigate('Tutors', {
                                        screen: 'TutorList',
                                    })
                                }}
                            >
                                Browse Tutors
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 7,
                            position: 'relative',
                            minHeight: isSm ? 250 : 150,
                        }}
                    >
                        {isSm && (
                            <Box
                                component="img"
                                src={require('@assets/images/landingpage/explore2.svg')}
                                alt="landing-page-image"
                                sx={{
                                    width: 180,
                                    position: 'absolute',
                                    top: -36,
                                    right: `calc(50% - 136px)`,
                                }}
                            />
                        )}

                        <Box
                            component="img"
                            src={require('@assets/images/landingpage/explore1.png')}
                            alt="landing-page-image"
                            sx={{
                                width: '100%',
                                position: 'absolute',
                                bottom: isSm ? -56 : -32,
                                right: isSm ? -56 : -32,
                                maxHeight: 240,
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Explore
