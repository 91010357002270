import React from 'react'
import { Checkbox } from '@mui/material'
import CheckBoxIcon from '@icons/CheckBoxIcon'

interface TutredCheckboxProps {
    checked?: boolean
    onChange: () => void
}

const TutredCheckbox: React.FC<TutredCheckboxProps> = ({
    checked,
    onChange,
}) => {
    return (
        <Checkbox
            checked={checked}
            onChange={onChange}
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckBoxIcon checked />}
        />
    )
}

export default TutredCheckbox
