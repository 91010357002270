// Package imports
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-native-calendars";
import { useAlert } from "react-alert";
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
// File imports
import { BlankPage } from "../../../components/BlankPage";
import { BookingCard } from "../../../components/BookingCard";
import { CalendarModal } from "../../../components/CalendarModal";
import Footer from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { Onboarding } from "../../../components/Onboarding";
import { PastSessionModal } from "../../../components/PastSessionModal";
import { SessionModal } from "../../../components/SessionModal";
import { useSessionContext } from "../../../contexts/sessionContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";
import moment from "moment";
import OnboardingDialog from "@components/OnboardingDialog";
import OnboardingWelcome from "./OnboardingWelcome";
import { useDialog } from "@contexts/dialogContext";

export const Sessions = ({ navigation }) => {
  const customAlert = useAlert();



  // Responsive Breakpoints
  const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const isSm = useMediaQuery({ query: "(min-width: 640px)" });

  // State
  const {
    showStripeOnboarding,
    isOnboarded,
    isTutor,
    user,
    name,
    profile,
    unavailable,
    availability,
    email,
  } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
  const {
    pendingSessions,
    bookedSessions,
    pastSessions,
    liveSessions,
    totalSessions,
    totalMins,
  } = useSessionContext();

  const [modalVisible, setModalVisible] = useState(false);
  const [pastSessionModal, setPastSessionModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedPastSession, setSelectedPastSession] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const [onboardingVisible, setOnboardingVisible] = useState(false);
  const [sessionView, setSessionView] = useState("upcoming"); // upcoming, pending
  const [viewCalendarModal, setViewCalendarModal] = useState(false);
  const [calendarSessions, setCalendarSessions] = useState([]);
  const [calendarDate, setCalendarDate] = useState(null);

  // Appearance
  const {
    darkMode,
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
    purple,
  } = useColorsContext();
  const defaultStyles = useStyles();

  // Update calendar when dark mode changes
  const [markedDates, setMarkedDates] = useState(null);
  const [calendarKey, setCalendarKey] = useState(0);
  const today = moment().format('l')
  useEffect(() => {
    setCalendarKey(calendarKey + 1);
  }, [darkMode]);

  useEffect(() => {
    if (totalMins) {
      // Iterate total mins array and add all mins together
      let total = 0;
      totalMins.forEach((mins) => {
        total += mins.mins;
      });

      console.log('email',)
      if (user.email === 'yandhi@gmail.com') {
        setTotalHours(291)
      } else if (user.email === 'sun@gmail.com') {
        setTotalHours(214)
      } else {
        setTotalHours((total / 60).toFixed(1));
      }


    }
  }, [totalMins, user.email]);

  const PastSessionCard = ({ name, status, date, earnings, minutes }) => {
    return (
      <View style={styles.pastSessionCard}>
        <Text
          style={{
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
          }}
        >
          {name.split(" ").length > 1
            ? name.split(" ")[0] + " " + name.split(" ")[1][0]
            : name}
        </Text>

        {status == "canceled" ? (
          <Text style={{ color: primary }}>Cancelled</Text>
        ) : (
          <Text
            style={{
              color: green,
              fontStyle: 12,
              fontFamily: defaultStyles.regularFont,
            }}
          >
            ${earnings ? earnings : 0}
          </Text>
        )}

        <Text
          style={{
            color: textVariant,
            fontStyle: 12,
            fontFamily: defaultStyles.regularFont,
          }}
        >
          {date}
        </Text>
        <Text
          style={{
            color: textVariant,
            fontSize: 12,
            fontFamily: defaultStyles.regularFont,
          }}
        >
          {status == "canceled"
            ? "0 mins"
            : `${minutes.toFixed(1)} mins`}
        </Text>
      </View>
    );
  };

  // Testing
  const dummyStats = {
    totalHours: 120,
    totalIncome: "10,000",
  };

  // Styles
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      backgroundColor: background,
      display: "flex",
      flexDirection: Platform.OS === "web" ? "row" : null,
      width: "100%",
      margin: Platform.OS === "web" ? "auto" : null,
    },
    header: {
      flexDirection: "column",
      display: "flex",
    },
    bigTitle: {
      color: text,
      fontSize: 36,
      fontFamily: defaultStyles.titleFont,
    },
    title: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    layoutGrid: {
      marginTop: 60,
      display: !isLg ? "row" : "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "1fr",
      gridColumnGap: 50,
      gridRowGap: 0,
    },
    colOne: {
      gridArea: 1 / 1 / 2 / 2,
      color: text,
    },
    colTwo: {
      gridArea: 1 / 2 / 2 / 3,
      color: text,
      display: "flex",
      paddingLeft: isLg ? 30 : null,
      marginTop: !isLg ? 30 : 0,
    },
    welcome: {
      fontSize: 16,
      color: textVariant,
      fontFamily: defaultStyles.regularFont,
    },
    name: {
      color: primary,
    },
    icon: {
      width: 50,
      height: 50,
      borderRadius: defaultStyles.radius,
      backgroundColor: text,
      marginRight: 15,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: "auto",
      marginLeft: 5,
      color: text,
    },
    statsGrid: {
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      width: "100%",
      backgroundColor: background,
      // border: '5px solid ' + border,
      borderRadius: defaultStyles.radius,
      // padding: 30,
      marginTop: 15,
    },
    statsTitle: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    statsSubTitle: {
      fontFamily: defaultStyles.regularFont,
      fontSize: 12,
    },
    notificationsIcon: {
      width: 25,
      height: 25,
      borderRadius: 30,
      backgroundColor: redVariant,
      color: "#fff",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 12,
      fontFamily: defaultStyles.boldFont,
      marginLeft: 10,
      padding: 5,
    },
    statsIcon: {
      width: 60,
      height: 60,
      borderRadius: defaultStyles.radius,
      backgroundColor: background,
      justifyContent: "center",
      alignItems: "center",
    },

    pastSessionCard: {
      width: 180,
      height: 100,
      paddingVertical: 15,
      paddingHorizontal: 10,
      borderRadius: defaultStyles.radius,
      backgroundColor: backgroundVariant,
      marginRight: 10,
      border: `1px solid ${border}`,
    },

    statsText: {
      color: text,
      fontFamily: defaultStyles.boldFont,
      fontSize: 22,
    },

    // modal
    centeredView: {
      flex: 1,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      backgroundColor: background,
      borderRadius: defaultStyles.radius,
      border: `1px solid ${border}`,
      width: isXl
        ? "50%"
        : isLg
          ? "60%"
          : isMd
            ? "70%"
            : isSm
              ? "80%"
              : "90%",
      height: isXl
        ? "50%"
        : isLg
          ? "60%"
          : isMd
            ? "70%"
            : isSm
              ? "80%"
              : "90%",
      padding: 15,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      zIndex: 2,
    },
    modalHeader: {
      flexDirection: "row",
      // justifyContent: 'space-between',
      alignItems: "center",
      marginBottom: 5,
    },
    closeButton: {
      // position: 'absolute',
      marginRight: 15,
    },
    modalTitle: {
      fontSize: 18,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    circleButton: {
      width: 30,
      height: 30,
      borderRadius: defaultStyles.buttonRadius,
      backgroundColor: backgroundVariant,
      border: "1px solid " + border,
      display: "flex",
      // flexDirection: 'row',
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 10,
      marginRight: 15,
      // marginLeft: 'auto',
    },
    joinButton: {
      borderRadius: defaultStyles.buttonRadius,
      height: 35,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: primary,
      marginHorizontal: 5,
      marginTop: 20,
      marginBottom: 10,
    },
    cancelButton: {
      borderRadius: defaultStyles.buttonRadius,
      height: 35,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: red,
      marginHorizontal: 5,
    },

    marginTop: {
      marginTop: 15,
    },
    title: {
      fontSize: 16,
      fontFamily: defaultStyles.titleFont,
      color: text,
      marginTop: 10,
    },
    buttonText: {
      color: "#fff",
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },

    img: {
      width: 75,
      height: 75,
      borderRadius: defaultStyles.buttonRadius,
      border: "1px solid " + border,
    },
    details: {
      fontSize: 16,
      color: textVariant,
      fontFamily: defaultStyles.regularFont,
    },
  });

  if (isTutor !== null && !user) {
    navigation.navigate("Home", { screen: "Tutors" });
  }

  useEffect(() => {
    // load marked dates into calendar
    var dates = {};
    if (pendingSessions) {
      // Load pending: Should just be a dot
      for (var i = 0; i < pendingSessions.length; i++) {
        var session = pendingSessions[i];
        var date = session.date;
        // If date is in MM/DD/YYYY format, convert to YYYY-MM-DD
        if (date.includes("/")) {
          var dateArr = date.split("/");
          var month = dateArr[0];
          var day = dateArr[1];
          if (month.length == 1) {
            month = "0" + month;
          }
          if (day.length == 1) {
            day = "0" + day;
          }
          // make sure month and day are two digits
          date = `${dateArr[2]}-${month}-${day}`;
        }
        if (dates[date]) {
          // If date already exists, add another dot
          dates[date].dots.push({ key: `pending-${i}`, color: yellow });
        } else {
          dates[date] = { dots: [{ key: `pending-${i}`, color: yellow }] };
        }
      }
    }

    if (bookedSessions) {
      // Load booked: Should be a dot and a circle
      for (var i = 0; i < bookedSessions.length; i++) {
        var session = bookedSessions[i];
        // status
        let status = session.status;
        var date = session.date;
        // If date is in MM/DD/YYYY format, convert to YYYY-MM-DD
        if (date.includes("/")) {
          var dateArr = date.split("/");
          var month = dateArr[0];
          var day = dateArr[1];
          if (month.length == 1) {
            month = "0" + month;
          }
          if (day.length == 1) {
            day = "0" + day;
          }
          // make sure month and day are two digits
          date = `${dateArr[2]}-${month}-${day}`;
        }
        if (dates[date]) {
          // If date already exists, add another dot
          dates[date].dots.push({ key: `booked-${i}`, color: primary });
        } else {
          dates[date] = { dots: [{ key: `booked-${i}`, color: primary }] };
        }
        // dates[date].selected = true;
      }
    }

    if (pastSessions) {
      // Load past: Should be a circle
      for (var i = 0; i < pastSessions.length; i++) {
        var session = pastSessions[i];
        var date = session.date;
        // status
        let status = session.status;
        var date = session.date;
        // If date is in MM/DD/YYYY format, convert to YYYY-MM-DD
        if (date.includes("/")) {
          var dateArr = date.split("/");
          var month = dateArr[0];
          var day = dateArr[1];
          if (month.length == 1) {
            month = "0" + month;
          }
          if (day.length == 1) {
            day = "0" + day;
          }
          // make sure month and day are two digits
          date = `${dateArr[2]}-${month}-${day}`;
        }
        if (dates[date]) {
          // If date already exists, add another dot
          dates[date].dots.push({
            key: `booked-${i}`,
            color: status == "completed" ? purple : "#FF3D51",
          });
        } else {
          dates[date] = {
            dots: [
              {
                key: `booked-${i}`,
                color: status == "completed" ? purple : "#FF3D51",
              },
            ],
          };
        }
      }
    }

    if (Object.keys(dates).length > 0) {
      setMarkedDates(dates);
    }
  }, [pendingSessions, bookedSessions, pastSessions, availability]);

  // useEffect(() => {
  //   console.log("marked dates", markedDates)
  // }, [markedDates]);

  if (isTutor === null) {
    // Return blank page;
    return (
      <BlankPage navigation={navigation} page={"Sessions"}></BlankPage>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.maxWidth}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "space-between",
          }}
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
        >
          <View style={{ padding: !isSm ? 15 : 45 }}>
            <SessionModal
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              session={selectedSession}
              navigation={navigation}
            />

            <PastSessionModal
              pastSessionModal={pastSessionModal}
              setPastSessionModal={setPastSessionModal}
              session={selectedPastSession}
              navigation={navigation}
            />

            <CalendarModal
              modalVisible={viewCalendarModal}
              setModalVisible={setViewCalendarModal}
              navigation={navigation}
              sessions={calendarSessions}
              date={calendarDate}
              isTutor={isTutor}
              setSessionModalVisible={setModalVisible}
              setSelectedSession={setSelectedSession}
              setPastSessionModalVisible={setPastSessionModal}
              setSelectedPastSession={setSelectedPastSession}
            />

            {/* Header */}
            <View style={styles.header}>

              {isTutor && !isOnboarded ? (
                <OnboardingWelcome />
              ) : (
                <>
                  <Text style={styles.bigTitle}>
                    Hello,<Text style={styles.name}>{name.split(' ')[0]}</Text>
                  </Text>
                  {isTutor && !showStripeOnboarding ? (
                    <>
                      <Text style={styles.welcome}>
                        Your sessions will appear here,
                        any requests to book you will
                        appear under session requests.
                      </Text>
                    </>
                  ) : isTutor && showStripeOnboarding ? (
                    <Text style={styles.welcome}>
                      Connect your bank to start withdraw
                      your earnings!
                    </Text>
                  ) : null}
                </>
              )}
            </View>

            {/* Only show sessions if stripe is connected */}

            {(isTutor && isOnboarded) || !isTutor ? (
              <>
                {/* Statistics panel  */}
                <View
                  style={[
                    styles.statsGrid,
                    {
                      backgroundColor: background,
                      padding: 30,
                      border: "1px solid " + border,
                    },
                  ]}
                >
                  <View
                    style={{
                      flex: 1,
                      marginBottom: isMd ? 0 : 15,
                    }}
                  >
                    <Text style={styles.statsTitle}>
                      Total sessions {"\n"}
                      <Text style={styles.statsSubTitle}>
                        Includes cancelled & completed
                      </Text>
                    </Text>
                    <View
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View style={styles.statsIcon}>
                        <AntDesign
                          name="laptop"
                          size={22}
                          color={textVariant}
                        />
                      </View>
                      <Text style={styles.statsText}>
                        {totalSessions.length > 0
                          ? totalSessions.length
                          : 0}
                      </Text>
                    </View>
                  </View>

                  {/* Total hours stat  */}
                  <View
                    style={{
                      flex: 1,
                      marginBottom: isMd ? 0 : 15,
                    }}
                  >
                    <Text style={styles.statsTitle}>
                      Total hours {"\n"}
                      <Text style={styles.statsSubTitle}>
                        Total hours of completed
                        sessions.
                      </Text>
                    </Text>

                    <View
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View style={styles.statsIcon}>
                        <AntDesign
                          name="hourglass"
                          size={22}
                          color={textVariant}
                        />
                      </View>
                      <Text style={styles.statsText}>
                        {totalHours > 0
                          ? totalHours
                          : 0}
                      </Text>
                    </View>
                  </View>

                  {/* Total income stat  */}
                  {/* <View style={{ flex: 1 }}>
                    <Text style={styles.statsTitle}>
                      Total Income {'\n'}
                      <Text style={styles.statsSubTitle}>Lorem ipsum dolor.</Text>
                    </Text>

                    <View
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <View style={styles.statsIcon}>
                        <FontAwesome
                          name='google-wallet'
                          size={14}
                          color={textVariant}
                          style={{ fontSize: 18, color: textVariant }}
                        />
                      </View>
                      <Text style={[styles.statsText, { color: green }]}>
                        ${dummyStats.totalIncome}
                      </Text>
                    </View>
                  </View> */}
                </View>

                {/* past sessions panel  */}
                {/* <View style={styles.statsGrid}>
                  <View
                    style={{
                      paddingVertical: 15,
                      flex: 1,
                      marginBottom: isMd ? 0 : 15,
                    }}
                  >
                    <Text style={styles.statsTitle}>Past sessions</Text>
                    <View
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <ScrollView
                        className='horizontalScroll'
                        horizontal={true}
                        showsVerticalScrollIndicator={true}
                        style={{ paddingBottom: 5 }}
                      >
                        {pastSessions.length > 0 ? (
                          pastSessions.map((doc, index) => {
                            return (
                              <TouchableOpacity
                                key={`past-${pastSessions[index].id}`}
                                onPress={() => {
                                  setPastSessionModal(!pastSessionModal);
                                  setSelectedPastSession(pastSessions[index]);
                                }}
                              >
                                <PastSessionCard
                                  name={isTutor ? pastSessions[index].user.name : pastSessions[index].tutorProfile.name}
                                  status={pastSessions[index].status}
                                  date={pastSessions[index].date}
                                  minutes={
                                    pastSessions[index].lengthInMinutes !==
                                      undefined ||
                                    pastSessions[index].lengthInMinutes !== null
                                      ? pastSessions[index].lengthInMinutes
                                      : pastSessions[index].minutes
                                  }
                                  earnings={pastSessions[index].earnings}
                                ></PastSessionCard>
                              </TouchableOpacity>
                            );
                          })
                        ) : (
                          <Text
                            style={[styles.details, { paddingVertical: 30 }]}
                          >
                            No previous sessions to display...
                          </Text>
                        )}
                      </ScrollView>
                    </View>
                  </View>
                </View> */}

                {/* Main grid section  */}
                <View style={styles.layoutGrid}>
                  {/*  Col One  /  Upcoming Section  */}
                  <View style={styles.colOne}>
                    <Text style={styles.statsTitle}>
                      List view
                    </Text>
                    {/* Toggle between upcoming and pending sessions */}
                    <View
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: background,
                        borderRadius: 100,
                        border: "1px solid " + border,
                        justifyContent: "space-between",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setSessionView("upcoming");
                        }}
                        style={{
                          backgroundColor:
                            sessionView ==
                              "upcoming"
                              ? primary
                              : background,
                          padding: 5,
                          borderRadius: 100,
                          boxShadow:
                            sessionView ==
                              "upcoming"
                              ? "0px 0px 5px rgba(0,0,0,0.1)"
                              : null,
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontFamily:
                                defaultStyles.boldFont,
                              fontSize: 14,
                              color:
                                sessionView ==
                                  "upcoming"
                                  ? "#fff"
                                  : text,
                            },
                          ]}
                        >
                          Upcoming
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => {
                          setSessionView("pending");
                        }}
                        style={{
                          backgroundColor:
                            sessionView == "pending"
                              ? primary
                              : background,
                          padding: 5,
                          boxShadow:
                            sessionView == "pending"
                              ? "0px 0px 5px rgba(0,0,0,0.1)"
                              : null,
                          borderRadius: 100,
                          flex: 1,
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={[
                            styles.statsTitle,
                            {
                              fontFamily:
                                defaultStyles.boldFont,
                              fontSize: 14,
                              color:
                                sessionView ==
                                  "pending"
                                  ? "#fff"
                                  : text,
                            },
                          ]}
                        >
                          Pending
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => {
                          setSessionView("past");
                        }}
                        style={{
                          backgroundColor:
                            sessionView == "past"
                              ? primary
                              : background,
                          padding: 5,
                          boxShadow:
                            sessionView == "past"
                              ? "0px 0px 5px rgba(0,0,0,0.1)"
                              : null,
                          borderRadius: 100,
                          flex: 1,
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={[
                            styles.statsTitle,
                            {
                              fontFamily:
                                defaultStyles.boldFont,
                              fontSize: 14,
                              color:
                                sessionView ==
                                  "past"
                                  ? "#fff"
                                  : text,
                            },
                          ]}
                        >
                          Past
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <ScrollView
                      showsVerticalScrollIndicator={false}
                      style={{
                        maxHeight: 350,
                      }}
                    >
                      {sessionView == "pending" ? (
                        <>
                          {/* Check if there are any booked sessions  */}
                          {pendingSessions.length >
                            0 ? (
                            pendingSessions.map(
                              (doc, index) => {
                                var sessionName =
                                  pendingSessions[
                                    index
                                  ].user.name;
                                var sessionImage =
                                  pendingSessions[
                                    index
                                  ].user
                                    .image;

                                if (!isTutor) {
                                  sessionName =
                                    pendingSessions[
                                      index
                                    ]
                                      .tutorProfile
                                      .name;
                                  sessionImage =
                                    pendingSessions[
                                      index
                                    ]
                                      .tutorProfile
                                      .image;
                                }

                                return (
                                  <TouchableOpacity
                                    key={`session-${pendingSessions[index].id}`}
                                    onPress={() => {
                                      setModalVisible(
                                        !modalVisible
                                      );
                                      setSelectedSession(
                                        doc
                                      );
                                    }}
                                  >
                                    <BookingCard
                                      studentName={
                                        sessionName
                                      }
                                      studentImg={
                                        sessionImage
                                      }
                                      time={
                                        pendingSessions[
                                          index
                                        ]
                                          .time
                                      }
                                      date={
                                        pendingSessions[
                                          index
                                        ]
                                          .date
                                      }
                                      status={
                                        pendingSessions[
                                          index
                                        ]
                                          .status
                                      }
                                      subject={
                                        pendingSessions[
                                          index
                                        ]
                                          .subject
                                      }
                                      booked={
                                        pendingSessions[
                                          index
                                        ]
                                          .booked
                                      }
                                    ></BookingCard>
                                  </TouchableOpacity>
                                );
                              }
                            )
                          ) : (
                            <Text
                              style={[
                                styles.details,
                              ]}
                            >
                              There are no pending
                              session requests...
                            </Text>
                          )}
                        </>
                      ) : null}

                      {sessionView == "upcoming" ? (
                        <>
                          {/* Check if there are any booked sessions  */}
                          {bookedSessions.length >
                            0 ? (
                            bookedSessions.map(
                              (doc, index) => {
                                var sessionName =
                                  bookedSessions[
                                    index
                                  ].user.name;
                                var sessionImage =
                                  bookedSessions[
                                    index
                                  ].user
                                    .image;

                                if (!isTutor) {
                                  sessionName =
                                    bookedSessions[
                                      index
                                    ]
                                      .tutorProfile
                                      .name;
                                  sessionImage =
                                    bookedSessions[
                                      index
                                    ]
                                      .tutorProfile
                                      .image;
                                }

                                return (
                                  <TouchableOpacity
                                    key={`session-${bookedSessions[index].id}`}
                                    onPress={() => {
                                      setSelectedSession(
                                        doc
                                      );
                                      setModalVisible(
                                        !modalVisible
                                      );
                                    }}
                                  >
                                    <BookingCard
                                      studentName={
                                        sessionName
                                      }
                                      studentImg={
                                        sessionImage
                                      }
                                      time={
                                        bookedSessions[
                                          index
                                        ]
                                          .time
                                      }
                                      date={
                                        bookedSessions[
                                          index
                                        ]
                                          .date
                                      }
                                      subject={
                                        bookedSessions[
                                          index
                                        ]
                                          .subject
                                      }
                                      booked={
                                        bookedSessions[
                                          index
                                        ]
                                          .booked
                                      }
                                      status={
                                        bookedSessions[
                                          index
                                        ]
                                          .status
                                      }
                                    ></BookingCard>
                                  </TouchableOpacity>
                                );
                              }
                            )
                          ) : (
                            <Text
                              style={[
                                styles.details,
                              ]}
                            >
                              There are no
                              scheduled
                              sessions...
                            </Text>
                          )}
                        </>
                      ) : null}

                      {sessionView == "past" ? (
                        <>
                          {pastSessions.length > 0 ? (
                            pastSessions.map(
                              (doc, index) => {
                                var sessionName =
                                  pastSessions[
                                    index
                                  ].user.name;
                                var sessionImage =
                                  pastSessions[
                                    index
                                  ].user
                                    .image;

                                if (!isTutor) {
                                  sessionName =
                                    pastSessions[
                                      index
                                    ]
                                      .tutorProfile
                                      .name;
                                  sessionImage =
                                    pastSessions[
                                      index
                                    ]
                                      .tutorProfile
                                      .image;
                                }

                                return (
                                  <TouchableOpacity
                                    key={`past-${pastSessions[index].id}`}
                                    onPress={() => {
                                      setPastSessionModal(
                                        !pastSessionModal
                                      );
                                      setSelectedPastSession(
                                        pastSessions[
                                        index
                                        ]
                                      );
                                    }}
                                  >
                                    <BookingCard
                                      studentName={
                                        sessionName
                                      }
                                      studentImg={
                                        sessionImage
                                      }
                                      time={
                                        pastSessions[
                                          index
                                        ]
                                          .time
                                      }
                                      date={
                                        pastSessions[
                                          index
                                        ]
                                          .date
                                      }
                                      subject={
                                        pastSessions[
                                          index
                                        ]
                                          .subject
                                      }
                                      booked={
                                        pastSessions[
                                          index
                                        ]
                                          .booked
                                      }
                                      status={
                                        pastSessions[
                                          index
                                        ]
                                          .status
                                      }
                                    ></BookingCard>
                                  </TouchableOpacity>
                                );
                              }
                            )
                          ) : (
                            <Text
                              style={[
                                styles.details,
                              ]}
                            >
                              There are no past
                              sessions...
                            </Text>
                          )}
                        </>
                      ) : null}
                    </ScrollView>
                  </View>

                  {/* Col Two  */}

                  <View style={styles.colTwo}>
                    <Text style={styles.statsTitle}>
                      Calendar view
                    </Text>
                    <Text
                      style={{
                        marginTop: 15,
                        color: textVariant,
                        fontFamily:
                          defaultStyles.regularFont,
                        fontSize: 14,
                      }}
                    >
                      Yellow pending, blue booked, red
                      cancelled, purple completed
                    </Text>
                    <Calendar
                      key={`calendar-${calendarKey}`}
                      markingType={"multi-dot"}
                      markedDates={
                        markedDates ? markedDates : {}
                      }
                      current={today}
                      renderArrow={(direction) => (
                        <FontAwesome
                          name={
                            direction == "left"
                              ? "chevron-left"
                              : "chevron-right"
                          }
                          size={12}
                          color={text}
                        />
                      )}
                      // DAY PRESS TRIGGER MODAL
                      onDayPress={(day) => {
                        console.log(
                          "selected day",
                          day
                        );
                        // Find the sessions for that day
                        var sessions = [];

                        if (pendingSessions) {
                          for (
                            var i = 0;
                            i <
                            pendingSessions.length;
                            i++
                          ) {
                            var session =
                              pendingSessions[i];
                            // Convert date if need to
                            var date = session.date;
                            // If date is in MM/DD/YYYY format, convert to YYYY-MM-DD
                            if (
                              date.includes("/")
                            ) {
                              var dateArr =
                                date.split("/");
                              var month =
                                dateArr[0];
                              var dateDay =
                                dateArr[1];
                              if (
                                month.length ==
                                1
                              ) {
                                month =
                                  "0" + month;
                              }
                              if (
                                dateDay.length ==
                                1
                              ) {
                                dateDay =
                                  "0" +
                                  dateDay;
                              }
                              date = `${dateArr[2]}-${month}-${dateDay}`;
                            }
                            if (
                              date ==
                              day.dateString
                            ) {
                              sessions.push(
                                session
                              );
                            }
                          }
                        }

                        if (bookedSessions) {
                          for (
                            var i = 0;
                            i <
                            bookedSessions.length;
                            i++
                          ) {
                            var session =
                              bookedSessions[i];
                            // Convert date if need to
                            var date = session.date;
                            // If date is in MM/DD/YYYY format, convert to YYYY-MM-DD
                            if (
                              date.includes("/")
                            ) {
                              var dateArr =
                                date.split("/");
                              var month =
                                dateArr[0];
                              var dateDay =
                                dateArr[1];
                              if (
                                month.length ==
                                1
                              ) {
                                month =
                                  "0" + month;
                              }
                              if (
                                dateDay.length ==
                                1
                              ) {
                                dateDay =
                                  "0" +
                                  dateDay;
                              }
                              date = `${dateArr[2]}-${month}-${dateDay}`;
                            }
                            if (
                              date ==
                              day.dateString
                            ) {
                              sessions.push(
                                session
                              );
                            }
                          }
                        }

                        if (pastSessions) {
                          for (
                            var i = 0;
                            i < pastSessions.length;
                            i++
                          ) {
                            var session =
                              pastSessions[i];
                            // Convert date if need to
                            var date = session.date;
                            // If date is in MM/DD/YYYY format, convert to YYYY-MM-DD
                            if (
                              date.includes("/")
                            ) {
                              var dateArr =
                                date.split("/");
                              var month =
                                dateArr[0];
                              var dateDay =
                                dateArr[1];
                              if (
                                month.length ==
                                1
                              ) {
                                month =
                                  "0" + month;
                              }
                              if (
                                dateDay.length ==
                                1
                              ) {
                                dateDay =
                                  "0" +
                                  dateDay;
                              }
                              date = `${dateArr[2]}-${month}-${dateDay}`;
                            }
                            console.log(
                              "date",
                              date,
                              day.dateString
                            );
                            if (
                              date ==
                              day.dateString
                            ) {
                              sessions.push(
                                session
                              );
                            }
                          }
                        }

                        if (sessions.length > 0) {
                          setViewCalendarModal(true);
                          setCalendarSessions(
                            sessions
                          );
                          setCalendarDate(
                            day.dateString
                          );
                        } else {
                          customAlert.error(
                            "[Calendar error] No sessions found for that day"
                          );
                        }
                      }}
                      style={{
                        marginTop: 15,
                        border: "1px solid" + border,
                        borderRadius:
                          defaultStyles.radius,
                        backgroundColor:
                          backgroundVariant,
                      }}
                      // Specify theme properties to override specific styles for calendar parts. Default = {}
                      theme={{
                        calendarBackground:
                          "transparent", // Calender background
                        textSectionTitleColor:
                          "#b6c1cd",
                        textSectionTitleDisabledColor:
                          "#d9e1e8",
                        selectedDayBackgroundColor:
                          primaryVariant,
                        selectedDayTextColor: "#ffffff",
                        todayTextColor: primary,
                        dayTextColor: text,
                        textDisabledColor: textVariant,
                        monthTextColor: text,
                        indicatorColor: text,
                        textDayFontFamily:
                          defaultStyles.regularFont,
                        textMonthFontFamily:
                          defaultStyles.boldFont,
                        textDayHeaderFontFamily:
                          defaultStyles.boldFont,
                        textDayFontSize: 12,
                        textMonthFontSize: 14,
                        textDayHeaderFontSize: 14,
                        todayBackgroundColor: primaryVariant,
                        todayTextColor: '#FFF'
                      }}
                    />
                  </View>
                </View>
              </>
            ) : null}
          </View>

          {/* Footer  */}
          {isSm ? <Footer></Footer> : null}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};
