import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import { useFirebaseContext } from '@contexts/firebaseContext'
import BackgroundIcon from '@icons/BackgroundIcon'
import { useUserContext } from '@contexts/userContext'
import {
    Box,
    Button,
    Card,
    Checkbox,
    Typography,
    useTheme,
} from '@mui/material'
import AddNewDegreeModal from '@screens/loggedIn/Profile/Education/AddNewDegreeModal'
import DegreeCard from '@screens/loggedIn/Profile/Education/DegreeCard'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import DegreeForm from './DegreeForm'
import ProfilePreviewIcon from '@icons/ProfilePreviewIcon'
import InputIconLabel from '@components/InputIconLabel'
import UploadVerificationDialog from './UploadVerificationDialog'
import { useConfirmActionDialog } from '@hooks/useConfirmAction'
import { useColorsContext, useStyles } from '@utils/styles'
import CertifiedBadge from '@icons/CertifiedBadge'
import { CheckBox } from 'react-native-elements'
import { CheckBoxOutlined } from '@mui/icons-material'
import CheckBoxIcon from '@icons/CheckBoxIcon'
import CheckedCheckBoxIcon from '@icons/CheckedCheckBoxIcon'
import TutredCheckbox from '@components/TutredCheckbox'

const Background = ({ setStep }) => {
    const [noDegree, setNoDegree] = useState(false)
    const alert = useAlert()
    const { user, extendedUser } = useUserContext()
    const { db } = useFirebaseContext()
    const { confirmAction, ConfirmActionDialogComponent } =
        useConfirmActionDialog()
    const [error, setError] = useState(false)
    const { primaryExtraLight, text } = useColorsContext()
    const defaultStyle = useStyles()
    const theme = useTheme()

    const removeDegree = (id: string) => {
        confirmAction({
            title: 'Delete Degree',
            message: 'Are you sure you want to delete this degree?',
            confirmText: 'Delete',
            onConfirm: () => {
                const docRef = doc(db, 'users', user.uid)
                updateDoc(docRef, {
                    degrees: extendedUser?.degrees?.filter(
                        (degree) => degree.id !== id
                    ),
                })
            },
        })
    }

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <ConfirmActionDialogComponent />
            <OnboardingSectionHeader
                title="Background"
                subtitle="Tell students your qualifications. Share your education and certifications to showcase your expertise."
                icon={<BackgroundIcon size={28} />}
            />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    pl: { xs: 0, md: 2 },
                    pr: { xs: 0, md: 0.5 },
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: primaryExtraLight,
                        borderRadius: 4,
                        overflowY: 'auto',
                    }}
                >
                    {extendedUser?.degrees?.length === 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: 1,
                                marginBottom: 1,
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <TutredCheckbox
                                checked={noDegree}
                                onChange={() => setNoDegree(!noDegree)}
                            />

                            <Typography
                                variant="body2"
                                fontFamily={defaultStyle.titleFont}
                                sx={{ marginLeft: 1 }}
                            >
                                Skip the next step, if you do not have a degree
                                completed or being completed.
                            </Typography>
                        </Box>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            gap: 2,
                            p: 2,
                            flexDirection: 'column',
                        }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <DegreeForm
                                handleAddDegree={(degree) => {
                                    const docRef = doc(db, 'users', user.uid)
                                    updateDoc(docRef, {
                                        degrees: arrayUnion(degree),
                                    })
                                }}
                            />
                        </Box>
                        <InputIconLabel
                            icon={<ProfilePreviewIcon size={22} />}
                            label="Preview"
                            description="This is how your degree will appear on your profile in the education section."
                            center={true}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                overflowX: 'auto',
                                pb: 1,
                            }}
                        >
                            {extendedUser?.degrees?.map((doc, index) => {
                                return (
                                    <DegreeCard
                                        key={`degree-card-${index}`}
                                        degree={doc}
                                        showVerificationOption={true}
                                        remove={() => removeDegree(doc.id)}
                                        sx={{ width: '50%', flexShrink: 0 }}
                                        size="small"
                                    ></DegreeCard>
                                )
                            })}
                            {extendedUser?.degrees?.length < 2 && (
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderStyle: 'dashed',
                                        width: '50%',
                                        p: 1,
                                        minHeight: 100,
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#727272',
                                            textAlign: 'center',
                                            fontFamily: 'Poppins_600SemiBold',
                                        }}
                                    >
                                        The degree you add will appear here
                                    </Typography>
                                </Card>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            margin: '0 auto',
                            borderRadius: 2,
                            backgroundColor: 'rgba(38, 132, 255, 0.40)',
                            px: 2,
                            py: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '70%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CertifiedBadge />
                            <Typography
                                variant="body1"
                                fontFamily="Poppins_700Bold"
                                sx={{
                                    marginLeft: 1,
                                    textDecoration: 'underline',
                                }}
                            >
                                Earn a "Verified" Badge on your profile
                            </Typography>
                        </Box>
                        <Typography
                            variant="subtitle2"
                            fontFamily="Poppins_500Medium"
                            textAlign="center"
                        >
                            Upload your degree(s) and/or certification(s) for
                            our team to review. Once reviewed a verified badge
                            will appear next to degree/certifcation.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <OnboardingFooter
                step={3}
                onNext={() => {
                    if (extendedUser?.degrees?.length === 0 && !noDegree) {
                        alert.error(
                            '[Error] Please add at least one degree, or select no post secondary experience'
                        )
                        setError
                        return
                    }
                    setStep(3)
                }}
                onPrev={() => {
                    setStep(1)
                }}
            />
        </Box>
    )
}

export default Background
