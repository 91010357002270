import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { duration } from 'moment'
import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import Animated, {
    interpolate,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated'
import MutliSwitchToggleOption, {
    MultiSwitchToggleOption,
} from './MutliSwitchToggleOption'

export interface MultiSwitchToggleProps {
    options: MultiSwitchToggleOption[]
    setSelectedIndex?: (option: any) => void
    selectedIndex?: number | null
    error?: string
    highlightColor?: string
    highlightTextColor?: string
}

export const MultiSwitchToggle: React.FC<MultiSwitchToggleProps> = ({
    options,
    setSelectedIndex,
    selectedIndex,
    error,
    highlightColor,
    highlightTextColor,
}) => {
    const { text, backgroundVariant, border, primary, background, darkMode } =
        useColorsContext()
    const defaultStyles = useStyles()
    const [itemWidths, setItemWidths] = useState([])
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const onOptionLayout = (
        {
            nativeEvent: {
                layout: { width },
            },
        },
        index
    ) => {
        const widths = itemWidths
        widths[index] = width
        setItemWidths([...widths])
    }

    const selectedHighlight = useAnimatedStyle(() => {
        let offset = selectedIndex * 18
        let itemOffsets = 0
        for (let i = 0; i < selectedIndex; i++) {
            itemOffsets += itemWidths[i]
        }
        if (offset < 0) offset = 0
        const translateValue = withTiming(offset + itemOffsets, {
            duration: 400,
        })

        return {
            position: 'absolute',
            backgroundColor: highlightColor || background,
            borderRadius: 8,
            left: -3,
            width: withTiming(itemWidths[selectedIndex] + 18, {
                duration: 400,
            }),
            transform: [{ translateX: translateValue }],
            height: 40,
            border: '2px solid #e4e5e7',
        }
    })

    return (
        <View
            style={[
                styles.container,
                {
                    border: '2px solid ' + border,
                    fontFamily: defaultStyles.titleFont,
                    color: text,
                    backgroundColor: darkMode ? backgroundVariant : '#f6f6f8',
                },
            ]}
        >
            {selectedIndex !== null ? (
                <Animated.View style={[selectedHighlight]}></Animated.View>
            ) : null}
            {options.map((option, index) => (
                <MutliSwitchToggleOption
                    key={option.label}
                    option={option}
                    index={index}
                    onLayout={onOptionLayout}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    highlightTextColor={highlightTextColor}
                />
            ))}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        height: 40,
        alignItems: 'center',
        justifyContent: 'space-evenly',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 7.5,
        paddingRight: 7.5,
        columnGap: 15,
    },
    option: {
        borderRadius: 8,
        // backgroundColor: 'red',
        textAlign: 'center',
    },
    optionText: {
        fontSize: 16,
        color: '#fff',
    },
})
