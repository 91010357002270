import { Box, Typography, useTheme } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { NativeEventEmitter, StyleSheet } from 'react-native'
import Agenda from '@components/Agenda'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import BookSessionModal from '../../BookSessionModal'
import { useUserContext } from '@contexts/userContext'
import { useAlert } from 'react-alert'
import { decode } from 'html-entities'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'
import { useDialog } from '@contexts/dialogContext'
import { useColorsContext } from '@utils/styles'

interface Props {
    tutor: any
}

const Schedule: FC<Props> = ({ tutor }) => {
    const { user, extendedUser } = useUserContext()
    const [modalVisible, setModalVisible] = React.useState(false)
    const [selectedTime, setSelectedTime] = React.useState(null)
    const [selectedPricingIndex, setSelectedPricingIndex] = React.useState(0)
    const customAlert = useAlert()
    const eventEmitter = new NativeEventEmitter()
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const { openLoginDialog } = useDialog()
    const { text } = useColorsContext()

    const pricingOptions = useMemo<MultiSwitchToggleOption[]>(() => {
        if (tutor?.sessionPricing) {
            return Object.keys(tutor?.sessionPricing).map((key) => {
                const pricing = tutor?.sessionPricing[key]
                return {
                    label: `${key} mins`,
                    contextLabel: ` ${decode('\u00b7')} CA$${pricing.rate || 0}`,
                    value: pricing,
                    error: undefined,
                }
            })
        } else {
            return []
        }
    }, [tutor?.sessionPricing?.[30]?.rate, tutor?.sessionPricing?.[60]?.rate])

    if (!tutor?.sessionPricing?.[30] || !tutor?.sessionPricing?.[60]) {
        return (
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h6"
                    fontFamily="Poppins_600SemiBold"
                    sx={{ color: text, my: 2 }}
                >
                    {tutor?.name?.split(' ')[0]} hasnt set up their schedule yet
                </Typography>
                <img
                    style={{
                        maxWidth: 200,
                        maxHeight: 200,
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                    src={require('@assets/images/profile/empty-reviews.svg')}
                    alt="empty-reviews"
                />
            </Box>
        )
    }
    return (
        <>
            <BookSessionModal
                visible={modalVisible}
                setVisible={setModalVisible}
                tutor={tutor}
                appointment={selectedTime}
                pricingOptions={pricingOptions}
                selectedPricingIndex={selectedPricingIndex}
            />
            <Agenda
                tutor={tutor}
                onSelect={() => {
                    if (user) {
                        if (extendedUser.isTutor) {
                            customAlert.error(
                                '[Booking Error] Only students can book sessions!'
                            )
                        } else {
                            setModalVisible(!modalVisible)
                        }
                    } else {
                        openLoginDialog('Login to book this session')
                        eventEmitter.addListener(
                            'login-success',
                            ({ user, extendedUser }) => {
                                if (extendedUser.isTutor) {
                                    navigation.navigate('Sessions')
                                } else {
                                    setModalVisible(!modalVisible)
                                }
                            }
                        )
                    }
                }}
                setModalVisible={setModalVisible}
                setSelectedTime={setSelectedTime}
                pricingOptions={pricingOptions}
                setSelectedPricingIndex={setSelectedPricingIndex}
                selectedPricingIndex={selectedPricingIndex}
            />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default Schedule
