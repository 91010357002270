import React from 'react';

const EducationIcon = ({ size = 35 }) => (
    <svg width={size} height={size} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4999 4.375L1.45825 13.125L17.4999 21.875L33.5416 13.125L17.4999 4.375Z" fill="currentColor" />
        <path d="M27.7084 18.6667L17.5001 24.7917L7.29175 18.6667V25.0834L17.5001 30.6251L27.7084 25.0834V18.6667Z" fill="currentColor" />
        <path d="M33.5417 13.125H30.625V24.7917H33.5417V13.125Z" fill="currentColor" />
    </svg>
);

export default EducationIcon;
