import React, { useEffect, useMemo, useState } from "react";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import {
  Switch,
  View,
  StyleSheet,
  TouchableOpacity,
  Picker,
  Text,
} from "react-native";

import { useColorsContext } from "../utils/styles";
import { useStyles } from "../utils/styles";
import { FontAwesome5 } from "@expo/vector-icons";
import { useFirebaseContext } from "../contexts/firebaseContext";
import { useUserContext } from "../contexts/userContext";
import { LoadingPage } from "../components/LoadingPage";
import { getAuth } from "firebase/auth";

import { updateDoc, doc } from "firebase/firestore";
import useInstaBookTutorial from "../hooks/useInstaBookTutorial";
import Animated, { FadeInDown, FadeOutDown } from "react-native-reanimated";
import NumericTextInput from '@wwdrew/react-native-numeric-textinput';
import TutredSwitch from "./TutredSwitch";

export const InstabookMenu = (props) => {
  const { db } = useFirebaseContext();
  let { instaBookMinimum, instaBookLength, instaBook, userId } = props;
  const [instaBookMinimumState, setInstaBookMinimumState] = useState(instaBookMinimum);
  const instaBookTutorial = useInstaBookTutorial();

  useEffect(() => {
    setInstaBookMinimumState(instaBookMinimum);
  }, [instaBookMinimum]);

  // Colors and styles
  const {
    primary,
    primaryVariant,
    red,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    darkMode,
  } = useColorsContext();
  const defaultStyles = useStyles();

  const { user } = useUserContext();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [minimumFeeError, setMinimumFeeError] = useState(null);
  const AnimatedText = Animated.createAnimatedComponent(Text);



  //  Create stylesheet
  const styles = StyleSheet.create({
    picker: {
      height: 50,
      color: text,
      borderRadius: defaultStyles.radius,
      fontFamily: defaultStyles.boldFont,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: backgroundVariant,
      border: "1px solid " + border,
    },
    label: {
      fontFamily: defaultStyles.boldFont,
      color: text,
      marginBottom: 10,
      fontSize: 16,
    },
    description: {
      fontFamily: defaultStyles.regularFont,
      color: text,
      marginBottom: 10,
      fontSize: 12,
    },
    error: {
      color: red,
      marginTop: 4,
      textAlign: 'center'
    }
  });

  const minimumFeeErrorText = useMemo(() => {
    if (minimumFeeError) {
      return <AnimatedText entering={FadeInDown} exiting={FadeOutDown} style={styles.error}>{minimumFeeError}</AnimatedText>
    } else {
      return null
    }
  }, [minimumFeeError])

  useEffect(() => {
    setLoading(false);
  }, [user]);



  if (loading) {
    return <LoadingPage />;
  }

  if (!user) {
    window.location.href = "/";
    return null;
  }

  return (
    <Menu>
      <MenuTrigger>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: instaBook
              ? primary
              : backgroundVariant,
            borderRadius: 16,
            border: "3px solid" + primary,
            paddingVertical: 10,
            paddingHorizontal: 25,
            marginRight: 15,
          }}
        >
          <Text
            style={{
              color: darkMode || instaBook ? "#fff" : "#000",
              fontSize: 16,
              fontFamily: 'Poppins_500Medium',
            }}
          >
            InstaBook {instaBook ? "on" : "off"}
          </Text>
        </View>
      </MenuTrigger>

      <MenuOptions
        optionsContainerStyle={{
          backgroundColor: backgroundVariant,
          borderRadius: defaultStyles.radius,
          padding: 15,
          marginLeft: 0,
          marginTop: 60,
          width: 300,
          shadowColor: "rgba(0, 0, 0, 0.1)",
          shadowOpacity: 0.8,
          shadowOffset: { width: 2, height: 2 },
          shadowRadius: 4,
        }}
        customStyles={{
          optionText: {
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
          },
        }}
      >
        <MenuOption
          onSelect={(e) => {
            return;
          }}
          disableTouchable={true}
        >
          <View>
            {/* Label */}
            <Text style={styles.label}>Instabook</Text>

            {/* Description */}
            <Text style={styles.description}>
              Turn Instabook on/off
            </Text>
            <TutredSwitch
              onChange={() => {
                // Check if user has already activated instabook in past via localStorage
                if (
                  localStorage.getItem("instaBook2") === null
                ) {
                  // If not, show tutorial
                  instaBookTutorial();
                  return;
                }

                updateDoc(doc(db, "users", userId), {
                  instaBook: !instaBook
                })
                  .then(() => { })
                  .catch((error) => { });
              }}
              checked={instaBook}
              inputProps={{
                'aria-labelledby': 'instabook-switch',
              }}
            />

            {/* <TutredSwitch
              size={20}
              onChange={() => {
                // Check if user has already activated instabook in past via localStorage
                if (
                  localStorage.getItem("instaBook2") === null
                ) {
                  // If not, show tutorial
                  instaBookTutorial();
                  return;
                }

                updateDoc(doc(db, "users", userId), {
                  instaBook: !instaBook
                })
                  .then(() => { })
                  .catch((error) => { });
              }}
              checked={instaBook}
            /> */}
          </View>
        </MenuOption>



        <MenuOption
          onSelect={(e) => {
            return;
          }}
          disableTouchable={true}
        >
          <View
            style={{
              marginTop: 15,
            }}
          >
            {/* Description */}
            <Text style={styles.description}>
              Click below for a quick tutorial on using InstaBook!
            </Text>

            {/* Action */}
            <TouchableOpacity
              onPress={() => {
                instaBookTutorial();
              }}
            >
              {/* Help Icon */}
              <FontAwesome5
                name="question-circle"
                size={24}
                color={primary}
                style={{}}
              />
            </TouchableOpacity>
          </View>
        </MenuOption>
      </MenuOptions>
    </Menu >
  );
};
