
const SleepyDino = ({ size = 400 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M327.384 155.74C334.984 157.532 347.976 158.06 355.732 157.384C356.336 157.332 357.048 157.32 357.416 157.828C357.642 158.259 357.709 158.756 357.604 159.232C356.804 166.76 354.368 181.176 354.736 188.744" fill="#4081F4" />
            <path d="M286.46 118.944L312 109.884C311.524 119.528 312.8 135.884 312.712 145.54" fill="#4081F4" />
            <path d="M238.704 102.832C243.178 96.4713 248.278 90.5752 253.928 85.2319C254.075 85.0583 254.259 84.9199 254.467 84.8271C254.675 84.7343 254.901 84.6895 255.128 84.6959C255.45 84.7669 255.734 84.9533 255.928 85.2199C261.68 91.8279 267.332 103.62 273.088 110.208" fill="#4081F4" />
            <path d="M191.936 118.904C192.447 111.647 193.517 104.44 195.136 97.3479C195.288 96.6719 195.536 95.8879 196.212 95.6999C196.744 95.5479 197.284 95.8599 197.748 96.1599C203.527 99.8932 209.225 103.76 214.844 107.76" fill="#4081F4" />
            <path d="M156.316 161.788C155.14 153.172 151.324 140.828 150.176 132.188C150.121 131.991 150.111 131.784 150.144 131.582C150.178 131.38 150.256 131.189 150.372 131.02C150.618 130.812 150.935 130.708 151.256 130.728C154.028 130.616 156.564 132.128 159 133.448C162.965 135.598 167.128 137.359 171.432 138.708" fill="#4081F4" />
            <path d="M171.72 258.928C171.72 258.928 31.416 267.228 19.656 258.36C7.89597 249.492 6.42797 229.56 17.744 213.632C22.74 206.6 41.344 204.284 64.2 203.752C68.904 203.64 79.772 192.264 84.34 191.32C102.864 187.496 101.992 201.816 107.42 201.996C123.492 202.532 138.164 180.768 138.164 180.768L171.72 258.928Z" fill="#F9CE46" />
            <path d="M86.184 215.144C151.916 206.62 153.932 109.144 236.832 102.02C319.732 94.8959 344.644 227.42 279.12 257.356C217.78 262.4 101.6 259.028 101.6 259.028C101.6 259.028 58.924 218.68 86.184 215.144Z" fill="#F9CE46" />
            <path d="M286.132 117.784C286.132 117.784 395.044 198.92 389.816 274.828C385.18 342.212 221.224 343.308 95.816 333.408C115.068 302.88 356.272 260.252 279.264 257.224C219.844 254.892 286.132 117.784 286.132 117.784Z" fill="#F9CE46" />
            <path d="M221.66 155.66C220.724 178.896 229.568 202.096 245.26 219.188C249.758 224.07 254.807 228.415 260.304 232.136L260.928 229.832C245.781 230.584 230.66 231.711 215.564 233.212C208.892 233.876 201.996 234.248 195.476 235.888C190.18 237.22 184.996 239.744 181.896 244.404C178.796 249.064 177.896 255.48 181.468 260.1C182.424 261.344 184.576 260.1 183.604 258.852C180.488 254.804 181.416 249.408 184.188 245.424C187.464 240.724 193.272 238.752 198.664 237.72C204.864 236.52 211.252 236.12 217.532 235.492C224.225 234.841 230.925 234.263 237.632 233.756C245.392 233.172 253.157 232.688 260.928 232.304C261.196 232.297 261.454 232.204 261.665 232.039C261.876 231.874 262.028 231.645 262.099 231.386C262.17 231.128 262.156 230.854 262.059 230.604C261.962 230.354 261.787 230.143 261.56 230C242.844 217.3 229.848 196.96 225.56 174.8C224.348 168.494 223.872 162.068 224.14 155.652C224.204 154.052 221.74 154.052 221.664 155.652L221.66 155.66Z" fill="#112870" />
            <path d="M132.336 224.156C135.033 229.56 138.099 234.772 141.512 239.756C143.136 242.116 144.792 245.008 146.872 246.996C147.54 247.636 148.3 247.58 147.172 247.592C146.424 247.592 145.676 247.592 144.932 247.592H140.08C134.936 247.592 129.356 247.704 125.28 251.316C121.884 254.336 121.04 259.46 124.036 263.04C125.06 264.264 126.804 262.504 125.784 261.288C123.9 259.036 124.488 255.74 126.38 253.688C128.34 251.568 131.232 250.632 134.028 250.288C136.76 249.948 139.556 250.064 142.308 250.064H146.8C147.684 250.064 148.724 250.184 149.496 249.664C151.324 248.396 149.54 246.42 148.62 245.264C146.74 242.882 144.947 240.436 143.24 237.924C139.989 233.123 137.06 228.112 134.472 222.924C133.76 221.5 131.624 222.752 132.336 224.176V224.156Z" fill="#112870" />
            <path d="M130.884 255.2C130.597 256.852 130.689 258.549 131.153 260.16C131.617 261.772 132.442 263.257 133.564 264.504C134.624 265.704 136.364 263.944 135.316 262.756C134.484 261.83 133.869 260.73 133.515 259.536C133.162 258.343 133.079 257.086 133.272 255.856C133.52 254.3 131.136 253.628 130.872 255.2H130.884Z" fill="#112870" />
            <path d="M137.976 256.936C137.93 258.824 138.557 260.667 139.744 262.136C140.144 262.668 141.06 262.56 141.496 262.136C142.024 261.62 141.896 260.912 141.496 260.384C140.758 259.395 140.389 258.18 140.452 256.948C140.5 255.348 138.024 255.348 137.976 256.948V256.936Z" fill="#112870" />
            <path d="M145.176 257.264L146.132 260.128C146.636 261.632 149.024 260.988 148.532 259.472L147.576 256.604C147.076 255.1 144.684 255.748 145.176 257.264Z" fill="#112870" />
            <path d="M190.54 249.916C190.109 252.399 190.433 254.955 191.471 257.252C192.508 259.549 194.212 261.482 196.36 262.8C197.72 263.636 198.964 261.496 197.608 260.664C195.919 259.631 194.573 258.122 193.74 256.326C192.908 254.53 192.625 252.528 192.928 250.572C193.184 249.016 190.8 248.344 190.528 249.916H190.54Z" fill="#112870" />
            <path d="M200.8 250.948C199.32 255.656 202.352 260.948 207.18 262C208.732 262.34 209.396 259.956 207.84 259.6C206.994 259.407 206.197 259.041 205.498 258.527C204.798 258.013 204.212 257.361 203.776 256.61C203.339 255.86 203.062 255.029 202.96 254.167C202.858 253.305 202.934 252.431 203.184 251.6C203.664 250.076 201.276 249.424 200.784 250.944L200.8 250.948Z" fill="#112870" />
            <path d="M212.648 255.172C213.651 257.086 215.008 258.793 216.648 260.2C217.848 261.224 219.612 259.484 218.396 258.448C216.919 257.183 215.698 255.645 214.8 253.92C214.06 252.512 211.924 253.76 212.664 255.172H212.648Z" fill="#112870" />
            <path d="M86.316 237.66C86.18 241.98 86.008 246.46 84.372 250.52C82.504 255.152 78.576 257.748 74.044 259.448C64.672 262.964 54.616 261.16 44.936 260.324C39.736 259.876 34.508 259.46 29.292 259.028C26.784 258.824 24.064 258.868 21.62 258.228C19.384 257.644 17.796 256.196 16.552 254.296C14.856 251.7 10.412 243.712 15.968 243.124C18.296 242.88 20.508 244.264 22.712 244.788C24.8934 245.257 27.1416 245.327 29.348 244.996C35.016 244.26 40.236 242.392 46.02 243.432C51.596 244.432 56.732 246.96 61.76 249.464C63.184 250.168 64.44 248.036 63.012 247.324C57.904 244.784 52.668 242.196 47.012 241.112C41.356 240.028 36.312 241.252 30.776 242.272C27.776 242.828 24.916 242.936 21.976 241.996C19.556 241.22 17.152 240.18 14.576 240.864C9.48404 242.208 10.576 247.376 12.148 251.064C13.792 254.968 16.012 259.064 20.304 260.436C22.648 261.196 25.276 261.176 27.708 261.38L37.876 262.212C44.028 262.72 50.172 263.368 56.328 263.812C61.68 264.212 67.084 264.136 72.276 262.632C77.64 261.076 83.076 258.36 85.808 253.24C88.328 248.488 88.608 242.924 88.768 237.64C88.82 236.04 86.344 236.04 86.296 237.64L86.316 237.66Z" fill="#112870" />
            <path d="M11.82 241.528C11.232 241.528 11.248 237.972 11.264 237.64C11.3722 236.346 11.6039 235.065 11.956 233.816C12.52 231.644 13.188 229.492 13.86 227.352C15.284 222.8 16.984 218.4 19.568 214.4C20.432 213.056 18.288 211.816 17.428 213.152C14.024 218.452 12.112 224.456 10.344 230.456C9.54402 233.224 8.63202 236.108 8.78002 239.032C8.88002 240.98 9.34802 244.004 11.82 244.016C13.42 244.016 13.42 241.552 11.82 241.544V241.528Z" fill="#112870" />
            <path d="M28.364 216.684C28.424 215.964 29.48 215.276 30.128 215.708C30.624 216.036 30.76 217.06 30.272 217.456C29.784 217.852 28.82 217.412 28.712 216.824C28.66 216.54 28.848 216.096 29.172 216.076C29.26 216.09 29.3451 216.118 29.424 216.16C29.476 216.18 29.336 216.224 29.472 216.212L30.672 217.12C30.5733 216.874 30.528 216.61 30.5391 216.345C30.5501 216.08 30.6172 215.821 30.736 215.584C30.992 214.968 30.456 214.224 29.872 214.06C29.5557 213.981 29.2212 214.027 28.9376 214.188C28.654 214.348 28.4429 214.612 28.348 214.924C27.9769 215.834 27.9541 216.85 28.284 217.776C28.3596 218.037 28.5171 218.266 28.7332 218.43C28.9493 218.594 29.2126 218.685 29.484 218.688C30.0924 218.657 30.6695 218.409 31.1101 217.988C31.5506 217.567 31.8253 217.002 31.884 216.396C31.9229 215.788 31.7478 215.186 31.389 214.695C31.0302 214.203 30.5104 213.852 29.92 213.704C27.38 213.008 25.392 216.02 26.624 218.2C27.704 220.108 30.496 220.768 32.1 219.128C33.58 217.616 33.244 214.852 31.528 213.668C29.176 212.068 26.1 214.088 25.892 216.684C25.768 218.284 28.244 218.264 28.368 216.684H28.364Z" fill="#112870" />
            <path d="M78.676 204.612C80.708 205.861 82.9977 206.63 85.3714 206.863C87.7451 207.095 90.1406 206.783 92.376 205.952C93.856 205.4 93.216 203.008 91.72 203.552C89.8035 204.292 87.7417 204.578 85.6959 204.389C83.6502 204.199 81.6761 203.539 79.928 202.46C78.564 201.628 77.316 203.768 78.676 204.6V204.612Z" fill="#112870" />
            <path d="M41.056 243.312L42.34 245.312C42.4524 245.495 42.6097 245.646 42.7971 245.75C42.9844 245.855 43.1954 245.91 43.41 245.91C43.6246 245.91 43.8356 245.855 44.0229 245.75C44.2103 245.646 44.3676 245.495 44.48 245.312C44.988 244.465 45.5891 243.677 46.272 242.964C47.372 241.812 45.624 240.06 44.524 241.212C43.6928 242.079 42.9603 243.035 42.34 244.064H44.48L43.196 242.064C42.336 240.732 40.192 241.972 41.056 243.316V243.312Z" fill="#112870" />
            <path d="M30.768 243.86C30.9316 242.764 31.3212 241.714 31.912 240.776H29.772L31.748 243.872C32.6 245.212 34.744 243.972 33.884 242.624L31.912 239.528C31.7997 239.345 31.6423 239.194 31.455 239.089C31.2677 238.985 31.0566 238.93 30.842 238.93C30.6274 238.93 30.4164 238.985 30.2291 239.089C30.0418 239.194 29.8844 239.345 29.772 239.528C29.0725 240.647 28.6058 241.896 28.4 243.2C28.3235 243.517 28.3707 243.851 28.532 244.134C28.6933 244.417 28.9565 244.628 29.268 244.724C29.856 244.884 30.684 244.524 30.788 243.86H30.768Z" fill="#112870" />
            <path d="M54.956 244.192L57.912 239.092L55.6 238.464C55.7446 240.383 55.7793 242.309 55.704 244.232C55.64 245.832 58.104 245.832 58.18 244.232C58.256 242.309 58.2199 240.383 58.072 238.464C57.984 237.292 56.416 236.72 55.768 237.84L52.812 242.944C52.012 244.324 54.148 245.572 54.948 244.192H54.956Z" fill="#112870" />
            <path d="M56.864 243.4C56.7827 242.957 56.8429 242.499 57.036 242.092C57.1937 241.806 57.2355 241.471 57.1529 241.155C57.0702 240.84 56.8694 240.568 56.592 240.396C56.3081 240.233 55.9717 240.189 55.6552 240.272C55.3387 240.355 55.0676 240.559 54.9 240.84C54.4039 241.829 54.2559 242.956 54.48 244.04C54.5231 244.197 54.5968 244.345 54.6969 244.474C54.797 244.603 54.9215 244.711 55.0634 244.792C55.2053 244.873 55.3617 244.926 55.5238 244.946C55.6859 244.967 55.8504 244.955 56.008 244.912C56.1656 244.869 56.3132 244.795 56.4423 244.695C56.5714 244.595 56.6795 244.47 56.7605 244.328C56.8414 244.187 56.8937 244.03 56.9142 243.868C56.9347 243.706 56.9231 243.541 56.88 243.384L56.864 243.4Z" fill="#112870" />
            <path d="M20.548 244.92C21.1227 245.556 21.5877 246.283 21.924 247.072C22.324 248.016 24.016 247.792 24.188 246.776L24.648 244.032C24.7245 243.715 24.6773 243.381 24.516 243.098C24.3547 242.815 24.0915 242.604 23.78 242.508C23.464 242.424 23.1277 242.468 22.8434 242.629C22.5592 242.791 22.3497 243.058 22.26 243.372L21.8 246.116L24.06 245.82C23.622 244.846 23.0274 243.95 22.3 243.168C21.22 241.992 19.472 243.744 20.548 244.92Z" fill="#112870" />
            <path d="M16.8 242.108L17.696 239.2L15.264 238.872C15.3363 240.102 15.636 241.308 16.148 242.428C16.42 243.036 17.308 243.184 17.84 242.872C18.1174 242.7 18.3182 242.428 18.4008 242.113C18.4835 241.797 18.4417 241.462 18.284 241.176C17.9644 240.447 17.7801 239.667 17.74 238.872C17.66 237.492 15.74 237.172 15.308 238.54L14.4 241.452C13.936 242.976 16.324 243.632 16.8 242.108Z" fill="#112870" />
            <path d="M68.952 242.608C69.3724 243.177 69.6314 243.849 69.7018 244.553C69.7722 245.257 69.6514 245.967 69.352 246.608C68.668 248.036 70.8 249.296 71.488 247.856C72.0053 246.838 72.241 245.701 72.1707 244.562C72.1003 243.422 71.7265 242.322 71.088 241.376C70.188 240.072 68.044 241.308 68.948 242.624L68.952 242.608Z" fill="#112870" />
            <path d="M109.096 324.884L119.968 304.4C120.332 305.756 121.168 306.924 121.996 308.056L127.292 315.34L109.096 324.884Z" fill="#4081F4" />
            <path d="M150.62 306C153.08 299.304 155.588 292.524 159.688 286.688C164.24 291.253 168.976 295.628 173.896 299.812L150.62 306Z" fill="#4081F4" />
            <path d="M205.872 290.268L211.788 273.956C212.412 272.236 213.048 270.492 214.164 269.044C214.249 268.897 214.365 268.771 214.503 268.672C214.641 268.574 214.798 268.505 214.964 268.472C215.284 268.495 215.584 268.638 215.804 268.872C220.828 273.116 230.072 277.588 235.096 281.832L205.872 290.268Z" fill="#4081F4" />
            <path d="M262.54 273.6C267.036 264.848 267.716 254.516 272.24 245.78C277.704 251.86 286.132 259.148 292.044 264.788L262.54 273.6Z" fill="#4081F4" />
            <path d="M311.504 257.064L314.332 225.864C320.78 231.197 327.125 236.644 333.368 242.204L311.504 257.064Z" fill="#4081F4" />
            <path d="M346.224 229.86C339 235.86 345.996 207.808 346.152 198.432C353.072 198.652 361.352 201.256 368.228 205.38C368.228 205.38 355.864 221.884 346.224 229.86Z" fill="#4081F4" />
            <path d="M90.924 191.876C93.9731 192.022 96.9076 193.082 99.3454 194.92C101.783 196.757 103.611 199.285 104.592 202.176C105.088 203.684 107.48 203.036 106.992 201.52C105.857 198.112 103.715 195.129 100.848 192.966C97.9807 190.802 94.5243 189.56 90.936 189.404C89.336 189.336 89.336 191.804 90.936 191.876H90.924Z" fill="#112870" />
            <path d="M151.76 172.42C153.305 175.27 155.505 177.712 158.18 179.544C159.492 180.456 160.732 178.312 159.428 177.408C157.116 175.797 155.22 173.66 153.896 171.172C153.14 169.768 151 171.016 151.76 172.42Z" fill="#FF7348" />
            <path d="M173.092 144.432C174.95 148.612 178.138 152.06 182.16 154.24C183.56 155.004 184.808 152.868 183.408 152.104C179.767 150.128 176.889 146.995 175.228 143.2C174.584 141.748 172.452 143.004 173.092 144.448V144.432Z" fill="#FF7348" />
            <path d="M197.6 122.832C199.114 126.631 201.515 130.012 204.604 132.692C205.804 133.728 207.56 131.984 206.352 130.944C203.563 128.57 201.381 125.564 199.988 122.176C199.396 120.72 197 121.356 197.588 122.832H197.6Z" fill="#FF7348" />
            <path d="M231.424 108.496C231.216 110.731 231.515 112.983 232.299 115.086C233.083 117.189 234.332 119.087 235.952 120.64C237.096 121.752 238.848 120.004 237.7 118.892C236.316 117.563 235.253 115.935 234.595 114.132C233.936 112.33 233.698 110.401 233.9 108.492C234.048 106.892 231.572 106.916 231.424 108.492V108.496Z" fill="#FF7348" />
            <path d="M263.9 123.164C263.94 121.326 264.354 119.516 265.117 117.843C265.879 116.171 266.975 114.671 268.336 113.436C269.508 112.348 267.756 110.604 266.588 111.684C264.998 113.15 263.72 114.923 262.833 116.896C261.945 118.869 261.466 121.001 261.424 123.164C261.384 124.764 263.856 124.764 263.9 123.164Z" fill="#FF7348" />
            <path d="M286 133.512C286.319 132.661 286.866 131.914 287.582 131.353C288.297 130.792 289.153 130.439 290.056 130.332C291.624 130.168 291.656 127.692 290.056 127.856C288.614 127.983 287.239 128.522 286.094 129.409C284.95 130.295 284.084 131.492 283.6 132.856C283.052 134.352 285.444 135 286 133.512Z" fill="#FF7348" />
            <path d="M300.368 144.3L303.7 142.96C304.011 142.864 304.274 142.653 304.435 142.37C304.595 142.086 304.642 141.752 304.564 141.436C304.4 140.848 303.664 140.324 303.04 140.572L299.712 141.912C299.401 142.008 299.138 142.219 298.977 142.502C298.817 142.786 298.77 143.12 298.848 143.436C299.008 144.024 299.748 144.552 300.368 144.3Z" fill="#FF7348" />
            <path d="M310.72 156.4C312.32 156.4 312.32 153.924 310.72 153.924C309.12 153.924 309.12 156.4 310.72 156.4Z" fill="#FF7348" />
            <path d="M320.712 167.708C322.312 167.708 322.312 165.232 320.712 165.232C319.112 165.232 319.112 167.708 320.712 167.708Z" fill="#FF7348" />
            <path d="M330.04 180.368H330.708C331.024 180.35 331.321 180.212 331.539 179.982C331.756 179.751 331.878 179.447 331.878 179.13C331.878 178.813 331.756 178.509 331.539 178.279C331.321 178.048 331.024 177.91 330.708 177.892H330.04C329.724 177.91 329.426 178.048 329.209 178.279C328.992 178.509 328.87 178.813 328.87 179.13C328.87 179.447 328.992 179.751 329.209 179.982C329.426 180.212 329.724 180.35 330.04 180.368Z" fill="#FF7348" />
            <path d="M238.648 155.776C240.248 155.776 240.248 153.3 238.648 153.3C237.048 153.3 237.048 155.776 238.648 155.776Z" fill="#112870" />
            <path d="M236.18 163.876C237.78 163.876 237.78 161.404 236.18 161.404C234.58 161.404 234.58 163.876 236.18 163.876Z" fill="#112870" />
            <path d="M240.056 175.148C241.656 175.148 241.656 172.672 240.056 172.672C238.456 172.672 238.456 175.148 240.056 175.148Z" fill="#112870" />
            <path d="M253.44 170.216C255.04 170.216 255.04 167.74 253.44 167.74C251.84 167.74 251.84 170.216 253.44 170.216Z" fill="#112870" />
            <path d="M242.168 164.932C243.768 164.932 243.768 162.46 242.168 162.46C240.568 162.46 240.568 164.932 242.168 164.932Z" fill="#112870" />
            <path d="M251.324 152.608C252.924 152.608 252.924 150.132 251.324 150.132C249.724 150.132 249.724 152.608 251.324 152.608Z" fill="#112870" />
            <path d="M251.324 188.532C252.924 188.532 252.924 186.056 251.324 186.056C249.724 186.056 249.724 188.532 251.324 188.532Z" fill="#112870" />
            <path d="M143.904 221.636C145.504 221.636 145.504 219.164 143.904 219.164C142.304 219.164 142.304 221.636 143.904 221.636Z" fill="#112870" />
            <path d="M147.428 232.204C149.028 232.204 149.028 229.728 147.428 229.728C145.828 229.728 145.828 232.204 147.428 232.204Z" fill="#112870" />
            <path d="M154.824 222.696C156.424 222.696 156.424 220.22 154.824 220.22C153.224 220.22 153.224 222.696 154.824 222.696Z" fill="#112870" />
            <path d="M96.94 334.356C111.944 321.252 130.828 314.124 149.74 308.672C166.968 303.7 184.404 299.472 201.656 294.584C219.828 289.432 237.936 284.032 255.92 278.252C272.28 272.992 288.72 267.58 304.444 260.652C313.276 256.818 321.723 252.153 329.672 246.72C338.724 240.444 346.94 233.084 354.556 225.144C355.66 223.992 353.912 222.24 352.804 223.392C339.692 237.064 324.804 248.372 307.656 256.476C292.136 263.812 275.684 269.24 259.384 274.552C241.416 280.404 223.308 285.84 205.136 291.04C187.888 295.972 170.496 300.34 153.212 305.136C132.484 310.892 111.652 318.264 95.212 332.624C94.012 333.676 95.768 335.424 96.964 334.376L96.94 334.356Z" fill="#FF7348" />
            <path d="M141.64 326.992C143.24 326.992 143.24 324.516 141.64 324.516C140.04 324.516 140.04 326.992 141.64 326.992Z" fill="#112870" />
            <path d="M153.576 321.284C155.176 321.284 155.176 318.808 153.576 318.808C151.976 318.808 151.976 321.284 153.576 321.284Z" fill="#112870" />
            <path d="M151.2 328.348V327.832C151.2 327.504 151.07 327.19 150.838 326.958C150.606 326.726 150.292 326.596 149.964 326.596C149.636 326.596 149.322 326.726 149.09 326.958C148.858 327.19 148.728 327.504 148.728 327.832V328.348C148.728 328.676 148.858 328.99 149.09 329.222C149.322 329.454 149.636 329.584 149.964 329.584C150.292 329.584 150.606 329.454 150.838 329.222C151.07 328.99 151.2 328.676 151.2 328.348Z" fill="#112870" />
            <path d="M164.476 325.956C166.076 325.956 166.076 323.48 164.476 323.48C162.876 323.48 162.876 325.956 164.476 325.956Z" fill="#112870" />
            <path d="M183.676 329.068C185.276 329.068 185.276 326.592 183.676 326.592C182.076 326.592 182.076 329.068 183.676 329.068Z" fill="#112870" />
            <path d="M370.92 266.728C374.551 274.35 375.479 282.981 373.552 291.2C373.192 292.748 375.576 293.408 375.952 291.856C378.024 282.991 377.008 273.685 373.072 265.476C372.384 264.04 370.272 265.292 370.932 266.724L370.92 266.728Z" fill="#FF7348" />
            <path d="M354.244 290.276C354.752 293.775 354.912 297.317 354.72 300.848C354.64 302.448 357.12 302.448 357.196 300.848C357.38 297.096 357.192 293.335 356.632 289.62C356.4 288.048 354.016 288.716 354.232 290.276H354.244Z" fill="#FF7348" />
            <path d="M340.36 305.624L340.88 306.66C341.047 306.942 341.318 307.147 341.635 307.23C341.952 307.313 342.288 307.268 342.572 307.104C342.848 306.932 343.048 306.661 343.131 306.347C343.214 306.032 343.172 305.697 343.016 305.412L342.496 304.372C342.329 304.09 342.058 303.886 341.741 303.802C341.424 303.719 341.088 303.764 340.804 303.928C340.526 304.099 340.324 304.371 340.242 304.687C340.159 305.003 340.201 305.339 340.36 305.624Z" fill="#FF7348" />
            <path d="M326.896 315.056C328.496 315.056 328.496 312.58 326.896 312.58C325.296 312.58 325.296 315.056 326.896 315.056Z" fill="#FF7348" />
            <path d="M307.496 319.008V320.048C307.514 320.364 307.652 320.661 307.882 320.879C308.113 321.096 308.417 321.218 308.734 321.218C309.051 321.218 309.355 321.096 309.586 320.879C309.816 320.661 309.954 320.364 309.972 320.048V319.008C309.954 318.692 309.816 318.395 309.586 318.177C309.355 317.96 309.051 317.838 308.734 317.838C308.417 317.838 308.113 317.96 307.882 318.177C307.652 318.395 307.514 318.692 307.496 319.008Z" fill="#FF7348" />
            <path d="M290.572 322.84C292.172 322.84 292.172 320.364 290.572 320.364C288.972 320.364 288.972 322.84 290.572 322.84Z" fill="#FF7348" />
            <path d="M40.8 144.644C45.332 142.904 50.4 142.812 54.884 144.796V140.52C47.8785 143.977 41.6496 148.824 36.576 154.764C35.5 156.028 35.672 158.576 37.668 158.904C45.3569 160.162 53.1979 160.178 60.892 158.952C62.216 158.74 62.948 157.092 62.62 155.904C62.22 154.496 60.904 153.968 59.576 154.176C52.7529 155.252 45.802 155.236 38.984 154.128L40.08 158.268C44.8657 152.642 50.7553 148.058 57.384 144.8C58.952 144.028 59.052 141.264 57.384 140.524C51.716 138.012 45.32 137.632 39.484 139.872C38.236 140.352 37.376 141.54 37.756 142.92C38.084 144.12 39.548 145.128 40.8 144.648V144.644Z" fill="#FF005D" />
            <path d="M60.756 108.504C66.9067 107.666 73.1686 108.316 79.016 110.4L78.424 105.876C73.392 109.447 68.192 112.763 62.824 115.824C61.256 116.72 61.172 119.228 62.824 120.1C69.0094 123.393 75.992 124.892 82.984 124.428C86.144 124.204 86.184 119.252 82.984 119.476C76.8703 119.935 70.7506 118.669 65.32 115.824V120.1C70.68 117.039 75.88 113.723 80.92 110.152C82.692 108.896 82.416 106.356 80.328 105.628C73.6195 103.318 66.4524 102.662 59.436 103.716C58.112 103.904 57.388 105.588 57.708 106.76C58.108 108.184 59.424 108.68 60.752 108.488L60.756 108.504Z" fill="#FF005D" />
            <path d="M88.484 80C94.4828 79.8272 100.359 81.7178 105.132 85.356L106.224 81.22C100.634 82.8111 95.1919 84.8842 89.96 87.416C88.5 88.12 88.412 90.248 89.46 91.304C94.5534 96.4232 101.44 99.3585 108.66 99.488C111.86 99.548 111.86 94.596 108.66 94.536C102.756 94.4192 97.1308 92.004 92.98 87.804L92.48 91.692C97.3268 89.3647 102.366 87.4603 107.54 86C109.14 85.54 110.16 83.056 108.632 81.864C102.906 77.3322 95.7849 74.9288 88.484 75.064C85.284 75.14 85.284 80.088 88.484 80.016V80Z" fill="#FF005D" />
            <path d="M27.624 209.396C23.7686 206.367 20.8403 202.318 19.172 197.708C18.344 195.404 17.84 192.908 18.32 190.508C18.992 187.156 21.488 184.444 24.268 182.456C26.176 181.096 28.316 179.972 30.632 179.612C35.368 178.884 40.312 181.712 42.484 185.984C44.656 190.256 44.176 195.64 41.684 199.724C39.192 203.808 32.268 208.28 27.6 209.368" fill="#75DDF9" />
            <path d="M26.4 186.528C21.944 189.656 20.584 195.992 23.656 200.572C24.54 201.892 26.684 200.652 25.792 199.324C23.516 195.928 24.24 191.056 27.648 188.664C28.944 187.756 27.708 185.608 26.4 186.528Z" fill="white" />
            <path d="M37.34 315.6C33.144 315.308 31.2 311.112 30.4 307.524C29.82 304.976 29.012 301.62 30.072 299.092C31.824 294.92 36.208 300.156 37.672 301.952C40.0585 304.9 41.9498 308.217 43.272 311.772C43.3595 312.09 43.5699 312.361 43.8568 312.524C44.1437 312.687 44.4837 312.73 44.802 312.642C45.1203 312.555 45.3907 312.344 45.5539 312.057C45.717 311.77 45.7595 311.43 45.672 311.112C45.102 308.116 44.978 305.052 45.304 302.02C45.592 299.42 46.104 296.212 47.992 294.248C48.844 293.364 50.136 292.648 51.408 292.8C53.152 293 53.936 294.484 54.444 296C55.356 298.672 55.992 301.468 56.752 304.188C56.8155 304.39 56.9293 304.572 57.0826 304.718C57.236 304.864 57.4239 304.968 57.6286 305.021C57.8334 305.074 58.0483 305.075 58.2532 305.022C58.4581 304.969 58.6463 304.865 58.8 304.72C62.448 300.972 69.776 297.192 74.948 300.108C80.948 303.484 79.04 311.752 77.652 317.06C77.252 318.604 79.652 319.264 80.052 317.72C81.74 311.256 83.316 301.956 76.208 297.968C70.02 294.5 61.528 298.368 57.064 302.972L59.132 303.516C58.54 301.408 57.956 299.296 57.364 297.184C56.924 295.616 56.512 293.984 55.548 292.628C53.728 290.104 50.452 289.744 47.824 291.248C44.9 292.924 43.74 296.4 43.176 299.528C42.4623 303.581 42.4976 307.731 43.28 311.772L45.68 311.112C43.9162 306.313 41.1943 301.922 37.68 298.208C35.16 295.568 30.616 293.228 28.192 297.352C26.192 300.788 27.344 305.972 28.368 309.52C29.6 313.84 32.56 317.744 37.34 318.064C38.94 318.172 38.94 315.696 37.34 315.592V315.6Z" fill="#112870" />
            <path d="M30.688 309.5C27.44 309.46 24.932 312.024 24.212 315.1C23.272 319.1 25.556 322.912 27.608 326.14C28.456 327.484 30.6 326.244 29.744 324.892C28.244 322.528 26.476 319.892 26.468 316.984C26.468 314.584 28.068 311.956 30.688 311.988C32.288 311.988 32.288 309.532 30.688 309.512V309.5Z" fill="#112870" />
            <path d="M308.596 366.664C302.64 365.968 297.16 361.22 295.972 355.264C295.848 354.632 295.628 353.664 295.92 353.02C296.344 352.124 297.068 352.176 297.956 352.432C299.68 352.928 301.44 354.088 302.94 355.048C305.812 356.915 308.376 359.217 310.54 361.872L312.292 360.12C307.644 354.84 308.548 346.6 314.46 342.672C315.731 341.814 317.16 341.218 318.664 340.92C321.012 340.468 322.504 341.28 323.416 343.444C324.944 347.044 325.34 351.104 325.044 354.988C324.948 356.216 326.792 356.668 327.348 355.612C330.232 350.14 333.804 343.772 339.912 341.436C343.656 340.004 347.484 340.732 348.82 344.856C350.02 348.524 348.348 352.4 346.988 355.78C346.532 356.916 348.292 357.912 349.06 356.98C351.86 353.568 356.724 351.324 360.788 354.096C364.456 356.6 364.24 362.716 359.392 363.612C357.828 363.9 358.488 366.284 360.048 366.012C366.008 364.912 367.564 357.296 363.584 353.236C358.784 348.316 351.28 350.436 347.308 355.236L349.376 356.436C351.024 352.336 352.676 347.764 350.92 343.42C349.448 339.776 346.164 337.78 342.24 338.296C333.84 339.404 328.816 347.524 325.212 354.36L327.52 354.984C327.872 350.388 327.456 344.22 324.544 340.396C321.932 336.968 316.856 338.328 313.7 340.22C305.996 344.84 304.688 355.22 310.54 361.868C311.604 363.068 313.296 361.328 312.292 360.116C309.201 356.36 305.355 353.295 301.004 351.12C299.348 350.32 297.152 348.996 295.3 349.94C293.948 350.628 293.408 352.196 293.332 353.62C293.14 357.308 295.4 361.22 297.852 363.808C300.687 366.795 304.487 368.682 308.58 369.136C310.18 369.324 310.144 366.844 308.58 366.66L308.596 366.664Z" fill="#112870" />
            <path d="M294.936 361.452C293.068 361.908 292.488 364.324 293.736 365.688C293.851 365.803 293.987 365.894 294.137 365.957C294.287 366.019 294.448 366.051 294.611 366.052C294.773 366.052 294.934 366.02 295.084 365.958C295.234 365.896 295.371 365.805 295.486 365.69C295.601 365.575 295.692 365.439 295.755 365.289C295.817 365.139 295.849 364.978 295.849 364.816C295.85 364.653 295.818 364.492 295.756 364.342C295.694 364.192 295.603 364.055 295.488 363.94C295.564 364.052 295.58 364.072 295.532 363.996C295.484 363.92 295.496 363.944 295.532 363.996C295.508 364.136 295.532 363.936 295.508 363.872L295.532 364.012C295.532 363.892 295.532 363.88 295.532 363.98C295.532 364.08 295.556 363.904 295.568 363.86C295.596 363.716 295.608 363.8 295.568 363.884C295.736 363.62 295.464 364.008 295.568 363.856C295.672 363.704 295.708 363.792 295.568 363.836C295.812 363.752 295.344 363.888 295.628 363.836C295.786 363.793 295.933 363.719 296.062 363.619C296.191 363.519 296.3 363.395 296.38 363.253C296.461 363.111 296.514 362.954 296.534 362.792C296.555 362.63 296.543 362.466 296.5 362.308C296.457 362.151 296.383 362.003 296.283 361.874C296.183 361.745 296.059 361.637 295.917 361.556C295.775 361.475 295.618 361.422 295.456 361.402C295.294 361.381 295.13 361.393 294.972 361.436L294.936 361.452Z" fill="#112870" />
            <path d="M142.524 364.8C145.856 361.2 147.292 356.252 150.784 352.8C152.716 350.9 155.184 349.736 157.92 350.464C160.368 351.12 162.364 352.972 164.12 354.708C166.056 356.624 167.948 358.596 169.824 360.572C171.488 362.32 172.684 364.572 169.932 365.888C167.968 366.84 165.696 366.828 163.564 366.948L154.46 367.472C152.86 367.564 152.86 370.04 154.46 369.948C158.252 369.728 162.06 369.576 165.84 369.296C168.072 369.128 170.508 368.732 172.316 367.296C177.628 363.1 170.172 357.364 167.3 354.408C163.408 350.408 158.328 345.828 152.396 348.624C146.464 351.42 144.972 358.492 140.776 363.024C139.692 364.192 141.44 365.944 142.524 364.772V364.8Z" fill="#112870" />
            <path d="M170.456 358.048C172.62 356.016 175.932 356.38 178.304 357.868C181 359.564 182.704 362.456 184.332 365.104C185.164 366.46 187.304 365.22 186.468 363.856C184.356 360.404 181.992 356.656 178.12 354.996C174.96 353.644 171.264 353.892 168.704 356.3C167.54 357.392 169.296 359.14 170.456 358.048Z" fill="#112870" />
            <path d="M194.476 364.636C196.076 364.636 196.076 362.164 194.476 362.164C192.876 362.164 192.876 364.636 194.476 364.636Z" fill="#112870" />
            <path d="M181.452 39.08C181.32 36.884 182.824 34.852 184.708 33.716C186.592 32.58 188.808 32.168 190.972 31.772C193.748 31.268 196.788 30.804 199.252 32.172C201.716 33.54 202.696 37.612 200.312 39.12C204.831 37.3883 209.744 36.9559 214.496 37.872C217.04 38.368 219.632 39.332 221.296 41.32C224.896 45.624 222.18 52.708 217.368 55.592C212.556 58.476 206.592 58.36 200.968 58.156L184.504 57.564C179.896 57.4 175.216 57.22 170.828 55.796C166.44 54.372 162.308 51.516 160.428 47.312C160.136 46.7746 160.018 46.1593 160.092 45.552C160.256 44.792 160.92 44.256 161.568 43.824C168.28 39.328 177.648 39.192 184.488 43.496" fill="#E3E5E5" />
            <path d="M319.6 59.8961C323.556 58.6001 327.412 56.8161 331.552 56.3601C335.692 55.9041 340.352 57.1161 342.628 60.6001C344.904 64.0841 343.244 69.8761 339.128 70.5161L346.068 73.2281C346.705 73.4218 347.287 73.7631 347.768 74.2241C349.392 76.0601 346.968 78.7441 344.704 79.6801C337.66 82.5921 329.68 82.0161 322.172 80.7041C316.44 79.7041 310.32 78.0121 306.78 73.3921C306.116 72.5241 305.544 71.5161 305.52 70.4201C305.468 67.9401 308.352 66.2321 310.812 66.5481C313.272 66.8641 315.32 68.5241 317.212 70.1241C314.924 68.0281 314.672 64.0841 316.676 61.7241C318.68 59.3641 322.608 58.9241 325.076 60.8441" fill="#E3E5E5" />
            <path d="M82.172 154.288C81.372 151.824 81.752 148.936 83.424 146.964C85.096 144.992 88.124 144.164 90.432 145.364C92.74 146.564 93.824 149.764 92.432 151.956C95.468 151.096 98.324 149.556 101.452 149.128C104.58 148.7 108.272 149.804 109.48 152.728C110.452 155.064 109.48 157.864 107.688 159.664C105.896 161.464 103.48 162.464 101.08 163.264C95.38 165.184 89.228 166.328 83.336 165.104C80.912 164.604 78.348 163.504 77.308 161.26C76.8862 160.358 76.7553 159.347 76.9335 158.368C77.1116 157.388 77.59 156.488 78.3024 155.792C79.0148 155.097 79.9258 154.64 80.9094 154.485C81.893 154.33 82.9004 154.485 83.792 154.928" fill="#E3E5E5" />
        </svg>
    );
};

export default SleepyDino;
