import * as React from 'react'
import Box from '@mui/material/Box'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useUserContext } from '@contexts/userContext'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { FilterType } from 'dataTypes'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { FlatList } from 'react-native'
import { TutorListCard } from '@components/TutorListCard'
import useNavigateToChat from '@hooks/useNavigateToChat'
import { subjectData, subjects } from '@config/subjects'
import { provinces, statesUSA } from '@config/locations'
import { levels } from '@config/levels'
import { exams, examData } from '@config/exams'
import {
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useColorsContext } from '@utils/styles'
import EmptyTutorList from './EmptyTutorList'
import { TutorListLargeDrawer } from './TutorListLargeDrawer'
import { useDialog } from '@contexts/dialogContext'

const locationOptions = [...provinces, ...statesUSA]

const defaultFilterState = {
    subjects: {
        type: 'array',
        value: [],
        label: 'Subjects',
        placeholder: 'Choose a subject/subjects',
        options: subjects,
        optionType: 'string',
        valueType: 'keyIndex',
        query: `array-contains-any`,
    },
    rate: {
        type: 'range',
        value: [0, 150],
        label: 'Price',
        maxValue: 150,
        minValue: 0,
        query: 'range',
    },
    skills: {
        type: 'array',
        value: [],
        label: 'Specialties',
        placeholder: 'Choose a specialty/specialties',
        options: subjectData,
        optionType: 'object',
        query: 'array-contains-any',
        tip: 'Specialties are skills within a subject that a tutor is particularly good at.',
    },
    ['location.province']: {
        type: 'array',
        value: [],
        label: 'Location',
        placeholder: 'Choose a location',
        query: 'in',
        options: [...locationOptions],
        optionType: 'string',
    },
    rating: {
        type: 'range',
        value: [0, 0],
        maxValue: 5,
        minValue: 0,
        label: 'Rating',
        query: 'rating',
    },
    instaBook: {
        type: 'boolean',
        value: null,
        label: 'InstaBook',
        query: '==',
    },
    isVerified: {
        type: 'boolean',
        value: null,
        label: 'Verified',
        query: '==',
        tip: 'Tutors that have been ID verified by Tutred.',
    },
    examTutor: {
        type: 'array',
        value: [],
        label: 'Exams',
        placeholder: 'Choose an exam/exams',
        options: examData,
        optionType: 'object',
        query: 'array-contains-any',
        tip: 'Tutors that have experience with these exams.',
    },
}

export default function ClippedDrawer() {
    const navigation: any = useNavigation()
    const { user } = useUserContext()
    const { background, text } = useColorsContext()
    const [tutors, setTutors] = React.useState([])
    const [sortBy, setSortBy] = React.useState('online')
    const [loading, setLoading] = React.useState(true)
    const [filters, setFilters] =
        React.useState<Record<string, FilterType>>(defaultFilterState)
    const { db } = useFirebaseContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const route = useRoute()
    const { openLoginDialog } = useDialog()
    React.useEffect(() => {
        if (route.params?.subject) {
            setFilters({
                ...filters,
                subjects: {
                    ...filters.subjects,
                    value: [route.params.subject],
                },
            })
        }
    }, [route])

    const resetFilters = () => {
        setFilters(defaultFilterState)
    }

    const fetchTutors = async () => {
        setLoading(true)
        let tutorsRef = collection(db, 'users')
        let q = query(tutorsRef)
        q = query(q, where('isTutor', '==', true))
        q = query(q, where('isOnboarded', '==', true))

        const queryResults = {}
        Object.keys(filters).forEach((key) => {
            if (
                filters[key].value === null ||
                filters[key].value.length === 0 ||
                filters[key].value === false
            )
                return
            // Firebase only supports "array-contains-any" filter per query and it also cant be combined with 'in' for the same query,
            // If we run querys on data stored like "Key": true, and use "==" queries" it requires a very large amount of indexes to work.
            // This isnt ideal so we have to handle these locally.
            if (
                filters[key].query === 'array-contains-any' ||
                filters[key].query === 'availability' ||
                filters[key].query === 'rating'
            ) {
                return
            }

            if (filters[key].query === 'range') {
                if (
                    filters[key].value[0] === filters[key].minValue &&
                    filters[key].value[1] === filters[key].maxValue
                )
                    return

                q = query(q, where(key, '>=', filters[key].value[0]))
                if (filters[key].value[1] !== filters[key].maxValue) {
                    q = query(q, where(key, '<=', filters[key].value[1]))
                }
            } else {
                let filterValue = filters[key].value

                if (filters[key].optionType === 'object') {
                    filterValue = filters[key].value.map((val) => val.id)
                }
            }
        })

        onSnapshot(q, async (snapshot) => {
            let results = snapshot.docs.map((doc) => doc.data())
            Object.keys(filters).forEach((key) => {
                if (
                    filters[key].value === null ||
                    filters[key].value.length === 0
                )
                    return
                if (filters[key].query === 'array-contains-any') {
                    let filterValue = filters[key].value

                    if (filters[key].optionType === 'object') {
                        filterValue = filters[key].value.map((val) => val.id)
                    }

                    results = results.filter((result) => {
                        let tutorVals = result[key]
                        if (
                            filters[key].valueType === 'keyIndex' &&
                            tutorVals
                        ) {
                            tutorVals = Object.keys(tutorVals).map((key) => key)
                        }
                        let filtersVals = filterValue
                        if (tutorVals?.length > 0) {
                            return filtersVals.every((val) =>
                                tutorVals.includes(val)
                            )
                        }
                    })
                }
                // if (
                //     filters[key].query === 'availability' &&
                //     filters[key].value.length > 0
                // ) {
                //     results = results.filter((result) => {
                //         let availableDates = result.availability || {}
                //         let isAvailable = filters[key].value.some(
                //             (available) => {
                //                 const day = available.toLowerCase()
                //                 return (
                //                     availableDates[day] &&
                //                     availableDates[day].start !== null &&
                //                     availableDates[day].end !== null
                //                 )
                //             }
                //         )
                //         return result?.alwaysAvailable || isAvailable
                //     })
                // }
            })
            setTutors(results)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        fetchTutors()
    }, [filters])

    const tutorResults = React.useMemo(() => {
        if (sortBy === 'online') {
            return tutors.sort((a, b) => b.lastOnline - a.lastOnline)
        } else if (sortBy === 'low-rate') {
            return tutors.sort((a, b) => a.rate - b.rate)
        } else if (sortBy === 'high-rate') {
            return tutors.sort((a, b) => b.rate - a.rate)
        }
    }, [tutors, sortBy])

    const { navigateToChat } = useNavigateToChat()

    const renderItem = React.useCallback(
        ({ item }) => {
            return (
                <TutorListCard
                    minRating={filters.rating.value[1]}
                    navigation={navigation}
                    tutor={item}
                    filterRating={true}
                    navigateToChat={(tutor, user, profile) => {
                        if (!user) {
                            openLoginDialog('Please login to send a message')
                            return
                        }

                        navigateToChat(tutor, user, profile)
                    }}
                />
            )
        },
        [navigation, user, navigateToChat, filters]
    )

    const keyExtractor = React.useCallback((item) => item.id, [])

    const ListHeader = () => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl sx={{ minWidth: 120, mb: 2 }} size="small">
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={sortBy}
                        startAdornment={
                            <InputAdornment position="start">
                                Sort by:
                            </InputAdornment>
                        }
                        onChange={(e) => setSortBy(e.target.value)}
                    >
                        <MenuItem value="online">Online</MenuItem>
                        <MenuItem value="low-rate">Lowest price</MenuItem>
                        <MenuItem value="high-rate">Highest price</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        )
    }

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: isMd ? 'row' : 'column',
                    overflow: 'hidden',
                    backgroundColor: background,
                    maxWidth: 1280,
                    margin: '0 auto',
                    position: 'relative',
                    width: '100%',
                }}
            >
                <TutorListLargeDrawer
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                />

                <Box
                    component="main"
                    sx={{
                        p: isMd ? 2 : 0,
                        pr: 0,
                        overflow: 'auto',
                        flexDirection: 'column',
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    <FlatList
                        style={{
                            display: 'flex',
                        }}
                        contentContainerStyle={{
                            paddingRight: 8,
                            paddingLeft: 8,
                            flex: 1,
                        }}
                        ListHeaderComponent={ListHeader}
                        ListEmptyComponent={
                            <EmptyTutorList
                                resetFilters={resetFilters}
                                loading={loading}
                            />
                        }
                        keyExtractor={keyExtractor}
                        data={tutorResults}
                        renderItem={renderItem}
                    />
                </Box>
            </Box>
        </>
    )
}
