import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useColorsContext } from '@utils/styles';

const ProfilePreviewIcon = ({ size = 24 }) => {

    const { text } = useColorsContext();
    return (
        <Svg width={size} height={size} viewBox="0 0 40 40" fill="none">
            <Path d="M7.53989 10.4165C7.53989 9.72615 8.09952 9.1665 8.78989 9.1665H31.2162C31.9065 9.1665 32.4662 9.72615 32.4662 10.4165V16.2498C32.4662 16.9402 31.9065 17.4998 31.2162 17.4998H8.78989C8.09952 17.4998 7.53989 16.9402 7.53989 16.2498V10.4165ZM10.0399 11.6665V14.9998H29.9662V11.6665H10.0399Z" fill={text} />
            <Path d="M23.7162 19.9648C23.0258 19.9648 22.4662 20.5245 22.4662 21.2148V28.7148C22.4662 29.4052 23.0258 29.9648 23.7162 29.9648H31.2162C31.9065 29.9648 32.4662 29.4052 32.4662 28.7148V21.2148C32.4662 20.5245 31.9065 19.9648 31.2162 19.9648H23.7162ZM24.9662 27.4648V22.4648H29.9662V27.4648H24.9662Z" fill={text} />
            <Path d="M7.53989 22.0835C7.53989 21.3932 8.09952 20.8335 8.78989 20.8335H18.75C19.4403 20.8335 20 21.3932 20 22.0835C20 22.7738 19.4403 23.3335 18.75 23.3335H8.78989C8.09952 23.3335 7.53989 22.7738 7.53989 22.0835Z" fill={text} />
            <Path d="M8.78989 26.6665C8.09952 26.6665 7.53989 27.2262 7.53989 27.9165C7.53989 28.6068 8.09952 29.1665 8.78989 29.1665H18.75C19.4403 29.1665 20 28.6068 20 27.9165C20 27.2262 19.4403 26.6665 18.75 26.6665H8.78989Z" fill={text} />
            <Path d="M3.33334 9.58333C3.33334 7.05203 5.38538 5 7.91668 5H32.0833C34.6147 5 36.6667 7.05203 36.6667 9.58333V30.4167C36.6667 32.948 34.6147 35 32.0833 35H7.91668C5.38538 35 3.33334 32.948 3.33334 30.4167V9.58333ZM7.91668 7.5C6.76608 7.5 5.83334 8.43273 5.83334 9.58333V30.4167C5.83334 31.5673 6.76608 32.5 7.91668 32.5H32.0833C33.234 32.5 34.1667 31.5673 34.1667 30.4167V9.58333C34.1667 8.43273 33.234 7.5 32.0833 7.5H7.91668Z" fill={text} />
        </Svg>
    )

}

export default ProfilePreviewIcon;
