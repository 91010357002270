import {
    Box,
    Divider,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    Grid2,
} from '@mui/material'
import { TouchableOpacity } from 'react-native'
import { articles, categories } from '../data'
import { useNavigation } from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import { useEffect, useState } from 'react'
import { ArticleListing } from './ArticleListings.tsx/ArticleListing'
import { ArticleListings } from './ArticleListings.tsx'

const HelpSections: React.FC<{ type: 'student' | 'tutor' }> = ({ type }) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const defaultStyles = useStyles()
    const navigation = useNavigation()
    const { primaryLight } = useColorsContext()
    const [featured, setFeatured] = useState([])
    const [articleCategories, setArticleCategories] = useState([])

    useEffect(() => {
        const featuredArticles = articles.filter(
            (article) => article.featured && article.type === type
        )
        setFeatured(featuredArticles)
        const categoryData = []
        categories.forEach((category) => {
            const articleData = articles.filter(
                (article) =>
                    article.type === type && article.category_id === category.id
            )
            if (articleData.length > 0) {
                categoryData.push({
                    ...category,
                    articles: articleData,
                })
            }
        })

        setArticleCategories(categoryData)

        return () => {
            setFeatured([])
        }
    }, [articles, type])

    return (
        <Box sx={{ backgroundColor: primaryLight, p: 2, borderRadius: 3 }}>
            <Box
                sx={{
                    borderRadius: 3,
                    backgroundColor: '#FFF',
                    p: 3,
                    mb: 2,
                }}
            >
                <Box
                    component="img"
                    sx={{ width: 40, height: 40, objectFit: 'contain', pb: 1 }}
                    src={require('@assets/images/help-center/featured.png')}
                />
                <Typography
                    variant="h5"
                    fontFamily={defaultStyles.boldFont}
                    color="primary"
                    sx={{ mb: 2 }}
                >
                    Featured Articles
                </Typography>
                <Grid2 container columnSpacing={4} direction={'row'}>
                    {featured.map((article) => (
                        <Grid2 size={{ md: 4, xs: 12 }}>
                            <ArticleListing article={article} />
                        </Grid2>
                    ))}
                </Grid2>
            </Box>
            <Grid2 container spacing={4} sx={{ p: 2 }}>
                {articleCategories &&
                    articleCategories?.map((category) => (
                        <Grid2 size={{ md: 4, xs: 12 }}>
                            <Box
                                component="img"
                                src={category?.icon}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    objectFit: 'contain',
                                    mb: 1,
                                }}
                            />
                            <Typography
                                variant="h6"
                                fontFamily={defaultStyles.boldFont}
                                sx={{ mb: 1 }}
                            >
                                {category.title}
                            </Typography>
                            <ArticleListings articles={category?.articles} />
                        </Grid2>
                    ))}
            </Grid2>
        </Box>
    )
}

export default HelpSections
