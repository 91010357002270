


const AutoAccept = ({ size = 20 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M13.4375 6.5625H7.5C7.25136 6.5625 7.0129 6.66127 6.83709 6.83709C6.66127 7.0129 6.5625 7.25136 6.5625 7.5V13.125C6.5625 13.3736 6.66127 13.6121 6.83709 13.7879C7.0129 13.9637 7.25136 14.0625 7.5 14.0625H12.5C12.5829 14.0625 12.6623 14.0295 12.7209 13.9709L14.2834 12.4084C14.342 12.3498 14.375 12.2704 14.375 12.1875V7.5C14.375 7.25136 14.2762 7.0129 14.1004 6.83709C13.9246 6.66127 13.6861 6.5625 13.4375 6.5625ZM12.8125 12.9956V12.5H13.3081L12.8125 12.9956ZM13.75 11.875H12.5C12.4171 11.875 12.3376 11.9079 12.279 11.9665C12.2204 12.0251 12.1875 12.1046 12.1875 12.1875V13.4375H7.5C7.41712 13.4375 7.33763 13.4046 7.27903 13.346C7.22042 13.2874 7.1875 13.2079 7.1875 13.125V8.4375H13.75V11.875ZM13.75 7.8125H7.1875V7.5C7.1875 7.41712 7.22042 7.33763 7.27903 7.27903C7.33763 7.22042 7.41712 7.1875 7.5 7.1875H13.4375C13.5204 7.1875 13.5999 7.22042 13.6585 7.27903C13.7171 7.33763 13.75 7.41712 13.75 7.5V7.8125Z" fill="black" />
                <path d="M10 9.375H9.375V10H10V9.375Z" fill="black" />
                <path d="M11.5625 9.375H10.9375V10H11.5625V9.375Z" fill="black" />
                <path d="M13.125 9.375H12.5V10H13.125V9.375Z" fill="black" />
                <path d="M10 10.625H9.375V11.25H10V10.625Z" fill="black" />
                <path d="M8.4375 10.625H7.8125V11.25H8.4375V10.625Z" fill="black" />
                <path d="M11.5625 10.625H10.9375V11.25H11.5625V10.625Z" fill="black" />
                <path d="M10 11.875H9.375V12.5H10V11.875Z" fill="black" />
                <path d="M8.4375 11.875H7.8125V12.5H8.4375V11.875Z" fill="black" />
                <path d="M11.5625 11.875H10.9375V12.5H11.5625V11.875Z" fill="black" />
                <path d="M13.125 10.625H12.5V11.25H13.125V10.625Z" fill="black" />
                <path d="M16.875 0.3125C16.3187 0.3125 15.775 0.47745 15.3125 0.786492C14.8499 1.09553 14.4895 1.53479 14.2766 2.0487C14.0637 2.56262 14.008 3.12812 14.1165 3.67369C14.2251 4.21926 14.4929 4.7204 14.8863 5.11374C15.2796 5.50707 15.7807 5.77494 16.3263 5.88346C16.8719 5.99198 17.4374 5.93628 17.9513 5.72341C18.4652 5.51054 18.9045 5.15005 19.2135 4.68754C19.5226 4.22503 19.6875 3.68126 19.6875 3.125C19.6867 2.37933 19.3901 1.66444 18.8628 1.13718C18.3356 0.609909 17.6207 0.313327 16.875 0.3125ZM16.875 5.3125C16.4424 5.3125 16.0194 5.18421 15.6597 4.94384C15.3 4.70347 15.0196 4.36183 14.854 3.96212C14.6884 3.56241 14.6451 3.12257 14.7295 2.69824C14.8139 2.27391 15.0223 1.88413 15.3282 1.5782C15.6341 1.27228 16.0239 1.06394 16.4482 0.979532C16.8726 0.895127 17.3124 0.938447 17.7121 1.10401C18.1118 1.26958 18.4535 1.54996 18.6938 1.90969C18.9342 2.26942 19.0625 2.69235 19.0625 3.125C19.0618 3.70493 18.8311 4.2609 18.421 4.67097C18.0109 5.08105 17.4549 5.31176 16.875 5.3125Z" fill="black" />
                <path d="M3.125 19.6875C3.68126 19.6875 4.22503 19.5226 4.68754 19.2135C5.15005 18.9045 5.51054 18.4652 5.72341 17.9513C5.93628 17.4374 5.99198 16.8719 5.88346 16.3263C5.77494 15.7807 5.50707 15.2796 5.11374 14.8863C4.7204 14.4929 4.21926 14.2251 3.67369 14.1165C3.12812 14.008 2.56262 14.0637 2.0487 14.2766C1.53479 14.4895 1.09553 14.8499 0.786492 15.3125C0.47745 15.775 0.3125 16.3187 0.3125 16.875C0.313327 17.6207 0.609909 18.3356 1.13718 18.8628C1.66444 19.3901 2.37933 19.6867 3.125 19.6875ZM3.125 14.6875C3.55765 14.6875 3.98058 14.8158 4.34031 15.0562C4.70004 15.2965 4.98042 15.6382 5.14599 16.0379C5.31155 16.4376 5.35487 16.8774 5.27047 17.3018C5.18606 17.7261 4.97772 18.1159 4.6718 18.4218C4.36587 18.7277 3.97609 18.9361 3.55176 19.0205C3.12743 19.1049 2.68759 19.0616 2.28788 18.896C1.88817 18.7304 1.54653 18.45 1.30616 18.0903C1.06579 17.7306 0.9375 17.3076 0.9375 16.875C0.938244 16.2951 1.16895 15.7391 1.57903 15.329C1.9891 14.919 2.54507 14.6882 3.125 14.6875Z" fill="black" />
                <path d="M16.3225 3.56683L15.8806 3.12495L15.4375 3.56683L16.1003 4.22964C16.1589 4.28822 16.2384 4.32113 16.3212 4.32113C16.4041 4.32113 16.4836 4.28822 16.5422 4.22964L18.3125 2.46214L17.8694 2.02026L16.3225 3.56683Z" fill="black" />
                <path d="M3.28125 17.5H2.96875C2.92731 17.5 2.88757 17.4835 2.85826 17.4542C2.82896 17.4249 2.8125 17.3852 2.8125 17.3438H2.1875C2.18754 17.5238 2.24976 17.6983 2.36364 17.8378C2.47753 17.9772 2.63609 18.0731 2.8125 18.1091V18.4375H3.4375V18.1091C3.62703 18.0702 3.79539 17.9624 3.90998 17.8065C4.02457 17.6506 4.07724 17.4577 4.0578 17.2652C4.03835 17.0727 3.94817 16.8943 3.80471 16.7645C3.66125 16.6347 3.47473 16.5627 3.28125 16.5625H2.96875C2.92731 16.5625 2.88757 16.546 2.85826 16.5167C2.82896 16.4874 2.8125 16.4477 2.8125 16.4062C2.8125 16.3648 2.82896 16.3251 2.85826 16.2958C2.88757 16.2665 2.92731 16.25 2.96875 16.25H3.28125C3.32269 16.25 3.36243 16.2665 3.39174 16.2958C3.42104 16.3251 3.4375 16.3648 3.4375 16.4062H4.0625C4.06246 16.2262 4.00024 16.0517 3.88635 15.9122C3.77247 15.7728 3.61391 15.6769 3.4375 15.6409V15.3125H2.8125V15.6409C2.62297 15.6798 2.45461 15.7876 2.34002 15.9435C2.22543 16.0994 2.17276 16.2923 2.1922 16.4848C2.21165 16.6773 2.30183 16.8557 2.44529 16.9855C2.58875 17.1153 2.77527 17.1873 2.96875 17.1875H3.28125C3.32269 17.1875 3.36243 17.204 3.39174 17.2333C3.42104 17.2626 3.4375 17.3023 3.4375 17.3438C3.4375 17.3852 3.42104 17.4249 3.39174 17.4542C3.36243 17.4835 3.32269 17.5 3.28125 17.5Z" fill="black" />
                <path d="M9.4749 18.8314C9.65101 18.8427 9.82673 18.8502 9.9999 18.8502H9.99994C11.4156 18.8496 12.8105 18.5095 14.0676 17.8584C15.3247 17.2074 16.4073 16.2643 17.2246 15.1084C18.042 13.9526 18.5702 12.6175 18.765 11.2153C18.9598 9.81311 18.8155 8.38463 18.3442 7.04972L18.3108 6.95521L18.2164 6.98879L17.6276 7.19816L17.5337 7.23159L17.5668 7.32565C17.9939 8.53613 18.1245 9.83135 17.9477 11.1027C17.7709 12.3741 17.2918 13.5845 16.5506 14.6325C15.8094 15.6805 14.8278 16.5355 13.688 17.1258C12.5482 17.7161 11.2834 18.0245 9.99985 18.0252C9.82831 18.0252 9.6526 18.0171 9.4749 18.0046V17.5002V17.5002C9.47489 17.4272 9.4555 17.3555 9.41871 17.2924C9.38192 17.2293 9.32905 17.1772 9.2655 17.1412C9.20195 17.1053 9.13 17.0868 9.057 17.0878C8.98399 17.0887 8.91255 17.1091 8.84996 17.1467L7.28751 18.0841L7.28743 18.0842C7.22644 18.1209 7.17598 18.1727 7.14095 18.2346C7.10591 18.2966 7.0875 18.3665 7.0875 18.4377C7.0875 18.5089 7.10591 18.5788 7.14095 18.6408C7.17598 18.7027 7.22644 18.7545 7.28743 18.7912L7.28751 18.7913L8.84996 19.7287C8.84998 19.7287 8.85 19.7287 8.85001 19.7288C8.91259 19.7663 8.98401 19.7866 9.057 19.7876C9.13 19.7886 9.20195 19.7701 9.2655 19.7342C9.32905 19.6982 9.38192 19.6461 9.41871 19.583C9.4555 19.5199 9.47489 19.4482 9.4749 19.3752V19.3752V18.8314ZM8.6499 18.2289V18.6465L8.30159 18.4377L8.6499 18.2289Z" fill="black" stroke="black" strokeWidth="0.2" />
                <path d="M11.15 2.85347C11.0874 2.89107 11.016 2.91139 10.943 2.91235C10.8699 2.9133 10.798 2.89486 10.7344 2.85892C10.6709 2.82297 10.618 2.7708 10.5812 2.70773C10.5445 2.64467 10.5251 2.57297 10.525 2.49996V2.49994V1.99445C10.3455 1.98256 10.1706 1.97494 10.0001 1.97494C8.7165 1.97559 7.45177 2.284 6.31196 2.87432C5.17216 3.46463 4.19051 4.31964 3.44934 5.36763C2.70817 6.41563 2.22909 7.62606 2.05227 8.89742C1.87544 10.1688 2.00604 11.464 2.4331 12.6745L2.46629 12.7685L2.3723 12.802L1.78355 13.0113L1.68912 13.0449L1.65575 12.9504C1.18445 11.6155 1.04015 10.187 1.23495 8.78482C1.42976 7.38262 1.95799 6.04758 2.77533 4.89169C3.59267 3.7358 4.67529 2.79277 5.93237 2.14172C7.18945 1.49066 8.58433 1.15056 10 1.14994L10 1.14994C10.1729 1.14994 10.3481 1.15698 10.525 1.16769V0.624941V0.624922C10.5251 0.551911 10.5445 0.480211 10.5812 0.417146C10.618 0.354081 10.6709 0.301911 10.7344 0.265963C10.798 0.230016 10.8699 0.211579 10.943 0.212535C11.016 0.213492 11.0874 0.233808 11.15 0.271409M11.15 2.85347L11.1499 2.8535L11.0985 2.76775L11.15 2.85347ZM11.15 2.85347L12.7124 1.916L12.7125 1.91595C12.7735 1.87928 12.824 1.82745 12.859 1.76551C12.894 1.70356 12.9124 1.63361 12.9124 1.56244C12.9124 1.49128 12.894 1.42132 12.859 1.35937C12.824 1.29743 12.7735 1.2456 12.7125 1.20893L12.7124 1.20888L11.15 0.271409M11.15 0.271409L11.1499 0.271379L11.0985 0.357128L11.15 0.271409ZM11.35 1.35366L11.6984 1.56244L11.35 1.77122V1.35366Z" fill="black" stroke="black" strokeWidth="0.2" />
            </g>
        </svg>

    )
}

export default AutoAccept;