import { Box, Typography } from '@mui/material'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { useColorsContext, useStyles } from '@utils/styles'
import { schoolLogos } from '@config/schools'
import DegreeCard from '@screens/loggedIn/Profile/Education/DegreeCard'

interface Props {
    degrees: Degree[]
    name: string
}

interface Degree {
    school: string
    year: string
    type: string
    name: string
}

const Education: React.FC<Props> = ({ degrees, name }) => {
    const { text, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()

    console.log('degress', degrees)

    return (
        <Box
            sx={{
                marginTop: 3,
                p: 2,
                background: primaryLight,
                borderRadius: 4,
            }}
        >
            {degrees?.length > 0 ? (
                <>
                    <Typography
                        variant="h6"
                        fontFamily={defaultStyles.titleFont}
                        sx={{ color: text, mb: 1 }}
                    >
                        Degrees{' '}
                    </Typography>
                    {degrees.map((degree, index) => {
                        return (
                            <DegreeCard
                                sx={{
                                    width: '100%',
                                    marginBottom: 2,
                                }}
                                degree={degree}
                                key={index}
                            />
                        )
                    })}
                </>
            ) : (
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h6"
                        fontFamily="Poppins_600Semibold"
                        sx={{ color: text, my: 2 }}
                    >
                        {name.split(' ')[0]} has not added any degrees to their
                        profile yet.
                    </Typography>
                    <img
                        style={{
                            maxWidth: 200,
                            maxHeight: 200,
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                        src={require('@assets/images/profile/empty-education.svg')}
                        alt="empty-education"
                    />
                </Box>
            )}
        </Box>
    )
}

export default Education
