import { Notification } from 'dataTypes'
export const useHandleNotificationAction = () => {
    return (notification: Notification) => {
        if (!notification.action) return

        switch (notification.action) {
            case '/onboarding':
                window.dispatchEvent(new Event('showOnboarding'))
                break
            default:
                console.warn(
                    'Unknown notification action type:',
                    notification.action
                )
        }
    }
}
