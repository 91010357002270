const SendIcon = ({ size = 37, color = "#000" }) => {
    const aspectRatio = 37 / 36;
    const height = size / aspectRatio;
    return (
        <svg width={size} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.8909 18.2132H16.4455" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.8909 18.2132L12.0261 29.7037L16.4455 18.2132L12.0261 6.72271L35.8909 18.2132Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default SendIcon

